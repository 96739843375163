import { useEffect, useState } from "react";
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {  FilterGridType } from "../../utils/enums"
import { AssemblyPricesState } from "../../store/reducers/assemblyPricesReducer";
import { Button, CommandBar, DataGrid, Modal, RowHeader, Section, SplitSection, VerticalSection } from "../../components";
import { HeaderItem, Row, RowItem, RowsContainer } from "../../components/RowsContainer";

export const EditAssemblyPrices = (props: any) => {
    const state = useAppSelector((state: any): AssemblyPricesState => state.assemblyPrices);
    let initialPriceData: any = JSON.parse(JSON.stringify(state.assemblyPrices[state.selectedIndex]));
    const [priceData, setPriceData] = useState(initialPriceData);
    const [componentsSearchText, setPartsSearchText] = useState("");
    const [subAsmSearchText, setSubAsmSearchText] = useState("");

    useEffect(() => {
        setPriceData(JSON.parse(JSON.stringify(state.assemblyPrices[state.selectedIndex])));
    }, [state.selectedIndex])

    const searchPassPart = (part: any) => {
        let text = componentsSearchText.toLowerCase();
        let include = part.partName.toLowerCase().includes(text) || part.partNumber.toLowerCase().includes(text)
            || part.quantity.toString().toLowerCase().includes(text)
        return include;
    }

    const searchPassSubAsm = (asm: any) => {
        let text = subAsmSearchText.toLowerCase();
        let include = asm.assemblyName.toLowerCase().includes(text) || asm.assemblyNumber.toLowerCase().includes(text)
            || asm.quantity.toString().toLowerCase().includes(text)
        return include;
    }

    
    const handleQuantityChange = (e: any, ind: number, type: string) => {
        let val = e.target.value;
        let copy = JSON.parse(JSON.stringify(priceData));
        if (type === "parts") {
            let prevProd = copy.parts[ind].quantity * copy.parts[ind].cost;
            let currProd = val * copy.parts[ind].cost;
            copy.parts[ind].quantity = parseInt(val);
            copy.totalCost = parseFloat(`${copy.totalCost - prevProd + currProd}`).toFixed(2)
        } else {
            let prevProd = copy.subAssemblies[ind].quantity * copy.subAssemblies[ind].cost;
            let currProd = val * copy.subAssemblies[ind].cost;
            copy.subAssemblies[ind].quantity = parseInt(val);
            copy.totalCost = copy.totalCost - prevProd + currProd
        }
        setPriceData(copy);
    }

    const handleRateChange = (e: any, ind: number, type: string) => {
        let val = e.target.value;
        let copy = JSON.parse(JSON.stringify(priceData));
        if (type === "parts") {
            let prevProd = copy.parts[ind].quantity * copy.parts[ind].cost;
            let currProd = val * copy.parts[ind].quantity;
            copy.parts[ind].cost = parseFloat(val);
            copy.totalCost = parseFloat(`${copy.totalCost - prevProd + currProd}`).toFixed(2)
        } else {
            let prevProd = copy.subAssemblies[ind].quantity * copy.subAssemblies[ind].cost;
            let currProd = val * copy.subAssemblies[ind].quantity;
            copy.subAssemblies[ind].cost = parseFloat(val);
            copy.totalCost = copy.totalCost - prevProd + currProd
        }
        setPriceData(copy);
    }

    const getAssemblyData = () => {
        return <DataGrid height="100%">
            <RowHeader height="40%">
            <HeaderItem width={33.33} value={"Assembly Name"}></HeaderItem>
            <HeaderItem width={33.33} value={"Assembly Number"}></HeaderItem>
            <HeaderItem width={33.33} value={"Total Cost"}></HeaderItem>
        </RowHeader>
        <RowsContainer height="60%">
            <Row color={true}>
                <RowItem width={33.33} value={priceData.name}></RowItem>
                <RowItem width={33.33} value={priceData.assemblyNumber}></RowItem>
                <RowItem width={33.33} value={`${priceData.totalCost} ${priceData.currencySymbol}`}></RowItem>
            </Row>
        </RowsContainer>
        </DataGrid>
    }

    const getPartsHeader = () => {
        return <RowHeader>
            <HeaderItem width={10} value={"S.No"}></HeaderItem>
            <HeaderItem width={20} value={"Part Name"}></HeaderItem>
            <HeaderItem width={15} value={"Part Number"}></HeaderItem>
            <HeaderItem width={20} value={"Quantity"}></HeaderItem>
            <HeaderItem width={20} value={"Unit Cost"}></HeaderItem>
            <HeaderItem width={15} value={"Total Cost"}></HeaderItem>
        </RowHeader>
    }

    const getParts = () => {
        let parts = [];
        let ind  = 0;
        for (let i = 0; i < priceData.parts.length; i++) {
            let part = priceData.parts[i];
            if (searchPassPart(part)) {
                parts.push(
                    <Row color={ind%2 === 1} key={part.partName+i}>
                        <RowItem width={10} value={`${ind+1}`}></RowItem>
                        <RowItem width={20} value={part.partName}></RowItem>
                        <RowItem width={15} value={part.partNumber}></RowItem>
                        <RowItem width={20} >
                            <input type="number" value={part.quantity}
                                onChange={(e) => handleQuantityChange(e, i, "parts")}></input>
                            <span>{" "+ part.quantityUnit}</span>
                        </RowItem>
                        <RowItem width={20} >
                            <input type="number"
                                onChange={(e) => handleRateChange(e, i, "parts")} 
                                value={part.cost}></input><span>{`  ${part.currencySymbol}/${part.quantityUnit}`}</span>
                        </RowItem>
                        <RowItem width={15}><span>{" "+`${part.cost*part.quantity || 0} ${part.currencySymbol}`}</span></RowItem>
                    </Row>)
                ind++;
            }
        }        
        return <RowsContainer>{parts}</RowsContainer>;
    }

    const getSubAssembliesHeader = () => {
        return <RowHeader>
            <HeaderItem width={10} value={"S.No"}></HeaderItem>
            <HeaderItem width={20} value={"Asmb. Name"}></HeaderItem>
            <HeaderItem width={20} value={"Asmb. Number"}></HeaderItem>
            <HeaderItem width={15} value={"Quantity"}></HeaderItem>
            <HeaderItem width={20} value={"Unit Cost"}></HeaderItem>
            <HeaderItem width={15} value={"Total Cost"}></HeaderItem>
        </RowHeader>
    }

    const getSubAssemblies = () => {
        let subAssemblies = [];
        let ind  = 0;
        for (let i = 0; i < priceData.subAssemblies.length; i++) {
            let asm = priceData.subAssemblies[i];
            if (searchPassSubAsm(asm)) {
                subAssemblies.push(
                    <Row color={ind%2 === 1} key={asm.assemblyName+i}>
                    <RowItem width={10} value={`${ind+1}`}></RowItem>
                    <RowItem width={20} value={asm.assemblyName}></RowItem>
                    <RowItem width={20} value={asm.assemblyNumber}></RowItem>
                    <RowItem width={15} >
                        <input type="number" onChange={(e) => handleQuantityChange(e, i, "assemblies")} value={asm.quantity}></input>
                        <span >{" "+ asm.quantityUnit}</span>
                    </RowItem>
                    <RowItem width={20}><input type="number" onChange={(e) => handleRateChange(e, i, "assemblies")} value={asm.cost}></input><span>{`${asm.currencySymbol}/${asm.quantityUnit}`}</span></RowItem>
                    <RowItem width={15}><span>{`${asm.cost*asm.quantity} ${asm.currencySymbol}`}</span></RowItem>
                </Row>)
                // <SubAssemblyPrice colorInd={ind} index={i} key={i+asm.assemblyName}></SubAssemblyPrice>)
                ind++;
            }
        }
        if (subAssemblies.length === 0) {
            return <></>
        }
        return <RowsContainer>{subAssemblies}</RowsContainer>;        
    }

    const handlePrint = () => {
        window.print();
    }

    const handleClose = () => {
        props.closeFn(false);
    }

    return <Modal
        iconName="AssemblyActive"
        size="full"
        title={`Assembly Cost Analysis for "${state.assemblyPrices[state.selectedIndex].name}"`}
        actions={[
            <Button onClick={() => { handleClose() }} label="Close"/>,
            <Button onClick={handlePrint} label="Print"/>
        ]}
        errorMsg={""}
    >
        <VerticalSection height="15%">
            {getAssemblyData()}
        </VerticalSection>
        <SplitSection height="85%">
            <Section height="100%">
                <CommandBar
                    searchFn={(e) => {setPartsSearchText(e.target.value)}}
                    gridType={FilterGridType.PRICES}
                />
                <DataGrid>
                    {getPartsHeader()}
                    {getParts()}
                </DataGrid>
            </Section>
            <Section height="100%">
                <CommandBar
                    searchFn={(e) => {setSubAsmSearchText(e.target.value)}}
                    gridType={FilterGridType.PRICES}
                />
                <DataGrid>
                    {getSubAssembliesHeader()}
                    {getSubAssemblies()}
                </DataGrid>
            </Section>
        </SplitSection>
    </Modal>
}