import { Button, Header, Icon, Modal } from "semantic-ui-react";

export const WrapperModal = (props: any) => {
    return <Modal size='tiny' open={props.isOpen}
        onClose={() => props.closingFn()}
        trigger={props.trigger}
    >
        <Header icon>
            {props.type}
        </Header>
        <Modal.Content>
            <p>{props.msg}</p>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => props.closingFn()}>
                <Icon name='remove' /> Ok
            </Button>
        </Modal.Actions>
    </Modal>
}