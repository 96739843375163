import { useCallback, useEffect, useState } from "react"
import { Icon } from "semantic-ui-react";
import { FilterName } from "../../utils/enums";
import "./Dropdown.css";
import { addOption, getOptions } from "./utils";
import { v4 as uuidv4 } from 'uuid';
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";

export interface DropdownOption {
    text: string;
    value: any;
    key: string;
}

export interface BaseProps {
    key: string;
    name: FilterName;
    selections: DropdownOption[];
    onSelection: Function;
    multiselect?: boolean;
    closeOnSelect?: boolean;
    setOpen?: Function;
    clearIcon?: boolean;
    noCaret?: boolean;
}

export interface DropdownFilterProps extends BaseProps {
    payload?: any;
    label?: string,
    dropdownOverflow?: boolean;
    noCheckbox?: boolean;
}

export interface DropdownFieldProps extends BaseProps {
    payload?: any;
    label?: string,
    dropdownOverflow?: boolean;
    noCheckbox?: boolean;
    addOption?: boolean;
    options?: DropdownOption[],
    isError?: boolean;
    errorMsg?: boolean;
}

export interface DropdownContainerProps extends BaseProps {
    payload?: any;
    searchText: string;
    isOpen: boolean;
    pos: { left: number, right: number, top: number, bottom: number }
    noCheckbox?: boolean;
    addOption?: boolean;
}

export const DropdownContainer = (props: DropdownContainerProps) => {
    const [selectAll, setSelectAll] = useState(false);
    const [limitReached, setLimitReached] = useState(false);
    const [limit, setLimit] = useState(10);
    const [options, setOptions] = useState<DropdownOption[]>([])
    const { common } = actions;
    const dispatch = useAppDispatch();

    const fetchOptions = useCallback(async (setSelections?: boolean) => {
        let payload = { ...props.payload, limit, pageNumber: 1, searchText: props.searchText }
        let res = await getOptions(props.name)({ ...payload });
        let newOptions = res.results.map((item: any) => {            
            return { text: item.name, value: item.name, key: item._id || item.name, object: item }
        })
        setOptions(newOptions);
        if (setSelections) {
            let newSelections = props.selections.filter((selection: any) => {
                return newOptions.findIndex((option: any) => {
                    return option.value === selection.value;
                }) > -1
            })
            props.onSelection({}, { values: newSelections, name: props.name })
        }
        setLimitReached(res.currentResults === res.totalResults);
    }, [limit, props.searchText, JSON.stringify(props.payload)])


    useEffect(() => {
        fetchOptions()
    }, [limit, props.searchText])

    useEffect(() => {
        fetchOptions(true)
        // let newSelections = props.selections.filter((selection: any) => {
        //     return options.findIndex((option: any) => {
        //         return option.value === selection.value;
        //     }) > -1
        // })
        // props.onSelection({}, { values: newSelections, name: props.name })
    }, [JSON.stringify(props.payload)])

    useEffect(() => {
        if (props.multiselect) {
            if (options.length <= props.selections.length && options.length > 0) {
                setSelectAll(true)
            } else {
                setSelectAll(false)
            }
        }
    }, [options, props.selections])

    const handleSelectAll = (e: any) => {
        if (selectAll) {
            props.onSelection(e, { values: [], name: props.name, multiselect: props.multiselect })
        } else {
            props.onSelection(e, { values: options, name: props.name, multiselect: props.multiselect })
        }
        setSelectAll(!selectAll);
    }

    const handleOptionClick = (e: any, index: number, type: string) => {
        let valuesCopy = JSON.parse(JSON.stringify(props.selections));
        if (type === "uncheck") {
            valuesCopy.splice(index, 1);
        } else {
            if (props.multiselect) {
                valuesCopy.push({ ...options[index] })
            } else {
                valuesCopy = [{ ...options[index] }]
            }
        }
        
        props.onSelection(e, { values: valuesCopy, name: props.name, multiselect: props.multiselect });
        if (props.closeOnSelect && props.setOpen && type !== "uncheck") {
            props.setOpen(false);
        }
    }

    const getOptionElems = () => {
        let optionElems = [];
        for (let i = 0; i < options.length; i++) {
            if (props.selections.findIndex(selection => {
                return selection.text === options[i].text
            }) === -1) {
                optionElems.push(
                    <div onClick={(e) => { handleOptionClick(e, i, "check") }} className="option" key={options[i].key + i}>
                        {!props.noCheckbox ? <div className="checkbox"><input type="checkbox"></input></div> : null}
                        <div className="text"><span>{options[i].text}</span></div>
                    </div>
                )
            }
        }
        return optionElems;
    }

    const handleScroll = (e: any) => {
        if (!limitReached) {
            var element = e.target;
            if (element.scrollHeight - element.scrollTop < (element.clientHeight + 10)) {
                setLimit(limit + 10)
            }
        }
    }
    const getDropdownStyle = () => {
        let style: any = {};
        if (props.pos.right > 0.8 * window.innerWidth) {
            style.right = 0;
        }

        if (props.pos.top > 0.6 * window.innerHeight) {
            style.bottom = props.pos.bottom - props.pos.top;
        }

        return style;
    }

    const handleAddNew = async () => {
        await addOption(props.name)({ ...props.payload, name: props.searchText });
        dispatch(common.setFlyMsg({ msg: `Add New ${props.name}` }))
    }

    return <>{props.isOpen ? <div style={{ ...getDropdownStyle() }} onScroll={handleScroll} className="dropdown-container2">
        {props.addOption && options.length === 0 ? <div className="add-option">
            <div className="option">
                <div className="checkbox" onClick={handleAddNew} ><Icon color="blue" name="save outline"></Icon></div>
                <div className="text"><span>{props.searchText}</span></div>
            </div>
        </div> : null}
        {props.multiselect && options.length > 0 ? <div className="select-all">
            <div onClick={handleSelectAll} className="option">
                {!props.noCheckbox ? <div className="checkbox"><input checked={selectAll} type="checkbox"></input></div> : null}
                <div className="text"><span>Select All</span></div>
            </div>
        </div> : null}
        <div className="selections">
            {props.selections.map((selection, ind) => {
                return <div onClick={(e) => { handleOptionClick(e, ind, "uncheck") }} className="option" key={selection.key + ind}>
                    {!props.noCheckbox ? <div className="checkbox"><input defaultChecked={true} type="checkbox"></input></div> : null}
                    <div className="text"><span>{selection.text}</span></div>
                </div>
            })}
        </div>
        <div className="options">
            {getOptionElems()}
        </div>
    </div> : null}</>
}

export interface InputBoxProps extends BaseProps {
    setSearchText: Function;
    isOpen: boolean, setOpen: Function;
    setPos: Function;
    isError?: boolean;
}

export const InputBox = (props: InputBoxProps) => {

    const [text, setText] = useState("");
    const [id] = useState(uuidv4())
    useEffect(() => {
        const inputBox = document.getElementById(id);
        let pos: any = {}
        if (inputBox) {
            const measures = inputBox.getBoundingClientRect();
            pos.left = measures.x;
            pos.right = measures.right;
            pos.bottom = measures.bottom;
            pos.top = measures.y;
            props.setPos(pos);
        }
    }, [])

    const getText = () => {
        return props.selections.map(selection => selection.text).join(", ");
    }

    const handleClearAll = (e: any) => {
        props.onSelection(e, { values: [], name: props.name });
    }

    const handleTextChange = (e: any) => {
        setText(e.target.value)
        props.setSearchText(e.target.value)
    }

    const handleInputFocus = (e: React.MouseEvent<HTMLDivElement>) => {
        if (props.isOpen) {
            props.setSearchText("")
        } else {
            props.setOpen(true);
        }
    }

    const handleCaretClick = () => {
        props.setOpen(!props.isOpen);
    }

    return <div id={id} className={"dropdown-inputbox" + (props.isError ? " error" : "")}>
        {props.isOpen ? <div className="inputbox"><input value={text} onChange={handleTextChange}></input></div> :
            <div className="inputbox"><input onClick={handleInputFocus} value={getText()}></input></div>}
        {props.selections.length > 0 && !props.isOpen && props.clearIcon ? <div onClick={handleClearAll} className="clear"><Icon color="grey" size="small" name="x"></Icon></div> : null}
        {!props.noCaret && props.isOpen ? <div onClick={handleCaretClick} className="expander"><Icon color="grey" name="caret up"></Icon></div>
            : !props.noCaret ? <div onClick={handleCaretClick} className="expander"><Icon color="grey" name="caret down"></Icon></div> : <></>}
    </div>
}