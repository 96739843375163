import { useEffect, useState } from "react";
import { Icon, Label } from "semantic-ui-react";
import { useAppDispatch } from "../../store/hooks";
import { ComponentFilter, FilterGridType, FilterName, ProductionFilter } from "../../utils/enums";
import { DropdownFilter } from "../Dropdown/DropdownFilter";
import "./FilterGrid.css";

export interface FilterGridProps {
    grid: FilterGridType;
    filters: FilterName[];
    datePicker?: boolean;
    dispatchFnFilters: Function;
    filterValues: {name: FilterName, value: any[]}[];
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any> | null;
    dropdownOverflow?: boolean;
    noTags?: boolean;
}

const singleSelects: FilterName[] = [ProductionFilter.ASSEMBLY_NAME];

export const FilterGrid = (props: FilterGridProps) => {
    const dispatch = useAppDispatch();
    const [width, setWidth] = useState(100);

    const handleSelect = (e: any, data: any ) => {
        let values = data.values;
        dispatch(props.dispatchFnFilters({name: data.name, value: values}));
    }

    const getFilterValue = (name: FilterName): any[] => {
        for (let filter of props.filterValues) {
            if (filter.name === name) {
                return filter.value;
            }
        }
        return [];
    }
    const getFiltersList = () => {
        const filters = props.filters.map((filter, ind) => {
            return <div  className="filter-item" key={filter}>
                <DropdownFilter
                    key={`${filter}-${props.grid}-${ind}`}
                    multiselect={singleSelects.includes(filter) ? false: true}
                    name={filter}
                    label={filter}
                    payload={filter === ComponentFilter.SUBCATEGORY ? {category: getFilterValue(ComponentFilter.CATEGORY).map(item => item.value)} : {}}
                    onSelection={handleSelect}
                    selections={getFilterValue(filter)}
                    dropdownOverflow={props.dropdownOverflow}
                />
            </div>
        })
        return filters;
    }

    const handleDelteTag = (name: FilterName, value: any) => {
        const values = getFilterValue(name);
        dispatch(props.dispatchFnFilters({name: name, value: values.filter(val => val.value !== value.value)}));
    }

    const getTags = () => {
        let tags: any[] = [];
        props.filterValues?.forEach(filter => {
            for (let val of filter.value) {
                if (!(typeof val === 'string' || typeof val === 'number' || val instanceof Date)) {
                    tags.push(<Label className="custom-label" key={filter.name+val.value}
                        onClick={() => handleDelteTag(filter.name, val)}
                        as='a' tag>{val.value}<Icon name='delete' /></Label>)
                }
            }
        })
        return tags;
    }



    const getChildren = () => {
        if (props.children) {
            if (Array.isArray(props.children) && props.children.length > 0) {
                return props.children.map((child, ind) => {
                    return <div className="filter-item" key={`child-${ind}`}>
                        {child}
                    </div>
                })
            } else {
                return <div className="filter-item" key={"child"}>
                    {props.children}
                </div>
            }
        }
    }

    return <div className="filter-grid" id={props.grid}>
        <div className="filters-section">
            {getChildren()}
            {getFiltersList()}
        </div>
        {props.noTags ? null : 
        <div className="tags">
            {getTags()}
        </div>}
    </div>
}