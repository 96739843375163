// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
    height: 100%;
    display: flex;
    background-color: var(--primary-background);
}

/* .main-container .content-container {
    width: 94%;
} */

.main-container .main-element {
    height: 100%;
    width: 88%;
}

.main-container .main-element .components-container {
    width: 88%;
    height: 92%;
    position: fixed;
    top: 7%;
    background: #F5F7FB;
    padding: 0;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/containers/MainContainer/MainContainer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,2CAA2C;AAC/C;;AAEA;;GAEG;;AAEH;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,UAAU;IACV,WAAW;IACX,eAAe;IACf,OAAO;IACP,mBAAmB;IACnB,UAAU;IACV,SAAS;AACb","sourcesContent":[".main-container {\n    height: 100%;\n    display: flex;\n    background-color: var(--primary-background);\n}\n\n/* .main-container .content-container {\n    width: 94%;\n} */\n\n.main-container .main-element {\n    height: 100%;\n    width: 88%;\n}\n\n.main-container .main-element .components-container {\n    width: 88%;\n    height: 92%;\n    position: fixed;\n    top: 7%;\n    background: #F5F7FB;\n    padding: 0;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
