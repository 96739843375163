import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { AssemblyFilter } from '../../utils/enums';

export interface Filters {
    type: string[],
    searchText: string,
}

export interface AssemblyState {
    assemblies: any[];
    selectedIndex: number;
    totalPages: number;
    pageNumber: number;
    limit: number;
    totalResults: number;
    currentResults: number;
    filterValues: {name: AssemblyFilter, value: any}[],
    searchText: string;
    componentsSearchText: string;
    subAsmSearchText: string;
    showForms: {add: boolean, delete: boolean, edit: boolean, updateParts: boolean, updateSubAsm: boolean};
    editIndex: number;
    deleteIndex: number;
};

const initialState: AssemblyState = {
    assemblies: [],
    selectedIndex: 0,
    totalPages: 1,
    pageNumber: 1,
    limit: 20,
    totalResults: 0,
    currentResults: 0,
    filterValues: [],
    searchText: "",
    componentsSearchText: "",
    subAsmSearchText: "",
    showForms: {add: false, delete: false, edit: false, updateParts: false, updateSubAsm: false},
    editIndex: 0,
    deleteIndex: 0,
}

const assemblySlice: Slice = createSlice({
    name: 'assembly',
    initialState: initialState,
    reducers: {
        setAssemblies: (state: AssemblyState, action: PayloadAction<any[]>) => {
            state.assemblies = action.payload;
        },
        setSelectedIndex: (state: AssemblyState, action: PayloadAction<number>) => {
            state.selectedIndex = action.payload;
        },
        setPageNumber: (state: AssemblyState, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setPagination: (state: AssemblyState, action: PayloadAction<{ 
            pageNumber: number; limit: number, totalResults: number, totalPages: number, currentResults: number
        }>) => {
            state.pageNumber = action.payload.pageNumber;
            state.limit = action.payload.limit
            state.currentResults = action.payload.currentResults;
            state.totalPages = action.payload.totalPages;
            state.totalResults = action.payload.totalResults;
        },
        setFilters: (state: AssemblyState, action: PayloadAction<{name: AssemblyFilter; value: any}>) => {
            let filterIndex = state.filterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.filterValues[filterIndex].value = action.payload.value;
            } else {
                state.filterValues.push({...action.payload})
            }
        },
        setSearchText: (state: AssemblyState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        setComponentsSearchText: (state: AssemblyState, action: PayloadAction<string>) => {
            state.componentsSearchText = action.payload;
        },
        setSubAsmSearchText: (state: AssemblyState, action: PayloadAction<string>) => {
            state.subAsmSearchText = action.payload;
        },
        setAddForm: (state: AssemblyState, action: PayloadAction<boolean>) => {
            state.showForms.add = action.payload;
        },
        setEditForm: (state: AssemblyState, action: PayloadAction<boolean>) => {
            state.showForms.edit = action.payload;
        },
        setUpdateForm: (state: AssemblyState, action: PayloadAction<{name: string, value: boolean}>) => {
            if (action.payload.name === "parts") {
                state.showForms.updateParts = action.payload.value;
            } else {
                state.showForms.updateSubAsm = action.payload.value;
            }
        },
        setDeleteForm: (state: AssemblyState, action: PayloadAction<boolean>) => {
            state.showForms.delete = action.payload;
        },
        setEditIndex: (state: AssemblyState, action: PayloadAction<number>) => {
            state.editIndex = action.payload;
        },
        setDeleteIndex: (state: AssemblyState, action: PayloadAction<number>) => {
            state.deleteIndex = action.payload;
        }
    },
})

export const assemblyActions = {
    setAssemblies: assemblySlice.actions.setAssemblies,
    setSelectedIndex: assemblySlice.actions.setSelectedIndex,
    setPagination: assemblySlice.actions.setPagination,
    setPageNumber: assemblySlice.actions.setPageNumber,
    setFilters: assemblySlice.actions.setFilters,
    setSearchText: assemblySlice.actions.setSearchText,
    setComponentsSearchText: assemblySlice.actions.setComponentsSearchText,
    setSubAsmSearchText: assemblySlice.actions.setSubAsmSearchText,
    setAddForm: assemblySlice.actions.setAddForm,
    setDeleteForm: assemblySlice.actions.setDeleteForm,
    setEditForm: assemblySlice.actions.setEditForm,
    setUpdateForm: assemblySlice.actions.setUpdateForm,
    setEditIndex: assemblySlice.actions.setEditIndex,
    setDeleteIndex: assemblySlice.actions.setDeleteIndex,
}

export default assemblySlice.reducer;