import { useEffect, useState } from "react"
import { assemblyAPIs } from "../../apis/assembly";
import { DataGrid, DeleteIcon, EditIcon, Row, RowHeader, RowsContainer, SaveIcon } from "../../components";
import { DeleteForm } from "../../components/DeleteForm/DeleteForm";
import { HeaderItem, RowItem } from "../../components/RowsContainer";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AssemblyState } from "../../store/reducers/assemblyReducer";

export const AssemblyPart = (props: {
    index: number,
    colorInd: number,
}) => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);
    const { common } = actions;
    let initialPartObj = state.assemblies[state.selectedIndex].parts ?
        JSON.parse(JSON.stringify(state.assemblies[state.selectedIndex].parts[props.index])) : {};
    const [updateObj, setUpdateObj] = useState(state.assemblies[state.selectedIndex]);
    const [partObj, setPartObj] = useState(initialPartObj);
    const dispatch = useAppDispatch();
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const handleChange = (e: any) => {
        setPartObj({
            ...partObj,
            quantity: parseFloat(e.target.value)
        })
    }

    useEffect(() => {
        let initialPartObj = state.assemblies[state.selectedIndex].parts ?
        JSON.parse(JSON.stringify(state.assemblies[state.selectedIndex].parts[props.index])) : {};
        setPartObj(initialPartObj);
    }, [props.index])

    const handleSave = async () => {
        let assemblyObj = JSON.parse(JSON.stringify(state.assemblies[state.selectedIndex]));
        assemblyObj.parts[props.index] = {...partObj};
        await assemblyAPIs.updateAssembly(assemblyObj, assemblyObj._id);
        setShowEdit(false);
        dispatch(common.setFlyMsg({msg: `Updated quantity for the part "${partObj.partName}" in assembly "${assemblyObj.name}"`}));    
    }

    const handleDelete = () => {
        let assemblyObj = JSON.parse(JSON.stringify(state.assemblies[state.selectedIndex]));
        assemblyObj.parts.splice(props.index);
        setUpdateObj(assemblyObj);
        setShowDelete(true);
    }

    return <><Row key={partObj.name} color={props.index%2 === 0 ? false : true}>
        <RowItem width={10} value={`${props.colorInd+1}`}></RowItem>
        <RowItem width={20} value={partObj.partName}></RowItem>
        <RowItem width={20} value={partObj.partNumber}></RowItem>
        {!showEdit ? <RowItem width={40} value={`${partObj.quantity} pc`}></RowItem> :
            <RowItem width={40}>
                <input type="number" onChange={handleChange} defaultValue={partObj.quantity}></input>
                <span>pc</span>
            </RowItem>}
        <RowItem width={10}>
            { !showEdit ? 
                [<DeleteIcon onClick={handleDelete}></DeleteIcon>,
                <EditIcon onClick={() => {setShowEdit(true)}} ></EditIcon>]:
                    <SaveIcon onClick={handleSave}></SaveIcon>}
        </RowItem>
    </Row>
    {showDelete? <DeleteForm
        icon="AssemblyActive"
        showModal={showDelete}
        closeFn={() => {setShowDelete(false)}}
        name="Assembly Part"
        value={partObj.partName}
        payload={updateObj}
        deleteFn={assemblyAPIs.updateAssembly}
        ></DeleteForm> : <></>
    }
    </>
}

export const AssemblyPartsHeader = () => {
    return <RowHeader height="20%">
        <HeaderItem key="heading-s-no" width={10} value="S.No"></HeaderItem>
        <HeaderItem key="heading-name" width={20} value="Part Name"></HeaderItem>
        <HeaderItem key="heading-number" width={20} value="Part Number"></HeaderItem>
        <HeaderItem key="heading-quantity" width={40} value="Quantity"></HeaderItem>
        <HeaderItem key="heading-action" width={10} value="Action"></HeaderItem>
    </RowHeader>
}

export const AssemblyPartsDataGrid = () => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);

    const searchPassPart = (part: any) => {
        let text = state.componentsSearchText.toLowerCase();
        let include = part.partName.toLowerCase().includes(text) || part.partNumber.toLowerCase().includes(text)
            || part.quantity.toString().toLowerCase().includes(text)
        return include;
    }

    const getData = () => {
        if (state.assemblies.length === 0) {
            return <RowsContainer></RowsContainer>
        }
        let parts = [];
        let ind  = 0;
        for (let i = 0; i < state.assemblies[state.selectedIndex].parts.length; i++) {
            let part = state.assemblies[state.selectedIndex].parts[i];
            if (searchPassPart(part)) {
                parts.push(<AssemblyPart colorInd={ind} index={i} key={i+part.partName}></AssemblyPart>)
                ind++;
            }
        }        
        return <RowsContainer height="80%">{parts}</RowsContainer>;
    }

    return <DataGrid height="72%">
        <AssemblyPartsHeader></AssemblyPartsHeader>
        {getData()}
    </DataGrid>
}
