import React, { createRef } from "react";
import "./Dropdown.css";
import { v4 as uuidv4 } from 'uuid';
import { DropdownContainer, DropdownFilterProps, InputBox } from "./DropdownParts";

const initialState: any = {
    isOpen: false,
    searchText: "",
    pos: { left: 0, right: 0, top: 0, bottom: 0},
}

export class DropdownFilter extends React.Component<DropdownFilterProps, any>{
    constructor(props: DropdownFilterProps) {
        super(props);
        this.state = { ...initialState, id: uuidv4() }
    }
    wrapperRef: any = createRef();
    inputRef: any = createRef();

    handleClickOutside = (e: any) => {
        if (
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(e.target)
        ) {
            if (this.state.isOpen) {
                this.setState({
                    isOpen: false,
                    searchText: ""
                })
            }
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        return <div ref={this.wrapperRef} className="custom-dropdown">
            {this.props.label ? <div className="text-label"><span>{this.props.label || this.props.name}</span></div> : null}
            <InputBox
                {...this.props}
                isOpen={this.state.isOpen}
                setPos={(pos: any) => this.setState({pos: pos})}
                setOpen={(isOpen:boolean)=>this.setState({isOpen})}
                setSearchText={(searchText: any) => {this.setState({searchText})}}
            ></InputBox>
            <DropdownContainer
                {...this.props}
                pos={this.state.pos}
                searchText={this.state.searchText}
                isOpen={this.state.isOpen}
                setOpen={(isOpen:boolean)=>this.setState({isOpen})}
            ></DropdownContainer>
        </div>
    }
}