import { getIcons } from "../../utils/commonUtils";
import "./Pagination.css";

export interface PaginationProps {
    resultsInPage: number;
    totalResults: number;
    limit: number;
    totalPages: number;
    currentPage: number;
    setPageFn: Function;
}

export const Pagination = (props: PaginationProps) => {

    const handleNextClick = (e: any) => {
        props.setPageFn(props.currentPage+1);
    }

    const handlePrevClick = (e: any) => {
        props.setPageFn(props.currentPage-1);
    }

    const getShowingText = () => {
        if (props.currentPage === 1) {
            return `Showing 1 to ${props.resultsInPage} of ${props.totalResults}`
        } else {
            let start = props.limit*(props.currentPage-1)+1;
            return `Showing ${start} to ${start+props.resultsInPage-1} of ${props.totalResults}`
        }
    }

    return <div className="pagination">
        <div className="results-text"><span>{getShowingText()}</span></div>
        <div className="prev-icon" onClick={handlePrevClick} hidden={props.currentPage === 1}>{getIcons("Previous")}</div>
        <div className="current-page"><span>{props.currentPage}</span></div>
        <div className="next-icon" onClick={handleNextClick} hidden={props.currentPage === props.totalPages}>{getIcons("Next")}</div>
        <div className="pages-text"><span>{`of ${props.totalPages} Page(s)`}</span></div>
    </div>
}