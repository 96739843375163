const baseUrl = `${process.env.API_ENDPOINT}/inv-ms/api/v0`;
const authEndPoint = `${baseUrl}/auth`;
const productsEndPoint = `${baseUrl}/products`;
const categoriesEndPoint = `${baseUrl}/categories`;
const subcategoriesEndPoint = `${baseUrl}/subcategories`;
const purchasesEndPoint = `${baseUrl}/purchases`;
const componentEndPoint = `${baseUrl}/parts`;
const footprintsEndPoint = `${baseUrl}/footprints`;
const manufacturersEndPoint = `${baseUrl}/manufacturers`;
const suppliersEndPoint = `${baseUrl}/suppliers`;
const assemblyEndPoint = `${baseUrl}/products`;
const inventoryEndPoint = `${baseUrl}/inventory`;
const stockEndPoint = `${baseUrl}/stocks`;
const assemblyTypesEndPoint = `${baseUrl}/assembly_types`;
const pricesEndPoint = `${baseUrl}/prices`;
const productionPlan = `${baseUrl}/productionPlan`;

const urls = {
    login: `${authEndPoint}/login`,
    register: `${authEndPoint}/register`,
    logout: `${authEndPoint}/logout`,
    products: productsEndPoint,
    categories: categoriesEndPoint,
    subcategories: subcategoriesEndPoint,
    purchases: purchasesEndPoint,
    purchasesBulk: `${purchasesEndPoint}/bulk`,
    components: componentEndPoint,
    footprints: footprintsEndPoint,
    manufacturers: manufacturersEndPoint,
    suppliers: suppliersEndPoint,
    assembly: assemblyEndPoint,
    inventory: inventoryEndPoint,
    stock: stockEndPoint,
    assemblyTypes: assemblyTypesEndPoint,
    prices: pricesEndPoint,
    plans: productionPlan,
}

export default urls;
