// import * as icons from "../assets/Icons";
import { AccountsIcon, AssemblyIcon, ComponentsIcon, DashboardIcon, InventoryIcon, OrdersIcon, PricesIcon, ProductionIcon, PurchasesIcon, ReportsIcon, SettingsIcon, StocksIcon, SuppliersIcon } from "../assets/Icons/icons";
import AssemblyActiveIcon from "../assets/Icons/AccountsActive.svg";
import DashboardActiveIcon from "../assets/Icons/DashboardActive.svg";
import OrdersActiveIcon from "../assets/Icons/OrdersActive.svg";
import ComponentsActiveIcon from "../assets/Icons/ComponentsActive.svg";
import InventoryActiveIcon from "../assets/Icons/InventoryActive.svg";
import StocksActiveIcon from "../assets/Icons/StocksActive.svg";
import ReportsActiveIcon from "../assets/Icons/ReportsActive.svg";
import SuppliersActiveIcon from "../assets/Icons/SuppliersActive.svg";
import SettingsActiveIcon from "../assets/Icons/SettingsActive.svg";
import AccountsActiveIcon from "../assets/Icons/AccountsActive.svg";
import PurchasesActiveIcon from "../assets/Icons/PurchasesActive.svg";
import ProductionActiveIcon from "../assets/Icons/ProductionActive.svg";
import PricesActiveIcon from "../assets/Icons/PricesActive.svg";
import { PreviousIcon, NextIcon, DeleteIcon } from "../components";
import { actions } from "../store";

export const getUserTypes = (): any => {
    return [{
        key: 'admin',
        value: 'admin',
        text: 'Admin'
    }, {
        key: 'store manager',
        value: 'store manager',
        text: 'Store Manager'
    }, {
        key: 'purchase incharge',
        value: 'purchase incharge',
        text: 'Purchase Incharge'
    }, {
        key: 'accountant',
        value: 'accountant',
        text: 'Accountant'
    }, {
        key: 'store staff',
        value: 'store staff',
        text: 'Store Staff'
    }]
}

export const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const getSubTabs = (tabName: string): string[] => {
    const subTabs: any = {
        "inventory": ["Products", "Subcategories", "Categories", "Stocks"],
        "home": ["Dashboard", "Analytics"],
        "purchases": ["Add", "View"],
        "prices": ["Set New", "Current", "History"],
        "orders": ["Place New", "Accepted", "Delivered", "History"],
        "bills": ["Today", "History"],
        "accounts": ["Purchases", "Salaries", "Attendance"]
    }

    return subTabs[tabName] || [];
}

export const getDropdownOptions = (data: any) => {
    return {
        key: data.id,
        value: data.id,
        text: data.display
    }
}

export const getUnits = () => {
    let options = [];
    for (let i = 0; i < 10; i++) {
        options.push({
            key: `${i+1}`,
            value: i+1,
            text: `${i+1}`,
        })
    }
    return options;
}

export const getUnitTypes = () => [
    {
        key: 'bags',
        text: 'bags',
        value: 'bags',
    },
    {
        key: 'trays',
        text: 'trays',
        value: 'trays',
    },
    {
        key: 'boxes',
        text: 'boxes',
        value: 'boxes',
    },
    {
        key: 'dzns',
        text: 'dzns',
        value: 'dzns',
    },
    {
        key: 'loose',
        text: 'loose',
        value: 'loose',
    },
    {
        key: 'gela',
        text: 'gela',
        value: 'gela',
    }
]

export const getGradeTypes = () => [
    {
        key: 'Store',
        text: 'Store',
        value: 'Store',
    },
    {
        key: 'Hotel',
        text: 'Hotel',
        value: 'Hotel',
    }
]

export const getPriceTiers = () => [
    {
        key: 'MRP',
        text: 'MRP',
        value: 'MRP',
    },
    {
        key: 'Nominal Price',
        text: 'Nominal Price',
        value: 'Nominal Price',
    },
    {
        key: 'Bulk Price',
        text: 'Bulk Price',
        value: 'Bulk Price',
    }
]

export const getProductTypes = () => [
    {
        key: 'End Product',
        text: 'End Product',
        value: 'End Product',
    },
    {
        key: 'Combo',
        text: 'Combo',
        value: 'Combo',
    }
]

export const getQuantityUnitsList = () => [
    {
        key: "kg",
        text: "kg",
        value: "kg",
    },
    {
        key: "pcs",
        text: "pcs",
        value: "pcs",
    },
    {
        key: "g",
        text: "g",
        value: "g",
    },
    {
        key: "L",
        text: "L",
        value: "L",
    },
    {
        key: "mL",
        text: "mL",
        value: "mL",
    },
]

export const getPurchasedBy = () => [
    {
        key: "Raju",
        text: "Raju",
        value: "Raju",
    },
    {
        key: "Prudhvi",
        text: "Prudhvi",
        value: "Prudhvi",
    },
    {
        key: "Vamshi",
        text: "Vamshi",
        value: "Vamshi",
    },
    {
        key: "Murali",
        text: "Murali",
        value: "Murali",
    },
    {
        key: "Naresh",
        text: "Naresh",
        value: "Naresh",
    },
]

export const getPurchasedAt = () => [
    {
        key: "NTR Nagar Market Shop No 49",
        text: "NTR Nagar Market Shop No 49",
        value: "NTR Nagar Market Shop No 49",
    },
    {
        key: "Bowenpally Market Shop No. 50",
        text: "Bowenpally Market Shop No. 50",
        value: "Bowenpally Market Shop No. 50",
    },
    {
        key: "Uday's Farm",
        text: "Uday's Farm",
        value: "Uday's Farm",
    },
]

export const getQuantityOptions = () => {
    const options = [];
    for (let i = 1; i <= 1000; i++) {
        options.push({
            text: `${i}`, value: i, key:`${i}`
        })
    }
    return options;
}

export const getPaymentModes = async () => {
    return {
        results: [
            {
                name: "Cash",
            },
            {
                name: "Credit Card",
            },
            {
                name: "Debit Card",
            },
            {
                name: "Net Banking",
            },
            {
                name: "UPI/Wallets",
            },
        ]
    }
}

export const getPurchaseModes = async () => {
    return {
        results: [
            {
                name: "Online",
            },
            {
                name: "Walk In Purchase",
            },
        ]
    }
}

export const getPurchaseUnits = async () => {
    const units = ["pcs", "reels", "box", "sheet", "kg", "mL", "L", "g", "mg", "lot", "bag"]
    return {
        results: units.map(unit => {return {name: unit}})
    }
}

export const getDeliveryStatus = async () => {
    const status = ["Order Placed", "In Transit", "In Customs", "Out for Delivery", "Delivered"]
    return {
        results: status.map(unit => {return {name: unit}})
    }
}

export const getPackingStatus = () => [
    {
        key: "Accepted",
        text: "Accepted",
        value: "Accepted",
    },
    {
        key: "Packed",
        text: "Packed",
        value: "Packed",
    }
]

export const getBusinessTypes = () => [
    {
        key: "Store",
        text: "Store",
        value: "Store",
    },
    {
        key: "Hotel",
        text: "Hotel",
        value: "Hotel",
    },
    {
        key: "Individual",
        text: "Individual",
        value: "Individual",
    },
]

export const getMonthNames = () => [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

export interface QuantityObj {
    quantity: number;
    quantityUnit: string;
}

export const getQuantity = (name: string): QuantityObj => {
    const quantity: any = {
        "reels":{
            quantity: 10000,
            quantityUnit: "pc"
        },
        "g": {
            quantity: 0.001,
            quantityUnit: "kg"
        },
        "mL": {
            quantity: 0.001,
            quantityUnit: "L"
        }
    }
    return quantity[name] || {quantity: 1, quantityUnit: name};
}

export const getIcons = (name: string) => {
    switch(name) {
        case "Assembly": return <AssemblyIcon/>;
        case "Dashboard": return <DashboardIcon/>;
        case "Orders": return <OrdersIcon/>;
        case "Components": return <ComponentsIcon/>;
        case "Inventory": return <InventoryIcon/>;
        case "Stocks": return <StocksIcon/>;
        case "Reports": return <ReportsIcon/>;
        case "Suppliers": return <SuppliersIcon/>;
        case "Settings": return <SettingsIcon/>;
        case "Accounts": return <AccountsIcon/>;
        case "Purchases": return <PurchasesIcon/>;
        case "Production": return <ProductionIcon/>;
        case "Prices": return <PricesIcon/>;
        case "AssemblyActive": return <AssemblyActiveIcon/>;
        case "DashboardActive": return <DashboardActiveIcon/>;
        case "OrdersActive": return <OrdersActiveIcon/>;
        case "ComponentsActive": return <ComponentsActiveIcon/>;
        case "InventoryActive": return <InventoryActiveIcon/>;
        case "StocksActive": return <StocksActiveIcon/>;
        case "ReportsActive": return <ReportsActiveIcon/>;
        case "SuppliersActive": return <SuppliersActiveIcon/>;
        case "SettingsActive": return <SettingsActiveIcon/>;
        case "AccountsActive": return <AccountsActiveIcon/>;
        case "PurchasesActive": return <PurchasesActiveIcon/>;
        case "ProductionActive": return <ProductionActiveIcon/>;
        case "PricesActive": return <PricesActiveIcon/>;
    }
}

export const iconMaps: any = {
    components: getIcons("ComponentsActive"),
    assembly: getIcons("AssemblyActive"),
    prices: getIcons("PricesActive"),
    purchases: getIcons("PurchasesActive"),
    stocks: getIcons("StocksActive"),
    inventory: getIcons("InventoryActive"),
    reports: getIcons("ReportsActive"),
    dashboard: getIcons("DashboardActive"),
    production: getIcons("ProductionActive"),
    orders: getIcons("OrdersActive"),
    suppliers: getIcons("SuppliersActive"),
}

export const getActions = (name: string) => {
    switch(name) {
        case "components":
            return actions.components;
        case "assembly":
            return actions.assembly;
        case "purchases":
            return actions.purchases;
        case "prices":
            return actions.prices;
        case "stocks":
            return actions.stocks;
        default:
            return actions.common;
    }
}