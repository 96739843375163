import { useState } from "react"
import { FormField, Step } from "semantic-ui-react"
import Add from "@mui/icons-material/Add";
import { plansAPIs } from "../../apis"
import { AddNewButton, Button, Form, PreviousIcon, MainSection, 
    Container, Modal, NavBar, Section, VerticalSection, NextIcon, InputTextField, Breadcrumb, DeleteIcon, XIcon, CommandBar, AddtoIcon, SaveButton } from "../../components"
import { DropdownField } from "../../components/Dropdown/DropdownField"
import { DropdownFilter } from "../../components/Dropdown/DropdownFilter"
import { actions } from "../../store"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { ProductionState } from "../../store/reducers/productionReducer"
import { ProductsState } from "../../store/reducers/productsReducer"
import { AssemblyFilter, FilterGridType, ProductionFilter } from "../../utils/enums"
import { NewStep, PlanSummary } from "./PlanStep"
import "./Process.css"
import { useHistory } from "react-router";

export const CreatePlan = () => {
    const state = useAppSelector((state: any): ProductionState => state.production)
    const [currIndex, setCurrIndex] = useState(0);
    const [displaySummary, setDisplaySummary] = useState(false);
    const [displayStart, setDisplayStart] = useState(false);
    const { production, common } = actions;
    const history = useHistory();
    const dispatch = useAppDispatch()

    const handleNameChange = (e: any, index: number) => {
        let value = e.target.value;
        dispatch(production.setStepMetaData({name: value, index: index}))
    }

    const handleDeleteStep = (i: number) => {
        const steps = JSON.parse(JSON.stringify(state.newPlan.steps));
        steps.splice(i,1)
        if (currIndex > steps.length-1) {
            setCurrIndex(steps.length-1)
        }
        dispatch(production.setNewPlanData({...state.newPlan, steps: steps}));
    }

    const getBreadCrumbs = () => {
        let breadcrumbs= [];
        for (let i = 0; i < state.newPlan.steps.length; i++) {
            breadcrumbs.push(<Breadcrumb  active={i === currIndex && !displaySummary}>
                <XIcon onClick={() => {handleDeleteStep(i)}}></XIcon>
                <div onClick={() => {setCurrIndex(i); setDisplaySummary(false)}} className="step-no"><span>{i+1}</span></div>
                {(currIndex === i && !displaySummary) ? <div className="step-name">
                    <input autoFocus={currIndex === i && !displaySummary} value={state.newPlan.steps[i].name} onChange={(e) => {handleNameChange(e, i)}}></input></div>:
                <div onClick={() => {setCurrIndex(i); setDisplaySummary(false)}} className="step-name"><span>{state.newPlan.steps[i] ? state.newPlan.steps[i].name : ""}</span></div>}
            </Breadcrumb>)
        }
        
        breadcrumbs.push(<Breadcrumb className="new-step">
            <Add htmlColor="white"></Add>
            <div onClick={handleAddNewStep} className="step-name"><span>Step</span></div>
        </Breadcrumb>)

        breadcrumbs.push(<Breadcrumb  active={currIndex === state.newPlan.steps.length || displaySummary}>
            <div onClick={() => {setDisplaySummary(true)}} className="step-name"><span>Summary</span></div>
        </Breadcrumb>)
        return breadcrumbs;
    }

    const handleAddNewStep = () => {
        dispatch(production.setNewStep({index: state.newPlan.steps.length}))
        setCurrIndex(state.newPlan.steps.length);
    }

    const handleSave = async () => {
        await plansAPIs.addPlan(state.newPlan);
        dispatch(common.setFlyMsg({msg: "Plan saved successfully."}))
        dispatch(production.resetNewPlan({}));
        setCurrIndex(0);
    }

    const handlePlanNameChange = (e: any) => {
        dispatch(production.setNewPlanData({...state.newPlan, planName: e.target.value}))
    }

    const handleMainAssemblySelection = (e: any, data: any) => {
        if (data.values.length > 0) {
            dispatch(production.setNewPlanData({...state.newPlan,
                mainAssemblyName: data.values[0].value,
                mainAssemblyId: data.values[0].key,
            }))    
        }
    }

    const handleBackClick = () => {
        history.push("/production")
    }
    return <Container>
        <NavBar
            name="production"
            sectionName="Create A Production Plan"
        />
        <MainSection>
            <div className="assembly-details">
                <div className="action-buttons">
                    <Button onClick={handleBackClick} label="Back"></Button>
                </div>
                <div className="fields">
                    <div className="plan-field">
                        <div className="label"><span>Plan Name</span></div>
                        <FormField><InputTextField 
                            value={state.newPlan.planName} name="planName" formError="" onChange={handlePlanNameChange} ></InputTextField></FormField>
                    </div>
                    <div className="plan-field">
                        <div className="label"><span>Main Assembly</span></div>
                        <FormField><DropdownFilter
                            key="main-assembly"
                            name={ProductionFilter.ASSEMBLY_NAME}
                            onSelection={handleMainAssemblySelection}
                            selections={[
                                {text: state.newPlan.mainAssemblyName || "", value: state.newPlan.mainAssemblyName || "", key: state.newPlan.mainAssemblyId || ""}
                            ]}
                            noCheckbox={false}
                        /></FormField>
                    </div>
                </div>
                <div className="action-buttons">
                    <SaveButton onClick={handleSave} label="Save"></SaveButton>
                </div>
                <div className="breadcrumbs-container">{getBreadCrumbs()}</div>  
            </div>
            <div className="plan-container">
                {!displaySummary ? <NewStep
                    index={currIndex}
                ></NewStep> : <PlanSummary></PlanSummary>}
            </div>
        </MainSection>
    </Container>
}