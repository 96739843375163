
import axios from 'axios';
import urls from './urls';

export const authAPIs = {
    login: async (payload: any) => {
        const body = {...payload}
        try {
            const response = await axios.post(urls.login, body);
            return response.data;
        } catch (error) {
            let err: any = error;
            return {
                err: err.response.data.message
            }
        }
    },
    logout: async () => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            const response = await axios.post(urls.logout,{}, {headers});
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            return response.data;
        } catch (error) {
            let err: any = error;
            return {
                err: err.response.data.message
            }
        }
    },
    register: async (payload: any) => {
        const body = {
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            roleType: payload.userType,
            password: payload.password
        }
        try {
            const response = await axios.post(urls.register, body);
            return response.data;
        } catch (error) {
            let err: any = error;
            return {
                err: err.response.data.message
            }
        }
    }
}