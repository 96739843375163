import { useEffect, useState } from "react";
import { assemblyAPIs } from "../../apis";
import { Modal, Form, Button } from "../../components";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CommonState } from "../../store/reducers/commonReducer";
import { AssemblyState } from "../../store/reducers/assemblyReducer";

export const EditAssembly = () => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { common, assembly } = actions;
    const dispatch = useAppDispatch();
    const initialObj = {...state.assemblies[state.editIndex]};
    const [asmObj, setAsmObj] = useState(initialObj);
    const [showLoader, setShowLoader] = useState(false);
    const errObj: any = {};
    const [formErrors, setFormErrors] = useState(errObj)
    const [err, setErr] = useState({show: false, msg: ""});

    useEffect(() => {
        const initialObj = {...state.assemblies[state.editIndex]};
        setAsmObj(initialObj)
        setFormErrors({})
    }, [state.editIndex])

    useEffect(() => {
        if (Object.values(formErrors).indexOf(true) > -1) {
            setErr({show: true, msg: "These fields are mandatory"});
        } else {
            setErr({show: false, msg: ""});
        }
    }, [Object.values(formErrors).includes(true)])

    const validateFields = () => {
        let errors: any = {};
        for (let key of ["name", "assemblyNumber", "type", "description"]) {
            if (["", null, undefined, []].indexOf(asmObj[key]) > -1) {
                errors[key] = true
            } else if (asmObj[key].length === 0) {
                errors[key] = true
            }
        }
        return errors;
    }

    const handleSubmit = async () => {
        let errors = validateFields();
        if (Object.values(errors).indexOf(true) > -1) {
            setFormErrors(errors);
            return;
        }
        setShowLoader(true);
        await assemblyAPIs.updateAssembly({
            ...asmObj
        }, asmObj._id);
        setShowLoader(false);
        dispatch(assembly.setEditForm(false));
        dispatch(common.setRefresh({name: "Edit Assembly", value: !commonState.refresh.value}));
        dispatch(common.setFlyMsg({msg: `Assembly ${asmObj.name} edited successfully.`}))
    }

    return <Modal
        size="small"
        iconName="AssemblyActive"
        title={`Edit Assembly "${asmObj.name}"`}
        key={"edit-assembly-"+state.editIndex}
        showLoader={showLoader}
        actions={[
            <Button onClick={() => {dispatch(assembly.setEditForm(false))}} label="Cancel"/>,
            <Button onClick={handleSubmit} label="Edit"/>
        ]}
        errorMsg={err.show ? err.msg : ""}
    >
        <Form
            id={asmObj._id}
            fields={["type", "description"]}
            formErrors={formErrors}
            formObj={asmObj}
            setFormErrors={(obj: any) => setFormErrors(obj)}
            setFormObj={(obj: any) => setAsmObj(obj)}
            showLoader={showLoader}
            key={"add-new-assembly"}
        />
    </Modal>
}

export const AddAssembly = () => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { common, assembly } = actions;
    const dispatch = useAppDispatch();
    const initialObj: any = {};
    const [asmObj, setAsmObj] = useState(initialObj);
    const [showLoader, setShowLoader] = useState(false);
    const errObj: any = {};
    const [formErrors, setFormErrors] = useState(errObj)
    const [err, setErr] = useState({show:false, msg: ""})

    useEffect(() => {
        if (Object.values(formErrors).indexOf(true) > -1) {
            setErr({show: true, msg: "These fields are mandatory"});
        } else {
            setErr({show: false, msg: ""});
        }
    }, [Object.values(formErrors).includes(true)])

    const validateFields = () => {
        let errors: any = {};
        for (let key of ["name", "assemblyNumber", "type", "description"]) {
            if (["", null, undefined, []].indexOf(asmObj[key]) > -1) {
                errors[key] = true
            } else if (asmObj[key].length === 0) {
                errors[key] = true
            }
        }
        return errors;
    }

    const handleSubmit = async () => {
        let errors = validateFields();
        if (Object.values(errors).indexOf(true) > -1) {
            setFormErrors(errors);
            return;
        }
        setShowLoader(true);
        await assemblyAPIs.addAssembly(asmObj);
        setShowLoader(false);
        dispatch(assembly.setAddForm(false));
        dispatch(common.setRefresh({ name: "Add Assembly", value: !commonState.refresh.value }));
        dispatch(common.setFlyMsg({ msg: `New Assembly ${asmObj.name} added successfully.` }))
    }

    const handleClose = () => {
        dispatch(assembly.setAddForm(false));
    }

    return <Modal
        size="small"
        iconName="AssemblyActive"
        title="Add New Assembly"
        key={"add-assembly"}
        showLoader={showLoader}
        actions={[
            <Button onClick={handleClose} label="Cancel"/>,
            <Button onClick={handleSubmit} label="Save"/>
        ]}
        errorMsg={err.show ? err.msg : ""}
    >
        <Form
            id={asmObj._id}
            fields={["name", "assemblyNumber", "type", "description"]}
            formErrors={formErrors}
            formObj={asmObj}
            setFormErrors={(obj: any) => setFormErrors(obj)}
            setFormObj={(obj: any) => setAsmObj(obj)}
            showLoader={showLoader}
            key={"add-new-assembly"}
        />
    </Modal>
}