import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import { purchasesAPIs } from "../../apis";
import { Row, DeleteIcon, EditIcon, RowHeader, RowsContainer, DataGrid, ExpandIcon, CollapseIcon } from "../../components";
import { RowItem, HeaderItem } from "../../components/RowsContainer";
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { CommonState } from "../../store/reducers/commonReducer";
import { PurchasesState } from "../../store/reducers/purchasesReducer";

export const PurchaseRecord = (props: {index: number}) => {
    const [expand, setExpand] = useState(false);
    const dispatch = useDispatch();
    const { purchases } = actions;
    const state = useAppSelector((state: any): PurchasesState => state.purchases);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const initialObj = {...state.purchases[props.index]};
    const [purchaseObj, setPurchaseObj] = useState(initialObj);

    useEffect(() => {
        const initialObj = {...state.purchases[props.index]};
        setPurchaseObj(initialObj);
    }, [props.index, state.purchases[props.index]],)

    const handleEdit = () => {
        dispatch(purchases.setEditForm(true))
        dispatch(purchases.setEditIndex(props.index))
    }

    const getPurchaseItemsHeader = () => {
        return <RowHeader className="expand-section">
            <HeaderItem width={10} value={"Part Name"}></HeaderItem>
            <HeaderItem width={10} value={"Part Number"}></HeaderItem>
            <HeaderItem width={10} value={"Status"}></HeaderItem>
            <HeaderItem width={10} value={"Delivery Date"}></HeaderItem>
            <HeaderItem width={10} value={"Units"}></HeaderItem>
            <HeaderItem width={10} value={"Total Quantity"}></HeaderItem>
            <HeaderItem width={10} value={"Total Tax"}></HeaderItem>
            <HeaderItem width={10} value={"Total Charges"}></HeaderItem>
            <HeaderItem width={10} value={"Total Price"}></HeaderItem>
            <HeaderItem width={10} value={"Rate"}></HeaderItem>
        </RowHeader>
    }

    const getPurchaseItems = (purchaseItems: any[]) => {
        return <RowsContainer className="expand-section">
            {
                purchaseItems.map(item => {
                    return <Row>
                        <RowItem width={10} value={item.partName}></RowItem>
                        <RowItem width={10} value={item.partNumber}></RowItem>
                        <RowItem width={10} value={item.deliveryStatus}></RowItem>
                        <RowItem width={10} value={(item.deliveryDate ? `(${item.deliveryDate.split("T")[0]})` : "") }></RowItem>
                        <RowItem width={10} value={item.noOfUnits + " " + item.unitType}></RowItem>
                        <RowItem width={10} value={item.totalQuantity+" "+ item.quantityUnit}></RowItem>
                        <RowItem width={10} value={item.totalTax + " ₹"}></RowItem>
                        <RowItem width={10} value={item.totalCharges + " ₹"}></RowItem>
                        <RowItem width={10} value={item.totalPrice + " ₹"}></RowItem>
                        <RowItem width={10} value={item.pricePerQuantity+ "/" + item.quantityUnit}></RowItem>
                    </Row>
                })
            }
        </RowsContainer>
    }

    return <><Row color={props.index%2 === 0 ? false : true} key={purchaseObj.name}>
        <RowItem width={10} value={purchaseObj.purchasedDate}></RowItem>
        <RowItem width={10} value={purchaseObj.deliveryStatus}></RowItem>
        <RowItem width={10} value={purchaseObj.deliveryDate || ""}></RowItem>
        <RowItem width={10} value={purchaseObj.paymentMode}></RowItem>
        <RowItem width={10} value={purchaseObj.purchasedBy}></RowItem>
        <RowItem width={10} value={`${purchaseObj.grandTotalPrice}(${purchaseObj.currencySymbol})`}></RowItem>
        <RowItem width={30} value={purchaseObj.note}></RowItem>
        <RowItem width={10}>
            {purchaseObj.deliveryStatus !== "Delivered" ? <EditIcon onClick={handleEdit}></EditIcon> : <></>}
            {expand ? <CollapseIcon onClick={() => {setExpand(false)}}/>: <ExpandIcon onClick={() => {setExpand(true)}}/>}
        </RowItem>
    </Row>
    {expand ? <DataGrid>
        {getPurchaseItemsHeader()}
        {getPurchaseItems(purchaseObj.purchaseItems)}
    </DataGrid>:null}
    </>
}

export const PurchaseHeader = () => {
    return <RowHeader>
        <HeaderItem width={10} value="Date"></HeaderItem>
        <HeaderItem width={10} value="Delivery Status"></HeaderItem>
        <HeaderItem width={10} value="Delivery Date"></HeaderItem>
        <HeaderItem width={10} value="Payment Mode"></HeaderItem>
        <HeaderItem width={10} value="Purchased By"></HeaderItem>
        <HeaderItem width={10} value="Cost"></HeaderItem>
        <HeaderItem width={30} value="Details"></HeaderItem>
        <HeaderItem width={10} value="Actions"></HeaderItem>
    </RowHeader>
}

export const PurchaseDataGrid = () => {
    const state = useAppSelector((state: any): PurchasesState => state.purchases);
    const getData = () => {
        return <RowsContainer>
            {state.purchases.map((comp, ind) => {
                return <PurchaseRecord index={ind}></PurchaseRecord>
            })}
        </RowsContainer>
    }

    return <DataGrid>
        <PurchaseHeader></PurchaseHeader>
        {getData()}
    </DataGrid>
}