import { RowHeader } from ".";
import "./components.css";
export const DataGrid = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    width?: string;
    height?: string;
}) => {
    return <div style={{width: props.width, height: props.height}}
        className={props.className ? `data-grid ${props.className}` : "data-grid"}>
        {props.children}
    </div>
}