
import axios from 'axios';
import urls from './urls';
import * as qs from 'query-string';

export const subcategoriesAPIs = {
    addSubcategory: async (payload: {
        name: string,
        category: string | string[],
        image?: any
    }) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "multipart/form-data"
            }
            const formData = new FormData();
            formData.append('name', payload.name)
            formData.append('category', typeof payload.category === 'object' ? payload.category[0] : payload.category)
            if (payload.image) {
                formData.append('imgLarge', payload.image)
            }
            const response = await axios.post(urls.subcategories, formData, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    getSubcategoriesData: async (payload: {
        pageNumber: number;
        limit: number;
        category?: string[];
        searchText?: string;
    }) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            let params: any = {
                "pageNumber": payload.pageNumber,
                "limit": payload.limit,
            }

            if (payload.category && payload.category.length > 0) {
                params.category = payload.category
            }
            
            if (payload.searchText) {
                params.searchText = payload.searchText;
            }

            let response = await axios.get(urls.subcategories, {
                params,
                paramsSerializer: params => qs.stringify(params),
                headers });
            return response.data.data;
        } catch (error) {
            console.log(error);
            
            let err: any = error;
            return err.error;
        }
    },
    deleteSubcategory: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            }
            const response = await axios.delete(`${urls.subcategories}/${payload.id}`, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
}