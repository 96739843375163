import { useEffect, useState } from "react";
import { Dimmer, Icon, Loader } from "semantic-ui-react"
import { componentsAPIs } from "../../apis";
import { assemblyAPIs } from "../../apis/assembly";
import { AddtoIcon, Button, CommandBar, DataGrid, DeleteIcon, Modal, MultiDeleteIcon, RowHeader, SaveIcon, Section, SplitSection } from "../../components";
import { HeaderItem, Row, RowItem, RowsContainer } from "../../components/RowsContainer";
import { Searchbox } from "../../components/Searchbox/Searchbox";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AssemblyState } from "../../store/reducers/assemblyReducer";
import { CommonState } from "../../store/reducers/commonReducer";
import { getIcons } from "../../utils/commonUtils"
import { FilterGridType } from "../../utils/enums";

export interface UpdateComponentsProps {
    showModal: boolean;
    closeFn: Function;
}

export interface UpdateComponentsState {
    showLoader: boolean;
    isEdited: boolean;
    errorMsg: null | string;
}

export const UpdateComponents = (props: UpdateComponentsProps) => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { assembly, common } = actions;
    const dispatch = useAppDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const initialOptions: any[] = [];
    const [partsOptions, setPartsOptions] = useState(initialOptions);
    const [partsSearchText, setPartsSearchText] = useState("");
    const [searchText, setSearchText] = useState("");
    const [parts, setParts] = useState(state.assemblies[state.selectedIndex].parts)

    useEffect(() => {
        componentsAPIs.getComponentsData({ limit: 1000, pageNumber: 1, searchText: partsSearchText }).then((data) => {
            setPartsOptions(data.results.map((option: any) => {
                return { ...option, quantityUnit: "pcs", quantity: 1 }
            }));
        }).catch(error => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in getting partsOptions"});
        })
    }, [partsSearchText])

    const handleSubmit = async () => {
        setShowLoader(true);
        await assemblyAPIs.updateAssembly({
            ...state.assemblies[state.selectedIndex],
            parts: parts,
            subAssemblies: state.assemblies[state.selectedIndex].subAssemblies
        }, state.assemblies[state.selectedIndex]._id);

        setShowLoader(false);
        props.closeFn(false);
        let msg = `Parts successfully updated for the assembly "${state.assemblies[state.selectedIndex].name}"`;
        dispatch(common.setFlyMsg({ msg: msg }))
    }

    const handleClose = () => {
        props.closeFn(false);
    }

    const handleDelete = (e: any, ind: number) => {
        let copy = JSON.parse(JSON.stringify(parts));
        copy.splice(ind, 1);
        setParts(copy)
    }

    const handleAdd = (ind: number) => {
        let copy = JSON.parse(JSON.stringify(parts));
        copy.push({
            partName: partsOptions[ind].name,
            partId: partsOptions[ind]._id,
            quantityUnit: partsOptions[ind].quantityUnit,
            quantity: partsOptions[ind].quantity,
            partNumber: partsOptions[ind].partNumber
        })
        setParts(copy)
    }

    const handleQuantityChange = (e: any, ind: number) => {
        const copy = JSON.parse(JSON.stringify(partsOptions));
        const option = { ...copy[ind] };
        option.quantity = parseInt(e.target.value);
        copy[ind] = option;
        setPartsOptions(copy);
    }

    const getComponents = () => {
        let partsElem: any = [];
        let ind  = 0;
        for (let i = 0; i < parts.length; i++) {
            let comp: any = parts[i];
            if (searchPassPart(comp)) {
                partsElem.push(<Row color={ind % 2 === 1} key={comp.partName}>
                    <RowItem width={20} value={`${ind + 1}`}></RowItem>
                    <RowItem width={20} value={comp.partName}></RowItem>
                    <RowItem width={20} value={comp.partNumber}></RowItem>
                    <RowItem width={20} value={`${comp.quantity} ${comp.quantityUnit}`}></RowItem>
                    <RowItem width={20}>
                        <DeleteIcon onClick={(e) => handleDelete(e, i)}></DeleteIcon>
                    </RowItem>
                </Row>)
                ind++;
            }
        }
        return partsElem;
    }

    const getPartsOptions = () => {
        return partsOptions.map((option: any, ind) => {
            let isAdded = false;
            let quantityValue = 1;
            parts.forEach((element: any) => {
                if (element.partName === option.name) {
                    isAdded = true;
                    quantityValue = element.quantity;
                }
            });
            return <Row color={ind % 2 === 1} key={option.partName}>
                <RowItem width={25}>
                    {isAdded ? <SaveIcon /> : <AddtoIcon onClick={() => !isAdded ? handleAdd(ind) : {}}/>}</RowItem>
                <RowItem width={25} value={option.name}></RowItem>
                <RowItem width={25} value={option.partNumber}></RowItem>
                <RowItem width={25} className="quantity">
                    {isAdded ? <><span>{`${quantityValue} `}</span><span>pcs</span></> :
                        <><input name="parts" type="number" onChange={(e) => { handleQuantityChange(e, ind) }} defaultValue={quantityValue}></input><span>pcs</span></>}
                </RowItem>
            </Row>
        })
    }

    const handlePartsSearch = (e: any) => {
        setPartsSearchText(e.target.value)
    }

    const handleSearch = (e: any) => {
        setSearchText(e.target.value)
    }

    const searchPassPart = (part: any) => {
        let text = searchText.toLowerCase();
        let include = part.partName.toLowerCase().includes(text) || part.partNumber.toLowerCase().includes(text)
            || part.quantity.toString().toLowerCase().includes(text)
        return include;
    }

    return <Modal
        iconName="AssemblyActive"
        size="full"
        title={`Update Parts for assembly ${state.assemblies[state.selectedIndex].name}`}
        actions={[
            <Button onClick={() => { dispatch(assembly.setUpdateForm({name: "parts", value: false})) }} label="Cancel"/>,
            <Button onClick={handleSubmit} label="Update"/>
        ]}
        errorMsg={""}
    >
        <SplitSection>
            <Section height={"100%"}>
                <CommandBar
                    searchFn={handleSearch}
                    gridType={FilterGridType.ASSEMBLY}
                ></CommandBar>
                <DataGrid>
                    <RowHeader>
                        <HeaderItem width={20} value={"S.No."}></HeaderItem>
                        <HeaderItem width={20} value={"Part Name"}></HeaderItem>
                        <HeaderItem width={20} value={"Part Number"}></HeaderItem>
                        <HeaderItem width={20} value={"Quantity"}></HeaderItem>
                        <HeaderItem width={20} value={"Action"}></HeaderItem>
                    </RowHeader>
                    <RowsContainer>
                        {getComponents()}
                    </RowsContainer>
                </DataGrid>
            </Section>
            <Section height={"100%"}>
                <CommandBar
                    searchFn={handlePartsSearch}
                    gridType={FilterGridType.ASSEMBLY}
                ></CommandBar>
                <DataGrid>
                    <RowHeader>
                        <HeaderItem width={25} value={"Add"}></HeaderItem>
                        <HeaderItem width={25} value={"Part Name"}></HeaderItem>
                        <HeaderItem width={25} value={"Part Number"}></HeaderItem>
                        <HeaderItem width={25} value={"Quantity"}></HeaderItem>
                    </RowHeader>
                    <RowsContainer>
                        {getPartsOptions()}
                    </RowsContainer>
                </DataGrid>
            </Section>
        </SplitSection>
    </Modal>
}

export const UpdateAssemblies = (props: UpdateComponentsProps) => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { assembly, common } = actions;
    const dispatch = useAppDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const initialOptions: any[] = [];
    const [searchText, setSearchText] = useState("");
    const [subAsmOptions, setSubAsmOptions] = useState(initialOptions);
    const [asmSearchText, setAsmSearchText] = useState("");
    const [subAsms, setSubAsms] = useState(state.assemblies[state.selectedIndex].subAssemblies)

    useEffect(() => {
        assemblyAPIs.getAssemblysData({ limit: 1000, pageNumber: 1, searchText: asmSearchText }).then((data) => {
            let options = data.results.map((option: any) => {
                return { ...option, quantityUnit: "pcs", quantity: 1 }
            });
            setSubAsmOptions(options.filter((option: any) => {
                return option._id !== state.assemblies[state.selectedIndex]._id
            }));
        }).catch(error => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in getting partsOptions"});
        })
    }, [asmSearchText])

    const handleSubmit = async () => {
        setShowLoader(true);
        await assemblyAPIs.updateAssembly({
            ...state.assemblies[state.selectedIndex],
            parts: state.assemblies[state.selectedIndex].parts,
            subAssemblies: subAsms
        }, state.assemblies[state.selectedIndex]._id);

        setShowLoader(false);
        props.closeFn(false);
        let msg = `Sub-assemblies successfully updated for the assembly "${state.assemblies[state.selectedIndex].name}"`;
        dispatch(common.setFlyMsg({ msg: msg }))
    }

    const handleDelete = (e: any, ind: number) => {
        let copy = JSON.parse(JSON.stringify(subAsms));
        copy.splice(ind, 1);
        setSubAsms(copy)
    }

    const handleAdd = (ind: number) => {
        let copy = JSON.parse(JSON.stringify(subAsms));
        copy.push({
            assemblyName: subAsmOptions[ind].name,
            assemblyId: subAsmOptions[ind]._id,
            quantityUnit: subAsmOptions[ind].quantityUnit,
            quantity: subAsmOptions[ind].quantity,
            assemblyType: subAsmOptions[ind].type,
            assemblyNumber: subAsmOptions[ind].assemblyNumber
        })
        setSubAsms(copy)
    }

    const handleQuantityChange = (e: any, ind: number) => {
        const copy = JSON.parse(JSON.stringify(subAsmOptions));
        const option = { ...copy[ind] };
        option.quantity = parseInt(e.target.value);
        copy[ind] = option;
        setSubAsms(copy);
    }

    const getSubAssemblies = () => {
        let subAsmsElem: any = [];
        let ind  = 0;
        for (let i = 0; i < subAsms.length; i++) {
            let asm: any = subAsms[i];
            
            if (searchPassAsm(asm)) {
                subAsmsElem.push(<Row color={ind % 2 === 1} key={asm.partName}>
                    <RowItem width={20} value={`${ind + 1}`}></RowItem>
                    <RowItem width={20} value={asm.assemblyName}></RowItem>
                    <RowItem width={20} value={asm.assemblyNumber}></RowItem>
                    <RowItem width={20} value={`${asm.quantity} ${asm.quantityUnit}`}></RowItem>
                    <RowItem width={20}>
                        <DeleteIcon onClick={(e) => handleDelete(e, i)}></DeleteIcon>
                    </RowItem>
                </Row>)
                ind++;
            }
        }
        return subAsmsElem;
    }

    const getSubAssembliesOptions = () => {
        return subAsmOptions.map((option: any, ind) => {
            let isAdded = false;
            let quantityValue = 1;
            subAsms.forEach((element: any) => {
                if (element.partName === option.name) {
                    isAdded = true;
                    quantityValue = element.quantity;
                }
            });
            return <Row color={ind % 2 === 1} key={option.partName}>
                <RowItem width={25}>
                    {isAdded ? <SaveIcon /> : <AddtoIcon onClick={() => !isAdded ? handleAdd(ind) : {}}/>}</RowItem>
                <RowItem width={25} value={option.name}></RowItem>
                <RowItem width={25} value={option.partNumber}></RowItem>
                <RowItem width={25} className="quantity">
                    {isAdded ? <><span>{`${quantityValue} `}</span><span>pcs</span></> :
                        <><input name="subAsms" type="number" onChange={(e) => { handleQuantityChange(e, ind) }} defaultValue={quantityValue}></input><span>pcs</span></>}
                </RowItem>
            </Row>
        })
    }

    const handleAsmSearch = (e: any) => {
        setAsmSearchText(e.target.value)
    }

    const handleSearch = (e: any) => {
        setSearchText(e.target.value)
    }

    const searchPassAsm = (asm: any) => {
        let text = searchText.toLowerCase();
        let include = asm.assemblyName.toLowerCase().includes(text) || asm.assemblyNumber.toLowerCase().includes(text)
            || asm.quantity.toString().toLowerCase().includes(text)
        return include;
    }

    return <Modal
        iconName="AssemblyActive"
        size="full"
        title={`Update Sub-assemblies for assembly ${state.assemblies[state.selectedIndex].name}`}
        actions={[
            <Button onClick={() => { dispatch(assembly.setUpdateForm({name: "assemblies", value: false})) }} label="Cancel"/>,
            <Button onClick={handleSubmit} label="Update"/>
        ]}
        errorMsg={""}
    >
        <SplitSection>
            <Section height={"100%"}>
                <CommandBar
                    searchFn={handleSearch}
                    gridType={FilterGridType.ASSEMBLY}
                ></CommandBar>
                <DataGrid>
                    <RowHeader>
                        <HeaderItem width={20} value={"S.No."}></HeaderItem>
                        <HeaderItem width={20} value={"Assembly Name"}></HeaderItem>
                        <HeaderItem width={20} value={"Assembly Number"}></HeaderItem>
                        <HeaderItem width={20} value={"Quantity"}></HeaderItem>
                        <HeaderItem width={20} value={"Action"}></HeaderItem>
                    </RowHeader>
                    <RowsContainer>
                        {getSubAssemblies()}
                    </RowsContainer>
                </DataGrid>
            </Section>
            <Section height={"100%"}>
                <CommandBar
                    searchFn={handleAsmSearch}
                    gridType={FilterGridType.ASSEMBLY}
                ></CommandBar>
                <DataGrid>
                    <RowHeader>
                        <HeaderItem width={25} value={"Add"}></HeaderItem>
                        <HeaderItem width={25} value={"Assembly Name"}></HeaderItem>
                        <HeaderItem width={25} value={"Assembly Number"}></HeaderItem>
                        <HeaderItem width={25} value={"Quantity"}></HeaderItem>
                    </RowHeader>
                    <RowsContainer>
                        {getSubAssembliesOptions()}
                    </RowsContainer>
                </DataGrid>
            </Section>
        </SplitSection>
    </Modal>
}