import { useEffect, useState } from "react";
import { Loader, Dimmer } from "semantic-ui-react"
import { categoriesAPIs, componentsAPIs, purchasesAPIs, subcategoriesAPIs } from "../../apis";
import { footprintsAPIs } from "../../apis/footprints";
import { manufacturersAPIs } from "../../apis/manufacturers";
import { suppliersAPIs } from "../../apis/suppliers";
import { Button, DataGrid, Form, MainSection, Modal, RowHeader, VerticalSection } from "../../components";
import { CustomDatePicker } from "../../components/CustomDatePicker/CustomDatePicker";
import { DropdownField } from "../../components/Dropdown/DropdownField";
import { HeaderItem, Row, RowItem, RowsContainer } from "../../components/RowsContainer";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CommonState } from "../../store/reducers/commonReducer";
import { PurchasesState } from "../../store/reducers/purchasesReducer";
import { getIcons } from "../../utils/commonUtils";
import { ComponentFilter, PurchasesFilter } from "../../utils/enums";

export interface EditPurchaseProps {
    editFn: Function;
    id: string;
    name: string;
    showModal: boolean;
    closeFn: Function;
    icon: string;
    purchaseObj: any;
}

export interface EditPurchaseState {
    showLoader: boolean;
    isEdited: boolean;
    errorMsg: null | string;
}

export const EditPurchase = (props: any) => {
    const state = useAppSelector((state):PurchasesState => state.purchases);
    const commonState = useAppSelector((state):CommonState => state.common);
    const [showLoader, setShowLoader] = useState(false);
    let initalObj = state.purchases[state.editIndex];
    const [editObj, setEditObj] = useState(initalObj);
    const {common, purchases } = actions;
    const dispatch = useAppDispatch();
    const [errMsg, setErrMsg] = useState("")

    useEffect(() => {
        let initalObj = state.purchases[state.editIndex];
        setEditObj(initalObj);
    }, [state.editIndex])

    useEffect(() => {
        if (editObj.deliveryStatus === "Delivered") {
            setEditObj({...editObj, purchaseItems: editObj.purchaseItems.map((item: any) => {return {...item, deliveryStatus: "Delivered"}})});
        } else {
            setEditObj({...editObj, purchaseItems: editObj.purchaseItems.map((item: any, index: number) => {
                return {...item, deliveryStatus: state.purchases[state.editIndex].purchaseItems[index].deliveryStatus}})});
        }
    }, [editObj.deliveryStatus])

    const handleSubmit = async () => {
        setShowLoader(true);
        let resp = await purchasesAPIs.updatePurchase(editObj, editObj._id);
        dispatch(purchases.setEditForm(false));
        dispatch(common.setFlyMsg({msg: `Purchase edited successfully.`}))
        const savedPurchases = JSON.parse(JSON.stringify(state.purchases));
        savedPurchases[state.editIndex] = {...editObj};
        dispatch(purchases.setPurchases(savedPurchases));
    }

    const handleClose = () => {
        dispatch(purchases.setEditForm(false));
    }

    const getItemsHeader = () => {
        return <RowHeader>
            <HeaderItem width={15} value={"Part Name"}></HeaderItem>
            <HeaderItem width={10} value={"Units"}></HeaderItem>
            <HeaderItem width={10} value={"Quantity"}></HeaderItem>
            <HeaderItem width={10} value={"Total Tax"}></HeaderItem>
            <HeaderItem width={10} value={"Tot. Charges"}></HeaderItem>
            <HeaderItem width={10} value={"Total Price"}></HeaderItem>
            <HeaderItem width={10} value={"Rate"}></HeaderItem>
            <HeaderItem width={25} value={"Status"}></HeaderItem>
            <HeaderItem width={25} value={"Delivery Date"}></HeaderItem>
        </RowHeader>
    }

    const handleDeliveryStatusChange = (e: any, data: any) => {
        let editCopy = JSON.parse(JSON.stringify(editObj));
        editCopy.deliveryStatus = data.values[0].value;
        setEditObj(editCopy);
    }

    const handleDeliveryStatusChangeItem = (e: any, data: any, ind: number) => {
        let editCopy = JSON.parse(JSON.stringify(editObj));
        editCopy.purchaseItems[ind].deliveryStatus = data.values[0].value;
        let allDelivered = editCopy.purchaseItems.every((item: any) => item.deliveryStatus === "Delivered");
        setEditObj({...editCopy, deliveryStatus: allDelivered ? "Delivered": editCopy.deliveryStatus === "Delivered"
            ? state.purchases[state.editIndex].deliveryStatus: editCopy.deliveryStatus});
    }

    const handleDateChange = (data: any) => {
        let editCopy = JSON.parse(JSON.stringify(editObj));
        editCopy.deliveryDate = data.value[0];
        setEditObj(editCopy);
    }

    const handleDateChangeItem = (data: any, ind: number) => {
        let editCopy = JSON.parse(JSON.stringify(editObj));
        editCopy.purchaseItems[ind].deliveryDate = data.value[0];
        setEditObj(editCopy);
    }

    const getPurchaseItems = (purchaseItems: any[]) => {
        return purchaseItems.map((item, ind) => {
            return <Row>
                <RowItem width={15} value={item.partName+`(${item.partNumber})`}></RowItem>
                <RowItem width={10} value={item.noOfUnits + " " + item.unitType}></RowItem>
                <RowItem width={10} value={item.totalQuantity+" "+ item.quantityUnit}></RowItem>
                <RowItem width={10} value={item.totalTax + " ₹"}></RowItem>
                <RowItem width={10} value={item.totalCharges + " ₹"}></RowItem>
                <RowItem width={10} value={item.totalPrice + " ₹"}></RowItem>
                <RowItem width={15} value={item.pricePerQuantity+ "/" + item.quantityUnit}></RowItem>
                {state.purchases[state.editIndex].purchaseItems[ind].deliveryStatus !== "Delivered" ?
                    <RowItem width={25}>
                        <DropdownField
                            multiselect={false}
                            closeOnSelect={true}
                            payload={{}}
                            name={PurchasesFilter.DELIVERY_STATUS}
                            onSelection={(e: any, data: any) => { handleDeliveryStatusChangeItem(e, data, ind)}}
                            key={PurchasesFilter.DELIVERY_STATUS}
                            selections={[{key: item.deliveryStatus,text: item.deliveryStatus, value: item.deliveryStatus}]}
                            addOption={false}
                            noCheckbox={true}
                        />
                    </RowItem>:
                    <RowItem width={25} value={item.deliveryStatus}></RowItem>}
                <RowItem width={25}>
                    <CustomDatePicker 
                        name="Delivery Date"
                        onChange={(data: any) => {handleDateChangeItem(data, ind)}}
                        key="delivery-date-picker"
                        value={item.deliveryDate}
                    />
                </RowItem>
            </Row>
        })
    }

    return <Modal size="full"
        iconName="PricesActive"
        title="Update Delivery Status"
        actions={[
            <Button onClick={handleClose} label="Cancel"/>,
            <Button onClick={handleSubmit} label="Update"/>
        ]}
        errorMsg={errMsg || ""}
    >        
        <VerticalSection height="20%">
            <Form
                readonlyFields={[
                    "purchasedDate", "paymentMode", "note", 
                    "grandTotalPrice"
                ]}
                fields={["deliveryStatus", "deliveryDate"]}
                formErrors={{}}
                formObj={{...editObj}}
                setFormErrors={() => {}}
                setFormObj={setEditObj}
                showLoader={false}
            />
        </VerticalSection>
        <VerticalSection height="100%">
            <DataGrid>
                {getItemsHeader()}
                <RowsContainer>
                    {getPurchaseItems(editObj.purchaseItems)}
                </RowsContainer>    
            </DataGrid>
        </VerticalSection>
    </Modal>
}