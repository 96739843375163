import { useEffect, useState } from "react";
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { NavBar } from "../../components/NavBar/NavBar";
import { assemblyAPIs } from "../../apis/assembly";
import { AssemblyState } from "../../store/reducers/assemblyReducer";
import { AssemblyFilter, FilterGridType } from "../../utils/enums";
import { UpdateAssemblies, UpdateComponents } from "./UpdateAssembly";
import { AssemblyDataGrid } from "./AssemblyData";
import { AssemblyPartsDataGrid } from "./AssemblyPartsData";
import { CommonState } from "../../store/reducers/commonReducer";
import { SubAssembliesDataGrid } from "./SubAssembliesData";
import { SplitSection, Container, Section, CommandBar, VerticalSection, AddNewButton } from "../../components";
import { AddAssembly, EditAssembly } from "./AssemblyForms";
import { DeleteForm } from "../../components/DeleteForm/DeleteForm";
import { UpdateButton } from "../../components/Buttons";

export const Assemblies = () => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { assembly, common } = actions;
    const dispatch = useAppDispatch();

    const getFilterValue = (name: AssemblyFilter): any[] => {
        const index = state.filterValues.findIndex(filter => filter.name === name);
        if (index > -1) {
            return state.filterValues[index].value;
        } else {
            return [];
        }
    }

    useEffect(() => {
        assemblyAPIs.getAssemblysData({
            pageNumber: state.pageNumber,
            limit: state.limit,
            searchText: state.searchText,
            type: getFilterValue(AssemblyFilter.ASSEMBLY_TYPE).map(item => item.value)
        }).then((assemblyData: any) => {
            let focusIndex = state.selectedIndex;
            if (focusIndex > (assemblyData.results.length-1)) {
                focusIndex = assemblyData.results.length-1;
                focusIndex = focusIndex > -1 ? focusIndex : 0;
            }
            dispatch(assembly.setSelectedIndex(focusIndex));
            dispatch(assembly.setAssemblies(assemblyData.results));
            dispatch(assembly.setPagination({
                pageNumber: assemblyData.pageNumber,
                limit: assemblyData.limit,
                totalPages: assemblyData.totalPages,
                totalResults: assemblyData.totalResults,
                currentResults: assemblyData.currentResults
            }))
        }).catch((error: any) => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in fetching components data"})
        })
    }, [state.limit, state.pageNumber, state.searchText, state.showForms, commonState.refresh.value, state.filterValues])

    const handleAssemblySearch = (e: any) => {
        dispatch(assembly.setSearchText(e.target.value));
    }

    const handlePartsSearch = (e: any) => {
        dispatch(assembly.setComponentsSearchText(e.target.value));
    }

    const handleSubAsmSearch = (e: any) => {
        dispatch(assembly.setSubAsmSearchText(e.target.value));
    }

    const assemblyFilters = [AssemblyFilter.ASSEMBLY_TYPE];
    return <Container>
        <NavBar
            sectionName="Assembly"
            name="assembly"
        />
        <SplitSection>
            <Section>
                <CommandBar
                    key="assembly"
                    dropdowns={assemblyFilters}
                    dropdownValues={state.filterValues}
                    setDropdownFn={assembly.setFilters}
                    gridType={FilterGridType.ASSEMBLY}
                    searchFn={handleAssemblySearch}
                    actions={[<AddNewButton label={"Add Assembly"}
                            onClick={() => { dispatch(assembly.setAddForm(true)) }}
                        />]}
                    >
                </CommandBar>
                <AssemblyDataGrid></AssemblyDataGrid>
            </Section>
            <Section>
                <VerticalSection>
                    <CommandBar
                        key="assembly-parts"
                        dropdowns={[]}
                        dropdownValues={[]}
                        setDropdownFn={() => {}}
                        gridType={FilterGridType.ASSEMBLY}
                        searchFn={handlePartsSearch}
                        actions={[<UpdateButton label={"Update Parts"}
                                onClick={() => { dispatch(assembly.setUpdateForm({name:"parts", value: true})) }}
                            />,
                        ]}
                    >
                    </CommandBar>
                    <AssemblyPartsDataGrid/>
                </VerticalSection>
                <VerticalSection>
                    <CommandBar
                        key="sub-assembly"
                        dropdowns={[]}
                        dropdownValues={[]}
                        setDropdownFn={() => {}}
                        gridType={FilterGridType.ASSEMBLY}
                        searchFn={handleSubAsmSearch}
                        actions={[<UpdateButton label={"Update Sub Asmb"}
                            onClick={() => { dispatch(assembly.setUpdateForm({name:"assemblies", value: true})) }}
                            />,
                        ]}
                    >
                    </CommandBar>
                    <SubAssembliesDataGrid/>
                </VerticalSection>
            </Section>
        </SplitSection>
        {state.showForms.updateParts ? <UpdateComponents
            showModal={state.showForms.updateParts}
            closeFn={(show: boolean) => {dispatch(assembly.setUpdateForm({name: "parts", value: show}))}}
        ></UpdateComponents>: <></>}
        {state.showForms.updateSubAsm ? <UpdateAssemblies
            showModal={state.showForms.updateSubAsm}
            closeFn={(show: boolean) => {dispatch(assembly.setUpdateForm({name: "subAssemblies", value: show}))}}
        ></UpdateAssemblies>: <></>}
        {state.showForms.add ? <AddAssembly></AddAssembly> : <></>}
        {state.showForms.edit ? <EditAssembly></EditAssembly>: <></>}
        {state.showForms.delete ? <DeleteForm
            icon="AssemblyActive"
            showModal={state.showForms.delete}
            closeFn={() => {dispatch(assembly.setDeleteForm(false))}}
            name="Assembly"
            value={state.assemblies[state.deleteIndex].name}
            payload={state.assemblies[state.deleteIndex]._id}
            deleteFn={assemblyAPIs.deleteAssembly}
        ></DeleteForm> : <></>}
    </Container>
}