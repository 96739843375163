import { useEffect, useState } from "react"
import { DataGrid, DeleteIcon, EditIcon, Row, RowHeader, RowsContainer } from "../../components";
import { HeaderItem, RowItem } from "../../components/RowsContainer";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AssemblyState } from "../../store/reducers/assemblyReducer";

export const AssemblyItem = (props: {index: number}) => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);
    const { assembly } = actions;
    const dispatch = useAppDispatch();
    const initialObj = {...state.assemblies[props.index]};
    const [assemblyObj, setPartObj] = useState(initialObj);

    useEffect(() => {
        const initialObj = {...state.assemblies[props.index]};
        setPartObj(initialObj);
    }, [props.index, state.assemblies[props.index]])

    const handleSelectClick = () => {
       dispatch(assembly.setSelectedIndex(props.index));
    }

    const handleDelete = () => {
        dispatch(assembly.setDeleteForm(true));
        dispatch(assembly.setDeleteIndex(props.index));
    }

    const handleEdit = () => {
        dispatch(assembly.setEditForm(true));
        dispatch(assembly.setEditIndex(props.index));
    }

    return <Row key={assemblyObj.name} onClick={() => {handleSelectClick()}}
            isSelected={state.selectedIndex === props.index}
            color={props.index%2 === 0 ? false : true}>
        <RowItem width={25} value={assemblyObj.name}></RowItem>
        <RowItem width={20} value={assemblyObj.type}></RowItem>
        <RowItem width={40} value={assemblyObj.description}></RowItem>
        <RowItem width={15}>
            <DeleteIcon onClick={handleDelete}/>
            <EditIcon onClick={handleEdit}/>
        </RowItem>
    </Row>
}

export const AssemblyHeader = () => {
    return <RowHeader>
        <HeaderItem key="heading-name" width={25} value="Name"></HeaderItem>
        <HeaderItem key="heading-type" width={20} value="Assembly Type"></HeaderItem>
        <HeaderItem key="heading-desc" width={40} value="Description"></HeaderItem>
        <HeaderItem key="heading-action" width={15} value="Action"></HeaderItem>
    </RowHeader>
}

export const AssemblyDataGrid = () => {
    const state = useAppSelector((state: any): AssemblyState => state.assembly);
    const getData = () => {
        return <RowsContainer>
            {state.assemblies.map((comp, ind) => {
                return <AssemblyItem key={"assembly-item"+ind} index={ind}></AssemblyItem>
            })}
        </RowsContainer>
    }

    return <DataGrid>
        <AssemblyHeader></AssemblyHeader>
        {getData()}
    </DataGrid>
}