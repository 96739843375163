import { ChangeEventHandler } from "react";
import { Icon } from "semantic-ui-react"
import "./Searchbox.css";

export const Searchbox = (props: {
    displayLabel?: boolean,
    key: string,
    onChange: ChangeEventHandler<HTMLInputElement>
}) => {

    return <div className="searchbox">
        {props.displayLabel ? <div className="text-label"><span>Search</span></div>: <></>}
        <div className="inputbox-container">
            <input placeholder={!props.displayLabel ? "Search": ""} onChange={props.onChange}></input>
            <div><Icon name="search"></Icon></div>
        </div>
    </div>
}