// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-bar {
    display: flex;
    height: 8%;
    align-items: center;
    padding-left: 1.5%;
    padding-right: 2%;
    justify-content: space-between;
    width: 100%;
}

.nav-bar .title{
    display: flex;
    width: 40%;
    height: 100%;
    align-items: center;
}

.nav-bar .title .text{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    padding-left: 3%;
}

.nav-bar .title .icon {
    background: #E5E5E5;
    border-radius: 17px;
    justify-content: center;
    align-items: center;
    width: 39px;
    height: 39px;
    display: flex;
}

.nav-bar .pagination-container{
    display: flex;
    width: 35%;
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;AAChB","sourcesContent":[".nav-bar {\n    display: flex;\n    height: 8%;\n    align-items: center;\n    padding-left: 1.5%;\n    padding-right: 2%;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.nav-bar .title{\n    display: flex;\n    width: 40%;\n    height: 100%;\n    align-items: center;\n}\n\n.nav-bar .title .text{\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: x-large;\n    padding-left: 3%;\n}\n\n.nav-bar .title .icon {\n    background: #E5E5E5;\n    border-radius: 17px;\n    justify-content: center;\n    align-items: center;\n    width: 39px;\n    height: 39px;\n    display: flex;\n}\n\n.nav-bar .pagination-container{\n    display: flex;\n    width: 35%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
