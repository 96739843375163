import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { AssemblyFilter } from '../../utils/enums';

export interface Filters {
    type: string[],
    searchText: string,
}

export interface AssemblyPricesState {
    assemblyPrices: any[];
    selectedIndex: number;
    totalPages: number;
    pageNumber: number;
    limit: number;
    totalResults: number;
    currentResults: number;
    filterValues: {name: AssemblyFilter, value: any}[],
    searchText: string;
    componentsSearchText: string;
    subAsmSearchText: string;
    showForms: {add: boolean, delete: boolean, edit: boolean, update: boolean};
    editIndex: number;
    deleteIndex: number;
};

const initialState: AssemblyPricesState = {
    assemblyPrices: [],
    selectedIndex: 0,
    totalPages: 1,
    pageNumber: 1,
    limit: 20,
    totalResults: 0,
    currentResults: 0,
    filterValues: [],
    searchText: "",
    componentsSearchText: "",
    subAsmSearchText: "",
    showForms: {add: false, delete: false, edit: false, update: false},
    editIndex: 0,
    deleteIndex: 0,
}

const assemblyPricesSlice: Slice = createSlice({
    name: 'assemblyPrices',
    initialState: initialState,
    reducers: {
        setAssemblyPrices: (state: AssemblyPricesState, action: PayloadAction<any[]>) => {
            state.assemblyPrices = action.payload;
        },
        setSelectedIndex: (state: AssemblyPricesState, action: PayloadAction<number>) => {
            state.selectedIndex = action.payload;
        },
        setPageNumber: (state: AssemblyPricesState, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setPagination: (state: AssemblyPricesState, action: PayloadAction<{ 
            pageNumber: number; limit: number, totalResults: number, totalPages: number, currentResults: number
        }>) => {
            state.pageNumber = action.payload.pageNumber;
            state.limit = action.payload.limit
            state.currentResults = action.payload.currentResults;
            state.totalPages = action.payload.totalPages;
            state.totalResults = action.payload.totalResults;
        },
        setFilters: (state: AssemblyPricesState, action: PayloadAction<{name: AssemblyFilter; value: any}>) => {
            let filterIndex = state.filterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.filterValues[filterIndex].value = action.payload.value;
            } else {
                state.filterValues.push({...action.payload})
            }
        },
        setSearchText: (state: AssemblyPricesState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        setComponentsSearchText: (state: AssemblyPricesState, action: PayloadAction<string>) => {
            state.componentsSearchText = action.payload;
        },
        setSubAsmSearchText: (state: AssemblyPricesState, action: PayloadAction<string>) => {
            state.subAsmSearchText = action.payload;
        },
        setAddForm: (state: AssemblyPricesState, action: PayloadAction<boolean>) => {
            state.showForms.add = action.payload;
        },
        setEditForm: (state: AssemblyPricesState, action: PayloadAction<boolean>) => {
            state.showForms.edit = action.payload;
        },
        setUpdateForm: (state: AssemblyPricesState, action: PayloadAction<boolean>) => {
            state.showForms.update = action.payload;
        },
        setDeleteForm: (state: AssemblyPricesState, action: PayloadAction<boolean>) => {
            state.showForms.delete = action.payload;
        },
        setEditIndex: (state: AssemblyPricesState, action: PayloadAction<number>) => {
            state.editIndex = action.payload;
        },
        setDeleteIndex: (state: AssemblyPricesState, action: PayloadAction<number>) => {
            state.deleteIndex = action.payload;
        }
    },
})

export const assemblyPricesActions = {
    setAssemblyPrices: assemblyPricesSlice.actions.setAssemblyPrices,
    setSelectedIndex: assemblyPricesSlice.actions.setSelectedIndex,
    setPagination: assemblyPricesSlice.actions.setPagination,
    setPageNumber: assemblyPricesSlice.actions.setPageNumber,
    setFilters: assemblyPricesSlice.actions.setFilters,
    setSearchText: assemblyPricesSlice.actions.setSearchText,
    setComponentsSearchText: assemblyPricesSlice.actions.setComponentsSearchText,
    setSubAsmSearchText: assemblyPricesSlice.actions.setSubAsmSearchText,
    setAddForm: assemblyPricesSlice.actions.setAddForm,
    setDeleteForm: assemblyPricesSlice.actions.setDeleteForm,
    setEditForm: assemblyPricesSlice.actions.setEditForm,
    setUpdateForm: assemblyPricesSlice.actions.setUpdateForm,
    setEditIndex: assemblyPricesSlice.actions.setEditIndex,
    setDeleteIndex: assemblyPricesSlice.actions.setDeleteIndex,
}

export default assemblyPricesSlice.reducer;