import { useEffect, useState } from "react";
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { purchasesAPIs } from "../../apis";
import { PurchasesState } from "../../store/reducers/purchasesReducer";
import { NavBar } from "../../components/NavBar/NavBar";
import { EditPurchase } from "./EditPurchase";
import { FilterGridType, PurchasesFilter } from "../../utils/enums";
import { CustomDatePicker } from "../../components/CustomDatePicker/CustomDatePicker";
import { CustomRangeSlider } from "../../components/CustomRangeSlider/CustomRangeSlider";
import { Searchbox } from "../../components/Searchbox/Searchbox";
import { PurchaseDataGrid } from "./PurchaseData";
import { AddNewButton, CommandBar, Container, MainSection } from "../../components";
import { useHistory } from "react-router-dom";
import { CommonState } from "../../store/reducers/commonReducer";


export const Purchases = () => {
    const state = useAppSelector((state: any): PurchasesState => state.purchases);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { purchases, common } = actions;
    const dispatch = useAppDispatch();
    const [showForms, setShowForms] = useState({add: false, edit: false, delete: false})
    const history = useHistory();

    const purchasesFilters = [
        PurchasesFilter.PART_NAME, PurchasesFilter.PAYMENT_MODE, PurchasesFilter.DELIVERY_STATUS
    ]

    const getFilterValue = (name: PurchasesFilter): any[] => {
        const index = state.filterValues.findIndex(filter => filter.name === name);
        if (index > -1) {
            return state.filterValues[index].value;
        } else {
            return [];
        }
    }

    useEffect(() => {
        purchasesAPIs.getPurchasesData({
            pageNumber: state.pagination.pageNumber,
            limit: state.pagination.limit,
            searchText: state.searchText,
            partName: getFilterValue(PurchasesFilter.PART_NAME).map(item => item.value),
            paymentMode: getFilterValue(PurchasesFilter.PAYMENT_MODE).map(item => item.value),
            fromPrice: getFilterValue(PurchasesFilter.TOTAL_COST)[0],
            toPrice: getFilterValue(PurchasesFilter.TOTAL_COST)[1],
            fromDate: getFilterValue(PurchasesFilter.FROM_DATE)[0],
            toDate: getFilterValue(PurchasesFilter.TO_DATE)[0],
            deliveryStatus: getFilterValue(PurchasesFilter.DELIVERY_STATUS).map(item => item.value),
        }).then((purchasesData: any) => {
            dispatch(purchases.setPurchases(purchasesData.results));
            dispatch(purchases.setPagination({
                pageNumber: purchasesData.pageNumber,
                limit: purchasesData.limit,
                totalPages: purchasesData.totalPages,
                totalResults: purchasesData.totalResults,
                currentResults: purchasesData.currentResults
            }))
            const selectMap: any = {};
            purchasesData.results.forEach((result:any) => {
                selectMap[result._id] = false;
            })
        }).catch((error: any) => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in fetching components data"})
        })
    }, [state.searchText, state.pagination, JSON.stringify(state.filterValues), showForms])


    const handleSearch = (e: any) => {
        dispatch(purchases.setSearchText(e.target.value));
    }

    const handlePriceSlider = (data: any) => {
        dispatch(purchases.setFilters({name: PurchasesFilter.TOTAL_COST, value: data.values}))
    }

    const handleDatesPick = (data: any) => {
        if (data.name === "From Date") {
            dispatch(purchases.setFilters({name: PurchasesFilter.FROM_DATE, value: data.value}))
        }

        if (data.name === "To Date") {
            dispatch(purchases.setFilters({name: PurchasesFilter.TO_DATE, value: data.value}))
        }
    }


    const getFilters = () => {
        return [<Searchbox
            displayLabel
            key="searchbox"
            onChange={handleSearch}
        />
        ,<CustomDatePicker 
            label="From"
            name="From Date"
            onChange={handleDatesPick}
            key="purchase-date-picker-from"
            value={getFilterValue(PurchasesFilter.FROM_DATE)[0]}
        />,
        <CustomDatePicker 
            label="To"
            name="To Date"
            onChange={handleDatesPick}
            key="purchase-date-picker-to"
            value={getFilterValue(PurchasesFilter.TO_DATE)[0]}
        />,
       <CustomRangeSlider 
            label="Total Cost"
            name="Total Cost"
            minValue={100}
            maxValue={10000}
            setValuesFn={handlePriceSlider}
            key="total-cost-slider"
            id="total-cost-slider"
        />]
    }

    return <Container>
        <NavBar
            sectionName="Purchases"
            name="purchases"
        />
        <MainSection>
            <CommandBar
                setDropdownFn={purchases.setFilters}
                gridType={FilterGridType.PURCHASES}
                searchFn={handleSearch}
                dropdowns={purchasesFilters}
                dropdownValues={state.filterValues}
                actions={[
                    <AddNewButton label="Add Purchases" onClick={() => {history.push("/purchases/create")}}></AddNewButton>
                ]}
            ></CommandBar>
            <PurchaseDataGrid></PurchaseDataGrid>
        </MainSection>
        {state.showForms.edit ? <EditPurchase></EditPurchase>: <></>}
    </Container>
}