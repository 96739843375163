import { useEffect, useState } from "react";
import { Item } from "semantic-ui-react";
import { Row, RowItem, DeleteIcon, RowsContainer, InputTextField, SaveIcon, AddtoIcon, EditIcon } from "../../components";
import { DropdownField } from "../../components/Dropdown/DropdownField";
import { DropdownFilter } from "../../components/Dropdown/DropdownFilter";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AdditionalCharge, AddPurchasesState, PurchaseItem, Tax } from "../../store/reducers/addPurchasesReducer";
import { getQuantity } from "../../utils/commonUtils";
import { ComponentFilter, PurchasesFilter } from "../../utils/enums";

export const NewPurchaseItem = (props: {
    isActive?: boolean;
    setData: Function;
    index: number;
}) => {

    const getNewItem = (): PurchaseItem => {
        if (state.purchaseItems[props.index]) {
            return {...state.purchaseItems[props.index]}
        }
        return {
            partName: "",
            partId: "",
            unitType: "pcs",
            noOfUnits: 1,
            totalQuantity: 1,
            quantityUnit: "pcs",
            netPrice: 0,
            totalPrice: 0,
            pricePerQuantity: 0,
            partNumber: "",
            totalTaxValue: 0,
            totalAdditionalCharge: 0,
            taxes: [],
            additionalCharges: [],
        }
    }
    const state = useAppSelector((state):AddPurchasesState => state.addPurchases);
    const dispatch = useAppDispatch();
    const { common, addPurchases } = actions;
    const [editMode, setEditMode] = useState(true);
    const [purchaseItem, setPurchaseItem] = useState<PurchaseItem>(getNewItem());
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (state.purchaseItems[props.index]) {
            console.log(state.purchaseItems[props.index],'--------------itemadd0');
            
            setPurchaseItem(state.purchaseItems[props.index])
        }
    }, [props.index, state.purchaseItems.length])

    useEffect(() => {
        console.log(purchaseItem,'===============item');
        
        dispatch(addPurchases.setItemAtIndex({item: {...purchaseItem}, index: props.index}));
    }, [purchaseItem])

    useEffect(() => {
        setPurchaseItem({
            ...purchaseItem,
            taxes: purchaseItem.taxes.map(tax => {
                return {
                    ...tax,
                    taxValue: sanitizePriceValue(purchaseItem.netPrice*tax.taxPercent/100)
                }
            }),
            additionalCharges: purchaseItem.additionalCharges.map(charge => {
                return {
                    ...charge,
                    chargeValue: sanitizePriceValue(purchaseItem.netPrice*charge.chargePercent/100)
                }
            }),
        })
    }, [purchaseItem.netPrice])

    useEffect(() => {
        if (purchaseItem.netPrice === 0) {
            setPurchaseItem({
                ...purchaseItem, totalPrice: 0, pricePerQuantity: 0
            })
        } else {
            let totalPrice = purchaseItem.netPrice + purchaseItem.taxes.reduce((a, b) => {return a+b.taxValue}, 0)
                + purchaseItem.additionalCharges.reduce((a, b) => {return a+b.chargeValue}, 0);
            setPurchaseItem({
                ...purchaseItem, totalPrice: totalPrice, pricePerQuantity: sanitizePriceValue(totalPrice/purchaseItem.noOfUnits)
            })
        }
    }, [purchaseItem.taxes, purchaseItem.additionalCharges])

    useEffect(() => {
        setPurchaseItem({
            ...purchaseItem, pricePerQuantity: sanitizePriceValue(purchaseItem.totalPrice/purchaseItem.noOfUnits)
        })
    }, [purchaseItem.totalPrice, purchaseItem.noOfUnits])

    const handleUnitsChange = (e: any, ind: number) => {
        let quantity = getQuantity( purchaseItem.unitType);
        setPurchaseItem({
            ...purchaseItem,
            noOfUnits: parseInt(e.target.value),
            totalQuantity:  parseInt(e.target.value) * quantity.quantity
        })

    }

    const handleUnitTypeChange = (e: any, data: any, ind: number) => {
        let quantity = getQuantity( data.values[0].value);
        setPurchaseItem({
            ...purchaseItem,
            unitType: data.values[0].value,
            totalQuantity: purchaseItem.noOfUnits * quantity.quantity
        })

    }

    const handlePriceChange = (e: any,  ind: number) => {
        let value = e.target.value.replace(/₹/g, "");
        setPurchaseItem({
            ...purchaseItem,
            netPrice: parseFloat(value) ? parseFloat(value) : 0,
        })    
    }

    const handleDelete = (e: any,  ind: number) => {
        let items = JSON.parse(JSON.stringify(state.purchaseItems));
        items.splice(ind, 1);
        dispatch(addPurchases.setPurchaseItems(items));
    }

    const handleNameSelection = (e: any, data: any) => {
        let value = data.values[0];
        
        setPurchaseItem({
            ...purchaseItem,
            partName: value?.value,
            partNumber: value?.object?.partNumber || "",
            partId: value?.key,
        })
    }

    const handleTaxTypeSelection = (e: any, data: any) => {
        setPurchaseItem({
            ...purchaseItem,
            taxes: data.values.map((item: any) => {return {taxType: item.value, taxPercent: 0, taxValue: 0}}),
        })
    }

    const handleChargeTypeSelection = (e: any, data: any) => {
        setPurchaseItem({
            ...purchaseItem,
            additionalCharges: data.values.map((item: any) => {return {chargeType: item.value, chargePercent: 0, chargeValue: 0}}),
        })
    }

    const sanitizePriceValue = (val: number) => {
        if (!val) {
            return 0;
        }
        val = Number(val);
        let value = parseFloat(val.toFixed(2).replace(/^0+/, ''));
        return value;
    }

    const handleTaxChangePercent = (e: any, index: number) => {
        let value = sanitizePriceValue(e.target.value);
        let taxes: Tax[] = JSON.parse(JSON.stringify(purchaseItem.taxes));
        taxes[index].taxPercent = value;
        taxes[index].taxValue = sanitizePriceValue(purchaseItem.netPrice*value/100);
        setPurchaseItem({...purchaseItem, taxes: taxes, totalTaxValue: taxes.reduce((a, b) => {return a+b.taxValue}, 0)});
    }

    const handleTaxChange = (e: any, index: number) => {
        let value = sanitizePriceValue(e.target.value);
        let taxes: Tax[] = JSON.parse(JSON.stringify(purchaseItem.taxes));
        taxes[index].taxValue = value;
        taxes[index].taxPercent = purchaseItem.netPrice > 0 ? sanitizePriceValue(value*100/purchaseItem.netPrice) : 0;
        setPurchaseItem({...purchaseItem, taxes: taxes, totalTaxValue: taxes.reduce((a, b) => {return a+b.taxValue}, 0)});
    }

    const handleChargeChangePercent = (e: any, index: number) => {
        let value = sanitizePriceValue(e.target.value);
        let charges: AdditionalCharge[] = JSON.parse(JSON.stringify(purchaseItem.additionalCharges));
        charges[index].chargePercent = value;
        charges[index].chargeValue = sanitizePriceValue(purchaseItem.netPrice*value/100);
        setPurchaseItem({...purchaseItem, additionalCharges: charges, totalAdditionalCharge: charges.reduce((a, b) => {return a+b.chargeValue}, 0)});
    }

    const handleChargeChange = (e: any, index: number) => {
        let value = sanitizePriceValue(e.target.value);
        let charges: AdditionalCharge[] = JSON.parse(JSON.stringify(purchaseItem.additionalCharges));
        charges[index].chargeValue = value;
        charges[index].chargePercent = purchaseItem.netPrice > 0 ? sanitizePriceValue(value*100/purchaseItem.netPrice) : 0;
        setPurchaseItem({...purchaseItem, additionalCharges: charges, totalAdditionalCharge: charges.reduce((a, b) => {return a+b.chargeValue}, 0)});
    }

    const getTaxes = () => {
        let taxes = purchaseItem.taxes.map((item, index) => {
            return <Row>
                <RowItem width={25} value={item.taxType}></RowItem>
                <RowItem width={30}>
                    <InputTextField
                        type="number"
                        subscript="%"
                        formError={""} name="chargePercent"
                        onChange={(e) => {handleTaxChangePercent(e, index)}}
                        value={item.taxPercent}/>

                </RowItem>
                <RowItem width={45}><InputTextField type="number" subscript="₹" onChange={(e) => {handleTaxChange(e, index)}}
                    formError={""} name="changeValue" value={item.taxValue} />
                </RowItem>
            </Row>
        });
        return <RowsContainer>
            {taxes}
        </RowsContainer>
    }

    const getAdditionalCharges = () => {
        let additionalCharges = purchaseItem.additionalCharges.map((item, index) => {
            return <Row>
                <RowItem width={40} value={item.chargeType}></RowItem>
                <RowItem width={40}>
                    <InputTextField
                        subscript="%"
                        type="number"
                        onChange={(e) => {handleChargeChangePercent(e, index)}}
                        formError={""} name="chargePercent"
                        value={item.chargePercent}/>
                </RowItem>
                <RowItem width={40}>
                    <InputTextField subscript="₹" formError={""} onChange={(e) => {handleChargeChange(e, index)}} type="number"
                        name="changeValue" value={item.chargeValue} />
                </RowItem>
            </Row>
        });
        return <RowsContainer>
            {additionalCharges}
        </RowsContainer>
    }

    const handleSaveItem = () => {
        if (purchaseItem.netPrice === 0 || purchaseItem.noOfUnits === 0 || purchaseItem.partName === "") {
            setValidated(true);
        } else {
            setValidated(false);
            setEditMode(false);
        }
    }

    const handleAdd = () => {
        dispatch(addPurchases.setNewItemAtIndex(state.purchaseItems.length));
    }

    const getEditableRow = () => {
        return <Row>
            <RowItem width={3} value={`${props.index+1}`}></RowItem>
            <RowItem width={8}>
                <DropdownField
                    isError={validated && !purchaseItem.partName}
                    key={props.index+""}
                    name={PurchasesFilter.PART_NAME}
                    onSelection={handleNameSelection}
                    selections={[{key: purchaseItem.partId,
                        text: purchaseItem.partName,
                        value: purchaseItem.partName}]}
                    noCheckbox={true}
                    closeOnSelect={true}   
                ></DropdownField>
            </RowItem>
            <RowItem width={7} value={`${purchaseItem.partNumber || ""}`}></RowItem>
            <RowItem width={10}>
                <InputTextField
                    formError={validated && purchaseItem.noOfUnits === 0 ? "No of units should be greater than 0": ""}
                    name="quantity" value={purchaseItem.noOfUnits || 0} onChange={(e: any) =>{handleUnitsChange(e, props.index)} }></InputTextField>
                <DropdownField
                    isError={validated && purchaseItem.unitType === ""}
                    noCaret={true}
                    key={`unit-type-${props.index}`}
                    name={PurchasesFilter.QUANTITY_UNIT}
                    noCheckbox={true}
                    onSelection={(e: any, data: any) => {handleUnitTypeChange(e, data, props.index)}}
                    selections={[{key: purchaseItem.unitType || "pcs" , value: purchaseItem.unitType || "pcs", text: purchaseItem.unitType || "pcs"}]}
                />
            </RowItem>
            <RowItem width={5} value={`${purchaseItem.totalQuantity} ${purchaseItem.quantityUnit}`}></RowItem>
            <RowItem width={10}>
                <InputTextField formError={validated && purchaseItem.netPrice === 0 ? "Price cannot be 0": ""} name="prices" subscript="₹" value={purchaseItem.netPrice} onChange={(e: any) =>{handlePriceChange(e, props.index)} }></InputTextField> 
            </RowItem>
            <RowItem width={15}>
                <div>
                    <DropdownField
                        name={PurchasesFilter.TAX_TYPE}
                        multiselect={true}
                        selections={purchaseItem.taxes?.map(item => {return {key: item.taxType, value: item.taxType, text: item.taxType}}) || []}
                        onSelection={handleTaxTypeSelection}
                        key={props.index+"tax_type"}
                    ></DropdownField>
                    {getTaxes()}
                </div>
            </RowItem>
            <RowItem width={15}>
                <div>
                <DropdownField
                    name={PurchasesFilter.ADDITIONAL_CHARGES_TYPE}
                    multiselect={true}
                    selections={purchaseItem.additionalCharges?.map(item => {return {key: item.chargeType, value: item.chargeType, text: item.chargeType}}) || []}
                    onSelection={handleChargeTypeSelection}
                    key={props.index+"additional_charge_type"}
                ></DropdownField>
                {getAdditionalCharges()}
                </div>
            </RowItem>
            <RowItem width={10} value={(!purchaseItem.pricePerQuantity) ? "": `${purchaseItem.pricePerQuantity} ₹/${purchaseItem.quantityUnit}`}></RowItem>
            <RowItem width={10} value={purchaseItem.totalPrice > 0 ? purchaseItem.totalPrice +"₹": ""}></RowItem>
            <RowItem width={3} className="action">
                <DeleteIcon onClick={(e) => handleDelete(e, props.index)}></DeleteIcon>
                <SaveIcon onClick={handleSaveItem}></SaveIcon>
                {props.index === state.purchaseItems.length-1 ? <AddtoIcon onClick={handleAdd} size="large"></AddtoIcon>: <></>}
            </RowItem>
        </Row>
    }

    const getSavedRow = () => {
        return <Row>
            <RowItem width={3} value={`${props.index+1}`}></RowItem>
            <RowItem width={8} value={purchaseItem.partName || ""}></RowItem>
            <RowItem width={7} value={purchaseItem.partNumber || ""}></RowItem>
            <RowItem width={10} value={purchaseItem.noOfUnits+ " "+ purchaseItem.quantityUnit}></RowItem>
            <RowItem width={5} value={`${purchaseItem.totalQuantity} ${purchaseItem.quantityUnit}`}></RowItem>
            <RowItem width={10} value={purchaseItem.netPrice+" ₹"}></RowItem>
            <RowItem width={15}
                value={purchaseItem.taxes.filter(item => item.taxValue > 0)
                .map(item => `${item.taxType}: ${item.taxValue}₹ (${item.taxPercent}%)`).join(", ")}>
            </RowItem>
            <RowItem width={15}
                value={purchaseItem.additionalCharges.filter(item => item.chargeValue > 0)
                .map(item => `${item.chargeType}: ${item.chargeValue}₹ (${item.chargePercent}%)`).join(", ")}>
            </RowItem>
            <RowItem width={10} value={(!purchaseItem.pricePerQuantity) ? "": `${purchaseItem.pricePerQuantity} ₹/${purchaseItem.quantityUnit}`}></RowItem>
            <RowItem width={10} value={purchaseItem.totalPrice > 0 ? purchaseItem.totalPrice +"₹": ""}></RowItem>
            <RowItem width={3} className="action">
                <DeleteIcon onClick={(e) => handleDelete(e, props.index)}></DeleteIcon>
                <EditIcon onClick={() => setEditMode(true)}></EditIcon>
                {props.index === state.purchaseItems.length-1 ? <AddtoIcon onClick={handleAdd} size="large"></AddtoIcon>: <></>}
            </RowItem>
        </Row>
    }
    return <div>
        {editMode ? getEditableRow() : getSavedRow()}
    </div>

}