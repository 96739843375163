import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { PurchasesFilter } from '../../utils/enums';
import { Pagination } from './commonReducer';

export interface AddPurchasesState {
    purchaseItems: PurchaseItem[];
    purchaseInfo: any;
    activeIndex: number;
    optionFilterValues: {name: PurchasesFilter, value: any}[],
    options: any[];
};

export interface Tax {
    taxType: string;
    taxPercent: number;
    taxValue: number;
}

export interface AdditionalCharge {
    chargeType: string;
    chargePercent: number;
    chargeValue: number;
}

export interface PurchaseItem {
    partName: string,
    partId: string,
    unitType: string,
    noOfUnits: number,
    totalQuantity: number,
    quantityUnit: string,
    netPrice: number,
    totalPrice: number,
    pricePerQuantity: number,
    partNumber: string,
    totalTaxValue: number,
    totalAdditionalCharge: number,
    taxes: Tax[]
    additionalCharges: AdditionalCharge[]
}

const initialState: AddPurchasesState = {
    purchaseItems: [],
    purchaseInfo: {},
    activeIndex: 0,
    optionFilterValues: [],
    options: [],
}

const addPurchasesSlice: Slice = createSlice({
    name: 'addPurchases',
    initialState: initialState,
    reducers: {
        setPurchaseInfo: (state: AddPurchasesState, action: PayloadAction<any[]>) => {
            state.purchaseInfo = action.payload;
        },
        setPurchaseItems: (state: AddPurchasesState, action: PayloadAction<PurchaseItem[]>) => {
            state.purchaseItems = action.payload;
        },
        setItemAtIndex: (state: AddPurchasesState, action: PayloadAction<{item: PurchaseItem, index: number}>) => {
            state.purchaseItems[action.payload.index] = action.payload.item;
        },
        setNewItemAtIndex: (state: AddPurchasesState, action: PayloadAction<number>) => {
            state.purchaseItems[action.payload] = {
                partName: "",
                partId: "",
                unitType: "pcs",
                noOfUnits: 1,
                totalQuantity: 1,
                quantityUnit: "pcs",
                netPrice: 0,
                totalPrice: 0,
                pricePerQuantity: 0,
                partNumber: "",
                totalTaxValue: 0,
                totalAdditionalCharge: 0,
                taxes: [],
                additionalCharges: [],    
            };
        },
        setActiveIndex: (state: AddPurchasesState, action: PayloadAction<number>) => {
            state.activeIndex = action.payload;
        },
        setOptions: (state: AddPurchasesState, action: PayloadAction<any[]>) => {
            state.options = action.payload;
        },
        setOptionFilters: (state: AddPurchasesState, action: PayloadAction<{name: PurchasesFilter; value: any}>) => {
            let filterIndex = state.optionFilterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.optionFilterValues[filterIndex].value = action.payload.value;
            } else {
                state.optionFilterValues.push({...action.payload})
            }
        },
    },
})

export const addPurchasesActions = {
    setPurchaseInfo: addPurchasesSlice.actions.setPurchaseInfo,
    setPurchaseItems: addPurchasesSlice.actions.setPurchaseItems,
    setItemAtIndex: addPurchasesSlice.actions.setItemAtIndex,
    setNewItemAtIndex: addPurchasesSlice.actions.setNewItemAtIndex,
    setActiveIndex: addPurchasesSlice.actions.setActiveIndex,
    setOptionFilters: addPurchasesSlice.actions.setOptionFilters,
    setOptions: addPurchasesSlice.actions.setOptions,
}

export default addPurchasesSlice.reducer;