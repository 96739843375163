import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { StockFilter } from '../../utils/enums';
import { Pagination } from './commonReducer';

export interface StockState {
    stock: any[];
    pagination: Pagination;
    filterValues: {name: StockFilter, value: any}[],
    searchText: string;
};

const initialState: StockState = {
    stock: [],
    pagination: {
        totalPages: 1,
        pageNumber: 1,
        limit: 20,
        totalResults: 0,
        currentResults: 0,    
    },
    filterValues: [],
    searchText: "",
}

const stockSlice: Slice = createSlice({
    name: 'stock',
    initialState: initialState,
    reducers: {
        setStock: (state: StockState, action: PayloadAction<any[]>) => {
            state.stock = action.payload;
        },
        setPageNumber: (state: StockState, action: PayloadAction<number>) => {
            state.pagination.pageNumber = action.payload
        },
        setPagination: (state: StockState, action: PayloadAction<{ 
            pageNumber: number; limit: number, totalResults: number, totalPages: number, currentResults: number
        }>) => {
            state.pagination.pageNumber = action.payload.pageNumber;
            state.pagination.limit = action.payload.limit
            state.pagination.currentResults = action.payload.currentResults;
            state.pagination.totalPages = action.payload.totalPages;
            state.pagination.totalResults = action.payload.totalResults;
        },
        setFilters: (state: StockState, action: PayloadAction<{name: StockFilter; value: any}>) => {
            let filterIndex = state.filterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.filterValues[filterIndex].value = action.payload.value;
            } else {
                state.filterValues.push({...action.payload})
            }
        },
        setSearchText: (state: StockState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
    },
})

export const stockActions = {
    setStock: stockSlice.actions.setStock,
    setPagination: stockSlice.actions.setPagination,
    setPageNumber: stockSlice.actions.setPageNumber,
    setFilters: stockSlice.actions.setFilters,
    setSearchText: stockSlice.actions.setSearchText,
}

export default stockSlice.reducer;