import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Menu, Icon } from "semantic-ui-react";
import { actions } from "../../store";
import { CommonState } from "../../store/reducers/commonReducer";
import { getSubTabs } from "../../utils/commonUtils";
import { convertToSnakeCase } from "../../utils/stringUtils";
import "./HeaderBar.css";
import { TabName } from "../../utils/enums";


export const HeaderBar = () => {
    const state = useAppSelector((state: any): CommonState => state.common);
    // const [redirect, setRedirect] = useState(false);
    // const [showLogoutModal, setShowLogoutModal] = useState(false);
    // const authState = useAppSelector((state: any): AuthState => state.auth);
    const { common } = actions;
    const dispatch = useAppDispatch();

    const handleClick = (e: any, data: any) => {
        dispatch(common.setSelectedSubTab(data.name))
    }

    const getMenuItems = () => {
        const tabName: string = state.selectedTab;
        const subTabs = getSubTabs(tabName);
        return subTabs.map((subTabName: string) => {
            let subTab = convertToSnakeCase(subTabName.toLowerCase());
            return <Menu.Item
                key={`${tabName}/${subTab}`}
                name={subTab}
                onClick={handleClick}
                active={state.selectedSubTab[tabName as TabName] === subTab}>
                    {subTabName}
                    
                </Menu.Item>
        })
    }

    return (
        <Menu className="header-bar">
                <Menu.Menu position='right'>
                    <div className="notification-icon">
                        <Icon  name='bell outline' size='large' />
                    </div>
                    <div className="user-dropdown">
                        <Icon name="user circle" size="large" />
                            <span>User Name</span>
                        <Icon name="angle down" size="large" />
                    </div>
                </Menu.Menu>
            </Menu>
    )
}