import { useEffect, useState } from "react";
import { componentsAPIs } from "../../apis";
import { Modal, Form, Button } from "../../components";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CommonState } from "../../store/reducers/commonReducer";
import { ComponentsState } from "../../store/reducers/componentsReducer";

export const EditComponent = () => {
    const state = useAppSelector((state: any): ComponentsState => state.components);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { common, components } = actions;
    const dispatch = useAppDispatch();
    const initialObj = {...state.components[state.editIndex]};
    const [partObj, setPartObj] = useState(initialObj);
    const [showLoader, setShowLoader] = useState(false);
    const errObj: any = {};
    const [formErrors, setFormErrors] = useState(errObj)
    const [err, setErr] = useState({show: false, msg: ""});

    useEffect(() => {
        const initialObj = {...state.components[state.editIndex]};
        setPartObj(initialObj)
        setFormErrors({})
    }, [state.editIndex])

    useEffect(() => {
        if (Object.values(formErrors).indexOf(true) > -1) {
            setErr({show: true, msg: "These fields are mandatory"});
        } else {
            setErr({show: false, msg: ""});
        }
    }, [Object.values(formErrors).includes(true)])

    const validateFields = () => {
        let errors: any = {};
        for (let key of ["category", "subcategory", "footPrint", "manufacturer", "supplier", "description"]) {
            if (["", null, undefined, []].indexOf(partObj[key]) > -1) {
                errors[key] = true
            } else if (partObj[key].length === 0) {
                errors[key] = true
            }
        }
        return errors;
    }

    const handleSubmit = async () => {
        let errors = validateFields();
        if (Object.values(errors).indexOf(true) > -1) {
            setFormErrors(errors);
            return;
        }
        setShowLoader(true);
        await componentsAPIs.updateComponent({
            ...partObj
        }, partObj._id);
        setShowLoader(false);
        dispatch(components.setEditForm(false));
        dispatch(common.setRefresh({name: "Edit Component", value: !commonState.refresh.value}));
        dispatch(common.setFlyMsg({msg: `Component ${partObj.name} edited successfully.`}))
    }

    return <Modal
        size="small"
        iconName="ComponentsActive"
        title="Edit Components"
        key={"edit-components-"+state.editIndex}
        showLoader={showLoader}
        actions={[
            <Button onClick={() => {dispatch(components.setEditForm(false))}} label="Cancel"/>,
            <Button onClick={handleSubmit} label="Edit"/>
        ]}
        errorMsg={err.show ? err.msg : ""}
    >
        <Form
            id={partObj._id}
            fields={[ "category", "subcategory", "footPrint",
            "manufacturer", "supplier", "description"]}
            formErrors={formErrors}
            formObj={partObj}
            setFormErrors={(obj: any) => setFormErrors(obj)}
            setFormObj={(obj: any) => setPartObj(obj)}
            showLoader={showLoader}
            key={"add-new-component"}
        />
    </Modal>
}

export const AddComponent = () => {
    const state = useAppSelector((state: any): ComponentsState => state.components);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { common, components } = actions;
    const dispatch = useAppDispatch();
    const initialObj: any = {};
    const [partObj, setPartObj] = useState(initialObj);
    const [showLoader, setShowLoader] = useState(false);
    const errObj: any = {};
    const [formErrors, setFormErrors] = useState(errObj)
    const [err, setErr] = useState({show:false, msg: ""})

    useEffect(() => {
        if (Object.values(formErrors).indexOf(true) > -1) {
            setErr({show: true, msg: "These fields are mandatory"});
        } else {
            setErr({show: false, msg: ""});
        }
    }, [Object.values(formErrors).includes(true)])

    const validateFields = () => {
        let errors: any = {};
        for (let key of ["name", "partNumber", "category", "subcategory", "footPrint", "manufacturer", "supplier", "description"]) {
            if (["", null, undefined, []].indexOf(partObj[key]) > -1) {
                errors[key] = true
            } else if (partObj[key].length === 0) {
                errors[key] = true
            }
        }
        return errors;
    }

    const handleSubmit = async () => {
        let errors = validateFields();
        if (Object.values(errors).indexOf(true) > -1) {
            setFormErrors(errors);
            return;
        }
        setShowLoader(true);
        await componentsAPIs.addComponent(partObj);
        setShowLoader(false);
        dispatch(components.setAddForm(false));
        dispatch(common.setRefresh({ name: "Add Component", value: !commonState.refresh.value }));
        dispatch(common.setFlyMsg({ msg: `New Component ${partObj.name} added successfully.` }))
    }

    const handleClose = () => {
        dispatch(components.setAddForm(false));
    }

    return <Modal
        size="small"
        iconName="ComponentsActive"
        title="Add New Component"
        key={"add-component"}
        showLoader={showLoader}
        actions={[
            <Button onClick={handleClose} label="Cancel"/>,
            <Button onClick={handleSubmit} label="Save"/>
        ]}
        errorMsg={err.show ? err.msg : ""}
    >
        <Form
            id={partObj._id}
            fields={["name", "partNumber", "category", "subcategory", "footPrint",
                "manufacturer", "supplier", "description"]}
            formErrors={formErrors}
            formObj={partObj}
            setFormErrors={(obj: any) => setFormErrors(obj)}
            setFormObj={(obj: any) => setPartObj(obj)}
            showLoader={showLoader}
            key={"add-new-component"}
        />
    </Modal>
}