import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { TabName, SubtabName } from '../../utils/enums';

export interface AddForms {
    inventory: boolean
}

export interface EditForms {
    inventory: boolean
}

export interface CommonState {
    selectedTab: TabName;
    selectedSubTab: {[key in TabName]: SubtabName};
    flyMsg: {msg: string, isError: boolean};
    refresh: {name: string, value: boolean};
};

export interface Pagination {
    totalPages: number;
    pageNumber: number;
    limit: number;
    totalResults: number;
    currentResults: number;
}

const getInitialState = (): CommonState => {
    const selectedTab = localStorage.getItem("tab") || TabName.INVENTORY;
    const subTab = localStorage.getItem("subtab");
    const initialState: CommonState = {
        selectedTab: selectedTab as TabName,
        selectedSubTab: {
            [TabName.INVENTORY]: SubtabName.PRODUCTS,
            [TabName.ACCOUNTS]: SubtabName.HISTORY,
            [TabName.BILLS]: SubtabName.HISTORY,
            [TabName.HOME]: SubtabName.DASHBOARD,
            [TabName.ORDERS]: SubtabName.HISTORY,
            [TabName.PRICES]: SubtabName.HISTORY,
            [TabName.PURCHASES]: SubtabName.ADD
        },
        flyMsg: {msg: "Welcome to the Portal", isError: false},
        refresh: {name: "Home", value: false}
    }

    if (subTab) {
        initialState.selectedSubTab[selectedTab as TabName] = subTab as SubtabName;
    }
    return initialState;
}

const commonSlice: Slice = createSlice({
    name: 'common',
    initialState: getInitialState(),
    reducers: {
        setSelectedTab: (state: CommonState, action: PayloadAction<TabName>) => {
            state.selectedTab = action.payload;
            localStorage.setItem("tab", action.payload);
        },
        setSelectedSubTab: (state: CommonState,
            action: PayloadAction<SubtabName>) => {
            state.selectedSubTab[state.selectedTab] = action.payload;
            localStorage.setItem("subtab", action.payload);
        },
        setFlyMsg: (state: CommonState,
            action: PayloadAction<{msg: string, isError?: boolean}>) => {
                state.flyMsg.msg = action.payload.msg;
                state.flyMsg.isError = action.payload.isError ? true: false;
        },
        setRefresh: (state: CommonState,
            action: PayloadAction<{name: string, value: boolean}>) => {
                state.refresh.name = action.payload.name;
                state.refresh.value = action.payload.value;
        },
    },
})

export const commonActions = {
    setSelectedTab: commonSlice.actions.setSelectedTab,
    setSelectedSubTab: commonSlice.actions.setSelectedSubTab,
    setFlyMsg: commonSlice.actions.setFlyMsg,
    setRefresh: commonSlice.actions.setRefresh,
}

export default commonSlice.reducer;