import { MouseEventHandler, useEffect, useState } from "react";
import "./components.css";

export const RowsContainer = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    width?: string;
    height?: string;
}) => {
    
    return <div style={{width: props.width, height: props.height}}
    className={props.className ? `rows-container ${props.className}` : "rows-container"}>
        {props.children}
    </div>
}

export const RowHeader = (props: {
    className?: string;
    headings?: string[];
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any> ;
    onClick?: MouseEventHandler<HTMLDivElement>
    width?: string;
    height?: string;
}) => {
    const getItems = () => {
        if (props.headings) {
            return props.headings.map(value => {
                return <HeaderItem key={value} value={value}></HeaderItem>
            })
        } else {
            return props.children
        }
    }

    return <div style={{width: props.width, height: props.height}}
        onClick={props.onClick} className={props.className ? `row-header ${props.className}` : "row-header"}>
        {getItems()}
    </div>
}

export const HeaderItem = (props: {
    className?: string;
    value?: string;
    width?: number;
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any> ;
    onClick?: MouseEventHandler<HTMLDivElement>
}) => {
    return <div style={{width: `${props.width}%`}} onClick={props.onClick} className={props.className ? `${"header-item"} ${props.className}` : "header-item"}>
        {props.value ? <span>{props.value}</span>: props.children}
    </div>
}

export const Row = (props: {
    className?: string;
    color?: boolean;
    isSelected?: boolean;
    values?: string[];
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any> ;
    onClick?: MouseEventHandler<HTMLDivElement>
}) => {

    const getItems = () => {
        if (props.values) {
            return props.values.map(value => {
                return <RowItem key={value} value={value}></RowItem>
            })
        } else {
            return null;
        }
    }

    let className = props.color ? "row colored" : "row";
    className = props.isSelected ? className + " highlight" : className;
    return <div onClick={props.onClick} className={props.className ? `${className} ${props.className}` : className}>
        {getItems()}
        {props.children ? props.children : null}
    </div>
}

export const RowItem = (props: {
    className?: string;
    value?: string;
    width?: number;
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any> ;
    onClick?: MouseEventHandler<HTMLDivElement>
}) => {
    return <div style={{width: `${props.width}%`}} onClick={props.onClick} className={props.className ? `${"row-item"} ${props.className}` : "row-item"}>
        {props.value ? <span>{props.value}</span>: props.children}
    </div>
}