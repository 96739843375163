import { MouseEventHandler } from "react"
import { Icon } from "semantic-ui-react"
import { getIcons } from "../utils/commonUtils";
import { CustomIcon } from "../assets/Icons/icons";

export const DeleteIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons delete ${props.className}` : "icons delete"}>
        <Icon name="trash"></Icon>
    </div>
}

export const EditIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons edit ${props.className}` : "icons edit"}>
        <Icon name="pencil"></Icon>
    </div>
}

export const MultiDeleteIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons mulit-delete ${props.className}` : "icons multi-delete"}>
        {getIcons("Delete")}
    </div>
}

export const SaveIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick?: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons edit ${props.className}` : "icons edit"}>
        <Icon color="green" name="check"></Icon>
    </div>
}

export const AddtoIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick?: MouseEventHandler;
    size?: string;
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons edit ${props.className}` : "icons edit"}>
        <Icon size={props.size === "large" ? "large" : "small"} color="blue" name="plus circle"></Icon>
    </div>
}

export const SidebarIcon = (props: {
    active?: boolean
    name: string;
}) => {
    return <CustomIcon name={props.name} active={props.active}></CustomIcon>
}

export const ExpandIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons expand ${props.className}` : "icons expand"}>
        <Icon size="large" color="blue" name="angle down"></Icon>
    </div>
}

export const CollapseIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons expand ${props.className}` : "icons expand"}>
        <Icon size="large" color="blue" name="angle right"></Icon>
    </div>
}

export const PreviousIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons expand ${props.className}` : "icons expand"}>
        <Icon size="massive" color="blue" name="angle left"></Icon>
    </div>
}

export const NextIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons expand ${props.className}` : "icons expand"}>
        <Icon size="massive" color="blue" name="angle right"></Icon>
    </div>
}

export const RightArrowIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick?: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons expand ${props.className}` : "icons expand"}>
        <Icon size="massive" color="blue" name="arrow right"></Icon>
    </div>
}

export const XIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick?: MouseEventHandler
}) => {
    return <div onClick={props.onClick} className={props.className ? `icons x ${props.className}` : "icons x"}>
        <Icon size="small" color="blue" name="x"></Icon>
    </div>
}

export const MoreIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    color?: string;
    onClick?: MouseEventHandler
}) => {
    return <div style={{color: props.color}} onClick={props.onClick} className={props.className ? `icons ellipisis ${props.className}` : "icons ellipisis"}>
        <Icon name="ellipsis vertical"></Icon>
    </div>
}

export const MenuIcon = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    color?: string;
    onClick?: MouseEventHandler
    size?: string
}) => {
    return <div style={{color: props.color}} onClick={props.onClick} className={props.className ? `menu-icon ${props.className}` : "menu-icon"}>
        <Icon name="list" size={props.size === "small" ? "small" : "large"}></Icon>
    </div>
}
