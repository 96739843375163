import { getCardActionAreaUtilityClass } from "@mui/material"
import { useEffect, useState } from "react"
import { AddNewButton, AddtoIcon, Arrow, DataGrid, DataTile, DataTileHeader, DataTileItem, DataTilesContainer, HeaderItem, InputTextField, MoreIcon, RightArrowIcon, Row, RowHeader, RowItem, RowsContainer, Section, XIcon } from "../../components"
import { Button } from "../../components/Buttons"
import { DropdownField } from "../../components/Dropdown/DropdownField"
import { FormField } from "../../components/Form/Fields"
import { actions } from "../../store"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { ProductionState, PlanStep } from "../../store/reducers/productionReducer"
import { ProductionFilter, PurchasesFilter } from "../../utils/enums"
import "./Process.css";

export const Step = (props: {
    key?: string;
    steps: PlanStep[];
    index: number
}) => {
    const state = useAppSelector((state): ProductionState => state.production);
    const [expandStep, setExpandStep] = useState(false);
    const getInputs = () => {
        if (!props.steps[props.index]) {
            return [];
        }
        const parts = props.steps[props.index].inputs.parts.map((part) => {
            return <Row>
                <RowItem value={part.partName}/>
                <RowItem value={`${part.quantity}`}/>
            </Row>
        })

        const assemblies = props.steps[props.index].inputs.subAssemblies.map((assembly) => {
            return <Row>
                <RowItem value={assembly.assemblyName}/>
                <RowItem value={`${assembly.quantity}`}/>
            </Row>
        })

        return parts.concat(assemblies);
    }

    const getOutputs = () => {
        if (!props.steps[props.index]) {
            return [];
        }
        const parts = props.steps[props.index].outputs.parts.map((part) => {
            return <Row>
                <RowItem value={part.partName}/>
                <RowItem value={`${part.quantity}`}/>
            </Row>
        })

        const assemblies = props.steps[props.index].outputs.subAssemblies.map((assembly) => {
            return <Row>
                <RowItem value={assembly.assemblyName}/>
                <RowItem value={`${assembly.quantity}`}/>
            </Row>
        })

        return parts.concat(assemblies);
    }

    let dataTile = <DataTile className={expandStep ? "expand-step": undefined}>
        <DataTileHeader><span>{props.steps[props.index].name || ""}</span>
            <div className="step-no-badge"><span>{props.index+1}</span></div>
            {!expandStep ? <MoreIcon onClick={() => {setExpandStep(true)}} className="expand-icon" color="white"></MoreIcon>:
            <XIcon onClick={() => {setExpandStep(false)}} className="expand-icon"></XIcon>}
        </DataTileHeader>
        <DataTilesContainer vertical={true}>
            <DataTileItem vertical={true}>
                <DataGrid>
                    <RowHeader width="100%" height="25%"><HeaderItem value="Input"></HeaderItem></RowHeader>
                    <RowsContainer className="summary-data">
                        {getInputs()}
                    </RowsContainer>
                </DataGrid>
            </DataTileItem>
            <DataTileItem vertical={true}>
                <DataGrid>
                    <RowHeader width="100%" height="25%"><HeaderItem value="Output"></HeaderItem></RowHeader>
                    <RowsContainer className="summary-data">
                        {getOutputs()}
                    </RowsContainer>
                </DataGrid>
            </DataTileItem>
        </DataTilesContainer>
    </DataTile>

    return !expandStep ? dataTile : <div className="expand-step-cover">{dataTile}</div>
}

export const NewStep = (props: {
    index: number,
}) => {
    const state = useAppSelector((state): ProductionState => state.production)
    const dispatch = useAppDispatch();
    const { production } = actions;
    const [focusData, setFocusData] = useState("inputs");
    const input = state.newPlan.steps[props.index]?.inputs || {parts: [], subAssemblies: []};
    const output = state.newPlan.steps[props.index]?.outputs || {parts: [], subAssemblies: []};
    const [inputs, setInputs] = useState({...input})
    const [outputs, setOutputs] = useState({...output})

    useEffect(() => {
        if (!state.newPlan.steps[props.index]) {
            dispatch(production.setNewStep({index: props.index}))
            setInputs({parts: [], subAssemblies: []})
            setOutputs({parts: [], subAssemblies: []})
            setFocusData("inputs");
        } else {
            setInputs(JSON.parse(JSON.stringify(state.newPlan.steps[props.index].inputs)))
            setOutputs(JSON.parse(JSON.stringify(state.newPlan.steps[props.index].outputs)))
        }
    }, [props.index, state.newPlan.steps.length])

    const handleElementsSelection = (data: any) => {
        if (data.name === ProductionFilter.ASSEMBLY_NAME) {
            const quantitiesMap: any = {};
            const values = JSON.parse(JSON.stringify(focusData === "inputs" ? inputs : outputs));
            values.subAssemblies.forEach((val: any) => {
                quantitiesMap[val.assemblyName] = val.quantity
            })
            values.subAssemblies = data.values.map((val: any) => {
                return {
                    assemblyName: val.value, quantity: quantitiesMap[val.value] || 1,
                    assemblyId: val.key
            }})
            focusData === "inputs" ? setInputs(values) : setOutputs(values)
        } else {
            const quantitiesMap: any = {};
            const values = JSON.parse(JSON.stringify(focusData === "inputs" ? inputs : outputs));
            values.parts.forEach((val: any) => {
                quantitiesMap[val.partName] = val.quantity
            })
            values.parts = data.values.map((val: any) => {
                return {
                    partName: val.value, quantity: quantitiesMap[val.value] || 1,
                    partId: val.key
            }})
            focusData === "inputs" ? setInputs(values) : setOutputs(values)
        }
        
        dispatch(production.setStepData({step: {
            ...state.newPlan.steps[props.index],
            inputs, outputs
        }, index: props.index}))
    }

    const handleQuantityChange = (e: any, dataType: string, partType: string, index: number) => {
        const values = JSON.parse(JSON.stringify(focusData === "inputs" ? inputs : outputs));
        values[partType][index].quantity = parseInt(e.target.value) || 0;
        focusData === "inputs" ? setInputs(values) : setOutputs(values)
        dispatch(production.setStepData({step: {
            ...state.newPlan.steps[props.index],
            inputs, outputs
        }, index: props.index}))
    }

    const getInputs = () => {
        const parts = inputs.parts.map((part: any, index: number) => {
            return <Row>
                <RowItem width={10} value={`${index+1}.`}></RowItem>
                <RowItem width={50} value={part.partName}></RowItem>
                <RowItem><input value={part.quantity} onChange={(e) => {handleQuantityChange(e, "inputs", "parts", index)}}></input></RowItem>
            </Row>
        })

        const assemblies = inputs.subAssemblies.map((assembly: any, index: number) => {
            return <Row>
                <RowItem width={10} value={`${inputs.parts.length + index+1}.`}></RowItem>
                <RowItem width={50} value={assembly.assemblyName}></RowItem>
                <RowItem><input value={assembly.quantity} onChange={(e) => {handleQuantityChange(e, "inputs", "subAssemblies", index)}}></input></RowItem>
            </Row>
        })

        return parts.concat(assemblies);
    }

    const getOutputs = () => {
        const parts = outputs.parts.map((part: any, index: number) => {
            return <Row>
                <RowItem width={10} value={`${index+1}.`}></RowItem>
                <RowItem width={50} value={part.partName}></RowItem>
                <RowItem><input onChange={(e) => {handleQuantityChange(e, "outputs", "parts", index)}}></input></RowItem>
            </Row>
        })

        const assemblies = outputs.subAssemblies.map((assembly: any,  index: number) => {
            return <Row>
                <RowItem width={10} value={`${outputs.parts.length + index+1}.`}></RowItem>
                <RowItem width={50} value={assembly.assemblyName}></RowItem>
                <RowItem><input onChange={(e) => {handleQuantityChange(e, "outputs", "subAssemblies", index)}}></input></RowItem>
            </Row>
        })

        return parts.concat(assemblies);
    }
 
    const getSelectedParts = () => {
        const values = focusData === "inputs" ? inputs : outputs;
        return values.parts.map((val: any) => {
            return {value: val.partName, key: val.partId, text: val.partName}
        })
    }

    const getSelectedAsms = () => {
        const values = focusData === "inputs" ? inputs : outputs;
        return values.subAssemblies.map((val: any) => {
            return {value: val.assemblyName, key: val.assemblyId, text: val.assemblyName}
        })
    }

    return <Section width="100%" height="100%" className="data-container">
        <div className="meta-data">
            <div className="input-output">
                <Button
                    onClick={() => setFocusData("inputs")}
                    className={focusData === "inputs" ? "select-button selected" : "select-button"} label="Input"></Button>
                <Button
                    onClick={() => setFocusData("outputs")}
                    className={focusData === "outputs" ? "select-button selected" : "select-button"} label="Output"></Button>
            </div>
            <div className="fields">
                <div className="plan-field">
                    <div className="label"><span>Parts</span></div>
                    <FormField>
                        <DropdownField
                            multiselect={true}
                            name={PurchasesFilter.PART_NAME}
                            onSelection={(e: any, data: any) => handleElementsSelection(data)}
                            selections={getSelectedParts()}
                            key={"assemblies-production"}
                        ></DropdownField>
                    </FormField>
                </div>
                <div className="plan-field">
                    <div className="label"><span>Sub-Assemblies</span></div>
                    <FormField>
                        <DropdownField
                            multiselect={true}
                            name={ProductionFilter.ASSEMBLY_NAME}
                            onSelection={(e: any, data: any) => handleElementsSelection(data)}
                            selections={getSelectedAsms()}
                            key={"parts-production"}
                        ></DropdownField>
                    </FormField>
                </div>
            </div>
        </div>
        <div className="plan-form">
            <div className={focusData === "inputs" ? "data active": "data"}>
                <DataGrid height="100%">
                    <RowHeader headings={["Input"]}></RowHeader>
                    <RowsContainer>
                        {getInputs()}
                    </RowsContainer>
                </DataGrid>
            </div>
            <div className={focusData === "outputs" ? "data active": "data"}>
                <DataGrid>
                    <RowHeader headings={["Output"]}></RowHeader>
                    <RowsContainer>
                        {getOutputs()}
                    </RowsContainer>
                </DataGrid>
            </div>
        </div>
    </Section>
}

export const PlanSummary = () => {
    const state = useAppSelector((state): ProductionState => state.production)    
    const getSteps = () => {
        const stepRows = [];
        const rowLength = 3;
        let direction = "right";
        let k = 0;
        for (let i = 0; i < state.newPlan.steps.length; i = i+rowLength) {
            let steps = [];
            for (let j = i; (j < i+rowLength) && (j< state.newPlan.steps.length); j++) {
                steps.push(<Step key={`step-${i}`} steps={state.newPlan.steps}
                 index={j}></Step>);
                if (j%rowLength !== (rowLength-1) && j < state.newPlan.steps.length-1) {
                    steps.push(<Arrow direction={direction}/>);
                } 
            }
            stepRows.push(<div className={k%2 === 0 ? "step-row": "step-row reverse"}>{steps}</div>)
            direction = direction === "right" ? "left" : "right";

            if (i + rowLength < state.newPlan.steps.length) {
                stepRows.push(<div className={k % 2 === 0? "step-row left": "step-row"}><Arrow direction="down"></Arrow></div>)
                k++;
            }
        }
        return stepRows;
    }
    return <div className="steps-container">{getSteps()}</div>
}

export const Plan = () => {
    const state = useAppSelector((state): ProductionState => state.production)    
    const getSteps = () => {
        const stepRows = [];
        const rowLength = 3;
        let direction = "right";
        let k = 0;
        for (let i = 0; i < state.plan.steps.length; i = i+rowLength) {
            let steps = [];
            for (let j = i; (j < i+rowLength) && (j< state.plan.steps.length); j++) {
                steps.push(<Step steps={state.plan.steps} key={`step-${i}`} index={j}></Step>);
                if (j%rowLength !== (rowLength-1) && j < state.plan.steps.length-1) {
                    steps.push(<Arrow direction={direction}/>);
                } 
            }
            stepRows.push(<div className={k%2 === 0 ? "step-row": "step-row reverse"}>{steps}</div>)
            direction = direction === "right" ? "left" : "right";

            if (i + rowLength < state.plan.steps.length) {
                stepRows.push(<div className={k % 2 === 0? "step-row left": "step-row"}><Arrow direction="down"></Arrow></div>)
                k++;
            }
        }
        return stepRows;
    }
    return <div className="steps-container">{getSteps()}</div>
}
