import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Form, Label, Input, Grid, Image } from "semantic-ui-react";
import { authAPIs } from "../../apis";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { validateEmail } from "../../utils/commonUtils";
import { WrapperModal } from "../WrapperModal/WrapperModal";
import logo from "../../assets/logos/OptimumPLogo.png"
import "./Login.css";

export const Login = () => {
    const [apiError, setApiError] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const { auth, common } = actions;
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(common.setSelectedTab("login"));
    })

    const [details, setDetails] = useState({
        email: "",
        password: "",
    });

    const [validation, setValidation] = useState({
        email: null,
        password: null,
    });


    const handleSubmit = async () => {
        const validationObj = JSON.parse(JSON.stringify(validation));
        if (!validateEmail(details.email)) {
            validationObj.email = 'Invalid or empty email';
        } else {
            validationObj.email = null;
        }

        if (!details.password || details.password.length < 4) {
            validationObj.password = 'Invalid or empty password.';
        } else {
            validationObj.password = null;
        }

        if (validationObj.email !== null || validationObj.password !== null) {
            setValidation(validationObj);
            return;
        }

        const data = await authAPIs.login(details);
        if (data.err) {
            setApiError(data.err);
        } else {
            setApiError(null);
            dispatch(auth.setAccessData({ accessToken: data.accessToken, userId: data.userId }));
            dispatch(common.setSelectedTab("dashboard"));
            setRedirect(true);
        }
    }

    const handleLoginInput = (e: any) => {
        const { name, value } = e.target;
        setDetails({
            ...details,
            [name]: value
        })
        setValidation({ email: null, password: null });
    }

    return <div className="landing">
        <Grid>
            <Grid.Column>
                <Image src={logo} centered />
                {redirect ? <Redirect to={`/dashboard`} /> : null}
                <Form>
                    <h1 className="title">Inventory Management</h1>
                    <Form.Field>
                        <label htmlFor="email"></label>
                        <Input
                            className={validation.email ? "field-error" : undefined}
                            icon="mail"
                            iconPosition="left"
                            error={validation.email ? true : false}
                            onChange={handleLoginInput}
                            type="email" id="email" name="email"
                            placeholder={validation.email ? validation.email : "Email Address"} />
                        {validation.email ? <Label className="field-error"
                            pointing="right" color="red">{validation.email}</Label> : null}
                    </Form.Field>
                    <Form.Field>
                        <label htmlFor="password"></label>
                        <Input
                            className={validation.email ? "field-error" : undefined}
                            icon="lock"
                            iconPosition="left"
                            error={validation.password ? true : false}
                            onChange={handleLoginInput}
                            type="password" id="password" name="password"
                            placeholder={validation.password ? validation.password : "Password"} />
                        {validation.password ? <Label className="field-error"
                            pointing="right" color="red">{validation.password}</Label> : null}
                    </Form.Field>
                    <WrapperModal
                        type="Error"
                        trigger={<button className="button" onClick={handleSubmit} type="submit">Login</button>}
                        closingFn={() => setApiError(null)}
                        isOpen={apiError !== null} msg={apiError}></WrapperModal>
                    <a className="message" href="/">Forgot Password?</a>
                </Form>
            </Grid.Column>
        </Grid>
    </div>
}