// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background-color: var(--white);
    -webkit-box-shadow: 0 0px 5px #555;
    -moz-box-shadow: 0 0px 5px #555;
    box-shadow: 0 0px 5px #555;
    width: 100%;
    position: fixed;
}

.header::before {
    content: "";
    position: absolute;
    top: 0;
    left: -5px;
    height: 100%;
    width: 5px;
    background-color: var(--white);
  }
.header-bar {
    background-color: #fff!important;
    margin: 0%!important;
}
.header-bar.ui.menu{
    height: 54px !important;
    border:0;
    box-shadow: none;
    border-radius: 0;
}
.header-bar .active.item {
    background: rgba(0,0,0,0.35)!important;
}
.header-bar .right{
    padding-right: 255px;
    align-items: center !important;
}

.notification-icon{
    margin-right: 35px;
}
.user-dropdown{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderBar/HeaderBar.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kCAAkC;IAClC,+BAA+B;IAC/B,0BAA0B;IAC1B,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,YAAY;IACZ,UAAU;IACV,8BAA8B;EAChC;AACF;IACI,gCAAgC;IAChC,oBAAoB;AACxB;AACA;IACI,uBAAuB;IACvB,QAAQ;IACR,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,sCAAsC;AAC1C;AACA;IACI,oBAAoB;IACpB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".header {\n    background-color: var(--white);\n    -webkit-box-shadow: 0 0px 5px #555;\n    -moz-box-shadow: 0 0px 5px #555;\n    box-shadow: 0 0px 5px #555;\n    width: 100%;\n    position: fixed;\n}\n\n.header::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: -5px;\n    height: 100%;\n    width: 5px;\n    background-color: var(--white);\n  }\n.header-bar {\n    background-color: #fff!important;\n    margin: 0%!important;\n}\n.header-bar.ui.menu{\n    height: 54px !important;\n    border:0;\n    box-shadow: none;\n    border-radius: 0;\n}\n.header-bar .active.item {\n    background: rgba(0,0,0,0.35)!important;\n}\n.header-bar .right{\n    padding-right: 255px;\n    align-items: center !important;\n}\n\n.notification-icon{\n    margin-right: 35px;\n}\n.user-dropdown{\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
