import { useEffect, useState } from "react";
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { stockAPIs } from "../../apis";
import { StockState } from "../../store/reducers/stockReducer";
import { NavBar } from "../../components/NavBar/NavBar";
import { FilterGridType, StockFilter } from "../../utils/enums";
import { CustomRangeSlider } from "../../components/CustomRangeSlider/CustomRangeSlider";
import { Searchbox } from "../../components/Searchbox/Searchbox";
import { StockDataGrid } from "./StockData";
import { CommandBar, Container, MainSection } from "../../components";


export const Stock = () => {
    const state = useAppSelector((state: any): StockState => state.stocks);
    const { stocks } = actions;
    const dispatch = useAppDispatch();
    const {common} = actions;

    const stockFilters = [
        StockFilter.ASSEMBLY_NAME
    ]

    const getFilterValue = (name: StockFilter): any[] => {
        const index = state.filterValues.findIndex((filter: any) => filter.name === name);
        if (index > -1) {
            return state.filterValues[index].value;
        } else {
            return [];
        }
    }

    useEffect(() => {
        stockAPIs.getStockData({
            pageNumber: state.pagination.pageNumber,
            limit: state.pagination.limit,
            searchText: state.searchText,
            partName: getFilterValue(StockFilter.ASSEMBLY_NAME).map(item => item.value),
            qlStart: getFilterValue(StockFilter.LEFT)[0],
            qlEnd: getFilterValue(StockFilter.LEFT)[1],
            qpStart: getFilterValue(StockFilter.PURCHASED)[0],
            qpEnd: getFilterValue(StockFilter.PURCHASED)[1],
            quStart: getFilterValue(StockFilter.USED)[0],
            quEnd: getFilterValue(StockFilter.USED)[1],
            qwStart: getFilterValue(StockFilter.WASTED)[0],
            qwEnd: getFilterValue(StockFilter.WASTED)[1],
        }).then((stockData: any) => {
            dispatch(stocks.setStock(stockData.results));
            dispatch(stocks.setPagination({
                pageNumber: stockData.pageNumber,
                limit: stockData.limit,
                totalPages: stockData.totalPages,
                totalResults: stockData.totalResults,
                currentResults: stockData.currentResults
            }))
        }).catch((error: any) => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in fetching components data"})
        })
    }, [state.searchText, state.pagination, JSON.stringify(state.filterValues)])

    const setPagination = (pageNumber: number) => {
        dispatch(stocks.setPageNumber(pageNumber));
    }

    const handleSearch = (e: any) => {
        dispatch(stocks.setSearchText(e.target.value));
    }

    const handleRangeFilter = (data: any) => {
        switch (data.name) {
            case "Quantity Used":
                dispatch(stocks.setFilters({ name: StockFilter.USED, value: data.values }))
                break;
            case "Quantity Left":
                dispatch(stocks.setFilters({ name: StockFilter.LEFT, value: data.values }))
                break;
            case "Quantity Purchased":
                dispatch(stocks.setFilters({ name: StockFilter.PURCHASED, value: data.values }))
                break;
            case "Quantity Wasted":
                dispatch(stocks.setFilters({ name: StockFilter.WASTED, value: data.values }))
                break;
        }
    }

    const getFilters = () => {
        return [<Searchbox
            displayLabel
            key="searchbox"
            onChange={handleSearch}
        />,
        <CustomRangeSlider
            label="Quantity Left"
            name="Quantity Left"
            minValue={0}
            maxValue={1000}
            setValuesFn={handleRangeFilter}
            key="quantity-left-slider"
            id="quantity-left-slider"
        />,
        <CustomRangeSlider
            label="Quantity Purchased"
            name="Quantity Purchased"
            minValue={0}
            maxValue={1000}
            setValuesFn={handleRangeFilter}
            key="quantity-purchased-slider"
            id="quantity-purchased-slider"
        />,
        <CustomRangeSlider
            label="Quantity Used"
            name="Quantity Used"
            minValue={0}
            maxValue={1000}
            setValuesFn={handleRangeFilter}
            key="quantity-used-slider"
            id="quantity-used-slider"
        />,
        <CustomRangeSlider
            label="Quantity Wasted"
            name="Quantity Wasted"
            minValue={0}
            maxValue={1000}
            setValuesFn={handleRangeFilter}
            key="quantity-wasted-slider"
            id="quantity-wasted-slider"
        />]
    }

    return <Container>
        <NavBar
            sectionName="Stock"
            name="stocks"
        />
        <MainSection>
            <CommandBar
                searchFn={handleSearch}
                setDropdownFn={stocks.setFilters}
                gridType={FilterGridType.INVENTORY}
                dropdowns={stockFilters}
                dropdownValues={state.filterValues}
                actions={[]}
            >
            </CommandBar>
            <StockDataGrid></StockDataGrid>
        </MainSection>
    </Container>
}