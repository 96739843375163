import { MouseEventHandler } from "react"
import { Icon } from "semantic-ui-react"
import SaveAltOutlined from "@mui/icons-material/SaveAltOutlined";

export const Button = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler;
    label: string;
}) => {
    return <button onClick={props.onClick} className={props.className ? `buttons ${props.className}` : "buttons"}>
        <span>{props.label}</span>
    </button>
}


export const AddNewButton = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler;
    label: string;
}) => {
    return <button onClick={props.onClick} className={props.className ? `buttons add ${props.className}` : "buttons add"}>
        <Icon name="plus"></Icon>
        <span>{props.label}</span>
    </button>
}

export const InputButton = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler;
    label: string;
}) => {
    return <button onClick={props.onClick} className={props.className ? `buttons input ${props.className}` : "buttons input"}>
        <Icon color="green" name="plus circle"></Icon>
        <span>{props.label}</span>
    </button>
}

export const UpdateButton = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler;
    label: string;
}) => {
    return <button onClick={props.onClick} className={props.className ? `buttons add ${props.className}` : "buttons add"}>
        <Icon name="pencil"></Icon>
        <span>{props.label}</span>
    </button>
}

export const ChangeButton = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler;
    label: string;
}) => {
    return <button onClick={props.onClick} className={props.className ? `buttons change ${props.className}` : "buttons change"}>
        <span>{props.label}</span>
    </button>
}

export const SaveButton = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    onClick: MouseEventHandler;
    label: string;
}) => {
    return <button onClick={props.onClick} className={props.className ? `buttons save ${props.className}` : "buttons save"}>
    <span>{props.label}</span>
    <SaveAltOutlined></SaveAltOutlined>
</button>
}