import moment from "moment";
export const daysArr: string[] = ["MON", "TUE", "WED", "THUR", "FRI", "SAT", "SUN"];
export const daysMap: any = {
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
    "Sunday": 7
}
export const monthsArr: string[] = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const daysInMonth: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const getHoursAndMin = (hours: number) => {
    let hour = Math.floor(hours);
    let min = (hours-hour)*60;
    let hrStr = hour >= 10? `${hour}` : `0${hour}`;
    let minStr = min >= 10? `${min}` : `0${min}`;
    return `${hrStr}:${minStr}`;
}

export const getCurrentWeekStr = (): string => {
    const weekNumber = getCurrentWeek();
    const currentdate = new Date();
    const startDate: Date = new Date();
    const endDate: Date = new Date();
    const day = currentdate.getDay();
    const date = currentdate.getDate();
    if (day === 1) {
        endDate.setDate(endDate.getDate()+6);
    } else {
        startDate.setDate(date-day+1);
        endDate.setDate(date+7-day);
    }
    let startStr: string = moment(startDate).format('D/M/YYYY');
    let endStr: string = moment(endDate).format('D/M/YYYY');
    return `Week-${weekNumber}: ${startStr} - ${endStr}`;
}

//gets current week number in the current month
export const getCurrentWeek = (): number => {
    const currentdate: Date = new Date();
    const monthStart: Date = new Date(Date.UTC(currentdate.getFullYear(), currentdate.getMonth(), 1));
    let day: number = monthStart.getUTCDay();
    day = day === 0 ? 7 : day;
    const noOfDaysInPrevMonth: number = (8 - day) === 7 ? 0 : (8 - day);
    const weekNumber = Math.ceil((currentdate.getDate()-noOfDaysInPrevMonth)/7);
    return weekNumber;
}

export const getCurrentMonth = (): number => {
    const currentdate: Date = new Date();
    return currentdate.getMonth()+1;
}

export const getMonths = (): any => {
    let months = [];
    for (let i = 0; i < 12; i++) {
        months.push({
            key: `month-${i+1}-${monthsArr[i]}`,
            text: `${monthsArr[i]}`,
            value: i+1
        })
    }
    return months;
}

/**
 * Gets week numbers in the given month, considering first Mon as start of the first week.
 * Last week of the month (4th or 5th) may spill over to the next month, but considered
 * part of 5th week of this month
 */
export const getWeekNumbers = (month: number): any => {
    const currentdate: Date = new Date();
    const currentYear: number = currentdate.getFullYear();
    const monthStart: Date = new Date(Date.UTC(currentdate.getFullYear(), month - 1, 1));
    let day: number = monthStart.getUTCDay();
    day = day === 0 ? 7 : day;
    const noOfDaysInMonth: number = daysInMonth[month - 1];
    const noOfDaysInPrevMonth: number = (8 - day) === 7 ? 0 : (8 - day);
    const noOfWeeks: number = Math.ceil((noOfDaysInMonth - noOfDaysInPrevMonth) / 7);
    const weekNumbers: any[] = [];

    for (let i = 0; i < noOfWeeks; i++) {
        let start: number = noOfDaysInPrevMonth + i * 7 + 1;
        let end: number = start + 6;
        let startStr: string = `${start}/${month}/${currentYear}`;
        let endStr: string = `${end}/${month}/${currentYear}`;
        if (end > noOfDaysInMonth) {
            end = end - noOfDaysInMonth;
            endStr = `${end}/${month + 1}/${currentYear}`;
        }
        weekNumbers.push({
            key: i,
            text: `Week-${i + 1}: ${startStr} - ${endStr}`,
            value: i + 1
        })
    }
    return weekNumbers;
}

export const getDateString = (dateStr: string): string => {
    let date = new Date(dateStr);
    let year = date.getFullYear();
    let month: any = date.getMonth()+1;
    month = month <= 9 ? `0${month}`: month;
    let day: any = date.getDate();
    day = day <= 9 ? `0${day}`: day;
    return `${year}-${month}-${day}`;
}