import { useEffect, useState } from "react"
import { Row, RowHeader, RowsContainer, DataGrid } from "../../components";
import { RowItem, HeaderItem } from "../../components/RowsContainer";
import { useAppSelector } from "../../store/hooks";
import { InventoryState } from "../../store/reducers/inventoryReducer";

export const InventoryItem = (props: {index: number}) => {
    const state = useAppSelector((state: any): InventoryState => state.inventory);
    const initialObj = {...state.inventory[props.index]};
    const [invObj, setInvObj] = useState(initialObj);

    useEffect(() => {
        const initialObj = {...state.inventory[props.index]};
        setInvObj(initialObj);
    }, [props.index, state.inventory[props.index]])

    return <Row color={props.index%2 === 0 ? false : true} key={invObj.name}>
        <RowItem width={20} value={invObj.partName}></RowItem>
        <RowItem width={20} value={`${invObj.quantityLeft} ${invObj.quantityUnit}`}></RowItem>
        <RowItem width={20} value={`${invObj.quantityPurchased} ${invObj.quantityUnit}`}></RowItem>
        <RowItem width={20} value={`${invObj.quantityUsed} ${invObj.quantityUnit}`}></RowItem>
        <RowItem width={20} value={`${invObj.quantityWasted} ${invObj.quantityUnit}`}></RowItem>
    </Row>
}

export const InventoryHeader = () => {
    return <RowHeader>
        <HeaderItem width={20} value="Part Name"></HeaderItem>
        <HeaderItem width={20} value="Q Left"></HeaderItem>
        <HeaderItem width={20} value="Q Purchased"></HeaderItem>
        <HeaderItem width={20} value="Q Used"></HeaderItem>
        <HeaderItem width={20} value="Q Wasted"></HeaderItem>
    </RowHeader>
}

export const InventoryDataGrid = () => {
    const state = useAppSelector((state: any): InventoryState => state.inventory);
    const getData = () => {
        return <RowsContainer>
            {state.inventory.map((comp, ind) => {
                return <InventoryItem index={ind}></InventoryItem>
            })}
        </RowsContainer>
    }

    return <DataGrid>
        <InventoryHeader></InventoryHeader>
        {getData()}
    </DataGrid>
}