import { useEffect, useState } from "react"
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CommonState } from "../../store/reducers/commonReducer";
import "./Alert.css"

export interface AlertProps {
    msg?: string;
    isError?: boolean;
}

export const Alert = (props: AlertProps) => {
    const state = useAppSelector((state: any): CommonState => state.common);
    const {common} = actions;
    const dispatch = useAppDispatch();

    const [hide, setHide] = useState(true);

    useEffect(() => {
        if (state.flyMsg.msg !== "") {
            setHide(false);
            const timer = setTimeout(() => {
                setHide(true)
                const timer = setTimeout(() => {
                    dispatch(common.setFlyMsg({msg: ""}))
                }, 2000);
                  return () => clearTimeout(timer);        
            }, 2000);
              return () => clearTimeout(timer);
        }
    }, [state.flyMsg.isError, state.flyMsg.msg])

    return <div id="base-alert" className={hide ? "custom-alert hide": state.flyMsg.isError ? "custom-alert error" : "custom-alert"}>
        <div className="container"><span>{state.flyMsg.msg}</span></div>
    </div>
}