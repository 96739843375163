import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { ProductionFilter } from '../../utils/enums';
import { Step } from 'semantic-ui-react';

export interface Filters {
    type: string[],
    searchText: string,
}


export interface PlanStep {
    name: string;
    level: number;
    inputs: {
        parts: { partName: string; partId: string; quantity: number}[],
        subAssemblies: {assemblyName: string; assemblyId: string; quantity: number}[]
    },
    outputs: {
        parts: { partName: string; partId: string; quantity: number}[],
        subAssemblies: {assemblyName: string; assemblyId: string; quantity: number}[]
    },
    details: string;
}

export interface ProductionState {
    plan: {
        mainAssemblyName: string;
        planName: string;
        mainAssemblyId: string;
        steps: PlanStep[];
        _id: string;
    };
    selectedIndex: number;
    totalPages: number;
    pageNumber: number;
    limit: number;
    totalResults: number;
    currentResults: number;
    filterValues: {name: ProductionFilter, value: any}[],
    searchText: string;
    componentsSearchText: string;
    subAsmSearchText: string;
    showForms: {add: boolean, delete: boolean, edit: boolean, updateParts: boolean, updateSubAsm: boolean};
    editIndex: number;
    deleteIndex: number;
    newPlan: {
        mainAssemblyName: string;
        planName: string;
        mainAssemblyId: string;
        steps: PlanStep[]
    }
};

const initialState: ProductionState = {
    plan: {
        mainAssemblyName: "",
        planName: "",
        mainAssemblyId: "",
        steps: [],
        _id: ""
    },
    selectedIndex: 0,
    totalPages: 1,
    pageNumber: 1,
    limit: 20,
    totalResults: 0,
    currentResults: 0,
    filterValues: [],
    searchText: "",
    componentsSearchText: "",
    subAsmSearchText: "",
    showForms: {add: false, delete: false, edit: false, updateParts: false, updateSubAsm: false},
    editIndex: 0,
    deleteIndex: 0,
    newPlan: {
        mainAssemblyName: "",
        planName: "",
        mainAssemblyId: "",
        steps: []
    }
}

const productionSlice: Slice = createSlice({
    name: 'production',
    initialState: initialState,
    reducers: {
        setPlan: (state: ProductionState, action: PayloadAction<any>) => {
            state.plan = action.payload;
        },
        setNewPlanData: (state: ProductionState, action: PayloadAction<{mainAssemblyName: string; planName: string; mainAssemblyId: string;}>) => {
            state.newPlan ={ ...state.newPlan, ...action.payload}
        },
        setNewStep: (state: ProductionState, action: PayloadAction<{ index: number}>) => {
            state.newPlan.steps[action.payload.index] = { 
                name: "",
                level: action.payload.index+1,
                inputs: {
                    parts: [],
                    subAssemblies: []
                },
                outputs: {
                    parts: [],
                    subAssemblies: []
                },
                details: "",            
            }
        },
        resetNewPlan: (state: ProductionState, action: PayloadAction<null>) => {
            state.newPlan ={
                mainAssemblyName: "",
                planName: "",
                mainAssemblyId: "",
                steps: []        
            }
        },
        setStepMetaData: (state: ProductionState, action: PayloadAction<{name: string, details: string, index: number}>) => {
            state.newPlan.steps[action.payload.index] = {
                ...state.newPlan.steps[action.payload.index],
                name: action.payload.name,
                details: action.payload.details
            }
        },
        setStepData: (state: ProductionState, action: PayloadAction<{ step: PlanStep, index: number}>) => {
            state.newPlan.steps[action.payload.index].inputs = {
                ...action.payload.step.inputs
            }
            state.newPlan.steps[action.payload.index].outputs = {
                ...action.payload.step.outputs
            }
        },
        setPlanStep: (state: ProductionState, action: PayloadAction<{step: any, index: number}>) => {
            state.newPlan.steps[action.payload.index] = { ...action.payload.step}
        },
        setSelectedIndex: (state: ProductionState, action: PayloadAction<number>) => {
            state.selectedIndex = action.payload;
        },
        setPageNumber: (state: ProductionState, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setPagination: (state: ProductionState, action: PayloadAction<{ 
            pageNumber: number; limit: number, totalResults: number, totalPages: number, currentResults: number
        }>) => {
            state.pageNumber = action.payload.pageNumber;
            state.limit = action.payload.limit
            state.currentResults = action.payload.currentResults;
            state.totalPages = action.payload.totalPages;
            state.totalResults = action.payload.totalResults;
        },
        setFilters: (state: ProductionState, action: PayloadAction<{name: ProductionFilter; value: any}>) => {
            let filterIndex = state.filterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.filterValues[filterIndex].value = action.payload.value;
            } else {
                state.filterValues.push({...action.payload})
            }
        },
        setSearchText: (state: ProductionState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        setComponentsSearchText: (state: ProductionState, action: PayloadAction<string>) => {
            state.componentsSearchText = action.payload;
        },
        setSubAsmSearchText: (state: ProductionState, action: PayloadAction<string>) => {
            state.subAsmSearchText = action.payload;
        },
        setAddForm: (state: ProductionState, action: PayloadAction<boolean>) => {
            state.showForms.add = action.payload;
        },
        setEditForm: (state: ProductionState, action: PayloadAction<boolean>) => {
            state.showForms.edit = action.payload;
        },
        setUpdateForm: (state: ProductionState, action: PayloadAction<{name: string, value: boolean}>) => {
            if (action.payload.name === "parts") {
                state.showForms.updateParts = action.payload.value;
            } else {
                state.showForms.updateSubAsm = action.payload.value;
            }
        },
        setDeleteForm: (state: ProductionState, action: PayloadAction<boolean>) => {
            state.showForms.delete = action.payload;
        },
        setEditIndex: (state: ProductionState, action: PayloadAction<number>) => {
            state.editIndex = action.payload;
        },
        setDeleteIndex: (state: ProductionState, action: PayloadAction<number>) => {
            state.deleteIndex = action.payload;
        }
    },
})

export const productionActions = {
    setPlan: productionSlice.actions.setPlan,
    setSelectedIndex: productionSlice.actions.setSelectedIndex,
    setPagination: productionSlice.actions.setPagination,
    setPageNumber: productionSlice.actions.setPageNumber,
    setFilters: productionSlice.actions.setFilters,
    setSearchText: productionSlice.actions.setSearchText,
    setComponentsSearchText: productionSlice.actions.setComponentsSearchText,
    setSubAsmSearchText: productionSlice.actions.setSubAsmSearchText,
    setAddForm: productionSlice.actions.setAddForm,
    setDeleteForm: productionSlice.actions.setDeleteForm,
    setEditForm: productionSlice.actions.setEditForm,
    setUpdateForm: productionSlice.actions.setUpdateForm,
    setEditIndex: productionSlice.actions.setEditIndex,
    setDeleteIndex: productionSlice.actions.setDeleteIndex,
    resetNewPlan: productionSlice.actions.resetNewPlan,
    setNewPlanData: productionSlice.actions.setNewPlanData,
    setPlanStep: productionSlice.actions.setPlanStep,
    setNewStep: productionSlice.actions.setNewStep,
    setStepData: productionSlice.actions.setStepData,
    setStepMetaData: productionSlice.actions.setStepMetaData,
}

export default productionSlice.reducer;