import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { PricesFilter } from '../../utils/enums';
import { Pagination } from './commonReducer';

export interface PricesState {
    prices: any[];
    pagination: Pagination;
    filterValues: {name: PricesFilter, value: any}[],
    searchText: string;
};

const initialState: PricesState = {
    prices: [],
    pagination: {
        totalPages: 1,
        pageNumber: 1,
        limit: 20,
        totalResults: 0,
        currentResults: 0,
    },
    filterValues: [],
    searchText: "",
}

const pricesSlice: Slice = createSlice({
    name: 'prices',
    initialState: initialState,
    reducers: {
        setPrices: (state: PricesState, action: PayloadAction<any[]>) => {
            state.prices = action.payload;
        },
        setPageNumber: (state: PricesState, action: PayloadAction<number>) => {
            state.pagination.pageNumber = action.payload
        },
        setPagination: (state: PricesState, action: PayloadAction<{ 
            pageNumber: number; limit: number, totalResults: number, totalPages: number, currentResults: number
        }>) => {
            state.pagination.pageNumber = action.payload.pageNumber;
            state.pagination.limit = action.payload.limit
            state.pagination.currentResults = action.payload.currentResults;
            state.pagination.totalPages = action.payload.totalPages;
            state.pagination.totalResults = action.payload.totalResults;
        },
        setFilters: (state: PricesState, action: PayloadAction<{name: PricesFilter; value: any}>) => {
            let filterIndex = state.filterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.filterValues[filterIndex].value = action.payload.value;
            } else {
                state.filterValues.push({...action.payload})
            }
        },
        setSearchText: (state: PricesState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
    },
})

export const pricesActions = {
    setPrices: pricesSlice.actions.setPrices,
    setPagination: pricesSlice.actions.setPagination,
    setPageNumber: pricesSlice.actions.setPageNumber,
    setFilters: pricesSlice.actions.setFilters,
    setSearchText: pricesSlice.actions.setSearchText,
}

export default pricesSlice.reducer;