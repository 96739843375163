// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-alert {
    position: absolute;
    top: 7%;
    right: 30%;
    margin-right: 2%;
    border-radius: 20px;
    width: 25%;
    height: 8%;
    background-color: rgba(44, 202, 255, 0.17);
    z-index: 10;
    text-align: center;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.custom-alert .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-alert.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}

.custom-alert.error {
    background-color: #ff000094;
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Alert/Alert.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,OAAO;IACP,UAAU;IACV,gBAAgB;IAChB,mBAAmB;IACnB,UAAU;IACV,UAAU;IACV,0CAA0C;IAC1C,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,+CAA+C;AACnD;;AAEA;IACI,2BAA2B;IAC3B,UAAU;AACd","sourcesContent":[".custom-alert {\n    position: absolute;\n    top: 7%;\n    right: 30%;\n    margin-right: 2%;\n    border-radius: 20px;\n    width: 25%;\n    height: 8%;\n    background-color: rgba(44, 202, 255, 0.17);\n    z-index: 10;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    opacity: 1;\n}\n\n.custom-alert .container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.custom-alert.hide {\n    visibility: hidden;\n    opacity: 0;\n    transition: visibility 0s 2s, opacity 2s linear;\n}\n\n.custom-alert.error {\n    background-color: #ff000094;\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
