import { useEffect, useState } from "react"
import { Row, RowHeader, RowsContainer, DataGrid } from "../../components";
import { RowItem, HeaderItem } from "../../components/RowsContainer";
import { useAppSelector } from "../../store/hooks";
import { StockState } from "../../store/reducers/stockReducer";

export const StockItem = (props: {index: number}) => {
    const state = useAppSelector((state: any): StockState => state.stocks);
    const initialObj = {...state.stock[props.index]};
    const [stockObj, setInvObj] = useState(initialObj);

    useEffect(() => {
        const initialObj = {...state.stock[props.index]};
        setInvObj(initialObj);
    }, [props.index, state.stock[props.index]])

    return <Row color={props.index%2 === 0 ? false : true} key={stockObj.name}>
        <RowItem width={20} value={stockObj.assemblyName}></RowItem>
        <RowItem width={20} value={`${stockObj.quantityLeft} ${stockObj.quantityUnit}`}></RowItem>
        <RowItem width={20} value={`${stockObj.quantityPurchased} ${stockObj.quantityUnit}`}></RowItem>
        <RowItem width={20} value={`${stockObj.quantityUsed} ${stockObj.quantityUnit}`}></RowItem>
        <RowItem width={20} value={`${stockObj.quantityWasted} ${stockObj.quantityUnit}`}></RowItem>
    </Row>
}

export const StockHeader = () => {
    return <RowHeader>
        <HeaderItem width={20} value="Assembly Name"></HeaderItem>
        <HeaderItem width={20} value="Q Left"></HeaderItem>
        <HeaderItem width={20} value="Q Purchased"></HeaderItem>
        <HeaderItem width={20} value="Q Used"></HeaderItem>
        <HeaderItem width={20} value="Q Wasted"></HeaderItem>
    </RowHeader>
}

export const StockDataGrid = () => {
    const state = useAppSelector((state: any): StockState => state.stocks);
    const getData = () => {
        return <RowsContainer>
            {state.stock.map((comp, ind) => {
                return <StockItem index={ind}></StockItem>
            })}
        </RowsContainer>
    }

    return <DataGrid>
        <StockHeader></StockHeader>
        {getData()}
    </DataGrid>
}