import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { Dropdown } from "semantic-ui-react"
import { assemblyAPIs, plansAPIs } from "../../apis"
import { AddNewButton, CommandBar, Container, DataGrid, EditIcon, HeaderItem, MainSection, NavBar, Row, RowHeader, RowItem, RowsContainer, Section, SplitSection } from "../../components"
import { DropdownFilter } from "../../components/Dropdown/DropdownFilter"
import { FormField, InputTextField } from "../../components/Form/Fields"
import { actions } from "../../store"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { PlanStep, ProductionState } from "../../store/reducers/productionReducer"
import { FilterGridType, ProductionFilter } from "../../utils/enums"
import { CreatePlan } from "./CreatePlan"
import { Plan, PlanSummary } from "./PlanStep"
import "./Process.css";

export const Processes = () => {
    const state = useAppSelector((state): ProductionState => state.production)
    const dispatch = useAppDispatch();
    const [assemblyId, setAssemblyId] = useState("");
    const [noData, setNoData] = useState(false)
    const history = useHistory();
    useEffect(() => {
        let assemblyName = state.filterValues[0]?.value[0]?.value || "";
        let emptyPlan = {
            mainAssemblyName: "",
            planName: "",
            mainAssemblyId: "",
            steps: [],
            _id: ""        
        }

        if (assemblyName) {
            plansAPIs.getAPlan({pageNumber: 1, limit: 1, searchText: state.searchText, assemblyName: [assemblyName]}).then((res: any) => {
                if (res.results.length === 0) {
                    setNoData(true);
                } else {
                    dispatch(production.setPlan(res.results[0] || emptyPlan));
                    setNoData(false);
                }
            })    
        } else {
            dispatch(production.setPlan(emptyPlan));
        }
    }, [state.filterValues])

    useEffect(() => {
        if (state.filterValues.length > 0 && state.filterValues[0].value.length > 0) {
            setAssemblyId(state.filterValues[0].value[0].key)
        }
    }, [state.filterValues[0]])
    const { production } = actions;
    const plan: PlanStep = {
        name: "PCBs Assembly",
        level: 1,
        details: "Setting up PCBs",
        inputs: {
            parts: [{partName: "Mosfet", partId: "p123", quantity: 10}, 
            {partName: "Resistors", partId: "p234", quantity: 20}],
            subAssemblies: [{assemblyName: "Mosfet", assemblyId: "asm123", quantity: 10}, {
                assemblyName: "Resistors", assemblyId: "asm234", quantity: 20}]
        },
        outputs: {
            parts: [],
            subAssemblies: [{assemblyName: "PCB", assemblyId: "id123", quantity: 10}]
        }
    }

    return <Container>
        <NavBar
            name="production"
            sectionName="Production"
        />
            <MainSection>
                <CommandBar
                    dropdowns={[ProductionFilter.ASSEMBLY_NAME]}
                    setDropdownFn={production.setFilters}
                    dropdownValues={state.filterValues}
                    gridType={FilterGridType.PRODUCTION}
                    noTags={true}
                    actions={[<AddNewButton
                        label={"New Plan"}
                        onClick={() => {history.push("production/plan")}}
                    />]}
                >
                </CommandBar>
                {noData ? <div className="empty-msg"><span>No Data Found</span></div> : state.plan.planName ? 
                <DataGrid width="100%" height="12%" className="plan-data">
                    <RowHeader height="40%">
                        <HeaderItem width={40} value="Plan Name"></HeaderItem>
                        <HeaderItem width={40} value="Main Assembly"></HeaderItem>
                        <HeaderItem width={20} value="Action"></HeaderItem>
                    </RowHeader>
                    <RowsContainer height="60%">
                        <Row>
                            <RowItem width={50} value={state.plan.planName}></RowItem>
                            <RowItem width={50} value={state.plan.mainAssemblyName}></RowItem>
                            <RowItem width={20}>
                                <EditIcon onClick={() => history.push("production/edit")}></EditIcon>
                            </RowItem>
                        </Row>
                    </RowsContainer>
                </DataGrid>:
                    <div className="empty-msg"><span>Select An Assembly</span></div>}
                <DataGrid height="68%">
                    <Plan></Plan>
                </DataGrid>
            </MainSection>
    </Container>
}