import { Switch, Route } from "react-router";
import { HeaderBar } from "../../components/HeaderBar/HeaderBar";
import { SideBar } from "../../components/SideBar/SideBar";
import { Assemblies } from "../Assemblies/Assemblies";
import { AssemblyPrices } from "../AssemblyPrices/AssemblyPrices";
import { EditAssemblyPrices } from "../AssemblyPrices/EditAssemblyPrices";
import { Components } from "../Components/Components";
import { Home } from "../Home/Home";
import { Inventory } from "../Inventory/Inventory";
import { Prices } from "../Prices/Prices";
import { CreatePlan } from "../Processes/CreatePlan";
import { EditPlan } from "../Processes/EditPlan";
import { Processes } from "../Processes/Processes";
import { AddPurchases } from "../Purchases/AddPurchases";
import { Purchases } from "../Purchases/Purchases";
import { Stock } from "../Stock/Stock";
import "./MainContainer.css";

export const MainContainer = () => {
    return <div className="main-container">
        <div className="side-bar">
            <SideBar></SideBar>
        </div>
        <div className="main-element">
            <div className="header"><HeaderBar></HeaderBar></div>
            <div className="components-container">
                <Switch>
                    <Route path="/dashboard" component={Home}/>
                    <Route path="/components" component={Components}/>        
                    <Route path="/assembly" component={Assemblies}/>
                    <Route path="/purchases" exact component={Purchases}/>
                    <Route path="/purchases/create" exact component={AddPurchases}/>
                    <Route path="/inventory" component={Inventory}/>
                    <Route path="/stocks" component={Stock}/>
                    <Route path="/prices/parts" component={Prices}/>
                    <Route path="/prices/edit" component={EditAssemblyPrices}/>
                    <Route path="/prices" component={AssemblyPrices}/>
                    <Route path="/production" exact component={Processes}/>
                    <Route path="/production/plan" component={CreatePlan}/>
                    <Route path="/production/edit" component={EditPlan}/>
                </Switch>
            </div>
        </div>
    </div>
}