import React from "react";
import { createRef, MouseEventHandler, useEffect, useState } from "react";
import "./CustomRangeSlider.css";

export interface SliderProps {
  minValue?: number;
  maxValue?: number;
  step?: number;
  label?: string;
  name: string;
  values?: number[];
  setValuesFn: Function;
  id: string;
}

export interface CustomRangeSliderState {
  clicks: {left: boolean; right: boolean},
  initialPos: {left: number; right: number},
  currPos: {left: number; right: number},
  barPos: {left: number; right: number},
  width: number,
  startValue: number,
  endValue: number,
  barClicked: boolean
}

const initialState: CustomRangeSliderState = {
  clicks: {left: false, right: false},
  initialPos: {left: 0, right: 0},
  currPos: {left: 0, right: 0},
  barPos: {left: 0, right: 0},
  width: 0,
  startValue: 0,
  endValue: 0,
  barClicked: false
}

export class CustomRangeSlider extends React.Component<SliderProps, CustomRangeSliderState> {
  constructor(props: SliderProps) {
    super(props);
    this.state  = {...initialState}
  }
  wrapperRef: any = createRef();
  inputRef: any = createRef();

  handleClickOutside = (e: any) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      if (this.state.clicks.left) {
        this.setState({
          clicks: {...this.state.clicks, left: false}
        })
      } else if (this.state.clicks.right) {
        this.setState({
          clicks: {...this.state.clicks, right: false}
        })
      }  
    }
  } 

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const leftSlider = document.getElementById(`${this.props.id}-left-slider`);
    const rightSlider = document.getElementById(`${this.props.id}-right-slider`);
    const bar = document.getElementById(`${this.props.id}-slider-bar`);
    let lPos = 0, rPos = 0, barL = 0, barR = 0;
    if (leftSlider) {
      lPos = leftSlider.getBoundingClientRect().x;
    }
    if (rightSlider) {
      rPos = rightSlider.getBoundingClientRect().x;
    }

    if (bar) {
      const pos = bar.getBoundingClientRect();
      barL = pos.x;
      barR = pos.x + pos.width;
    }

    this.setState({
      initialPos: {left: lPos, right: rPos},
      currPos: {left: lPos, right: rPos},
      barPos: {left: barL, right: barR},
      width: (barR-rPos),
      startValue: this.props.minValue || 0,
      endValue: this.props.maxValue || 100
    })
  }

  componentDidUpdate(prevProps: any, prevState: CustomRangeSliderState) {
    if (prevState.clicks.left !== this.state.clicks.left || prevState.clicks.right !== this.state.clicks.right ||
      prevState.barClicked !== this.state.barClicked) {
      let values = [this.getLowerValue()];
      if (this.getHigherValue() < (this.props.maxValue || 100)) {
        values.push(this.getHigherValue());
      }
      this.props.setValuesFn({name: this.props.name, values: values})
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleBarClick = (e: React.MouseEvent<HTMLElement>, section: string) => {
      const rightSlider = document.getElementById(`${this.props.id}-right-slider`);
      const leftSlider = document.getElementById(`${this.props.id}-left-slider`);
      let x: number = e.clientX;
      if (leftSlider && section === "a" && x < (this.state.currPos.right-this.state.width)) {      
        if (x < this.state.initialPos.left) {
          leftSlider.style.left = '0px';
          this.setState({currPos: {...this.state.currPos, left: this.state.initialPos.left}, barClicked: !this.state.barClicked})  
        } else {
          let val: number = x-this.state.initialPos.left;
          leftSlider.style.left = val+ 'px';
          this.setState({currPos: {...this.state.currPos, left: x}, barClicked: !this.state.barClicked})  
        }
      }
  
      if (rightSlider && (section === "b" || section === "c") && x > (this.state.currPos.left+this.state.width)) {
        if (x > this.state.initialPos.right) {
          rightSlider.style.right = '0px';
          this.setState({currPos: {...this.state.currPos, right: this.state.initialPos.right}, barClicked: !this.state.barClicked})  
        } else {
          let val = this.state.initialPos.right - x;
          rightSlider.style.right = val+ 'px';
          this.setState({currPos: {...this.state.currPos, right: x}, barClicked: !this.state.barClicked})  
        }
      }
  }

  getFills = () => {
    if (this.state.width > 0) {
      let barWidth = this.state.barPos.right-this.state.barPos.left;
      let a = (this.state.currPos.left - this.state.barPos.left)*100/(barWidth)
      let b = (this.state.currPos.right+this.state.width - this.state.currPos.left)*100/(barWidth)
      let c = (this.state.barPos.right -(this.state.currPos.right+this.state.width))*100/(barWidth)
      return {
        a,b,c
      }  
    } else {
      return {
        a: 0, b: 100, c: 0
      }
    }
  }

  handleSlideClick = (e: any) => {
    console.log(e.target, "pressed");
    if (e.target.name === "left-slider") {
      this.setState({
        clicks: { left: !this.state.clicks.left, right: false }
      })
    }
    if (e.target.name === "right-slider") {
      this.setState({
        clicks: { left: false, right: !this.state.clicks.right }
      })
    }
  }

  handleSlide = (e: any) => {
    const leftSlider = document.getElementById(`${this.props.id}-left-slider`);
    const rightSlider = document.getElementById(`${this.props.id}-right-slider`);

    let x: number = e.clientX;
    if (leftSlider && this.state.clicks.left && x < (this.state.currPos.right-this.state.width)) {      
      if (x < this.state.initialPos.left) {
        leftSlider.style.left = '0px';
        this.setState({currPos: {...this.state.currPos, left: this.state.initialPos.left}})  
      } else {
        let val: number = x-this.state.initialPos.left;
        leftSlider.style.left = val+ 'px';
        this.setState({currPos: {...this.state.currPos, left: x}})  
      }
    }

    if (rightSlider && this.state.clicks.right && x > (this.state.currPos.left+this.state.width)) {
      if (x > this.state.initialPos.right) {
        rightSlider.style.right = '0px';
        this.setState({currPos: {...this.state.currPos, right: this.state.initialPos.right}})  
      } else {
        let val = this.state.initialPos.right - x;
        rightSlider.style.right = val+ 'px';
        this.setState({currPos: {...this.state.currPos, right: x}})  
      }
    }
  }

  getLowerValue = () => {
    const vals = this.getFills();
    let minValue = this.props.minValue || 0;
    let maxValue = this.props.maxValue || 100;
    return Math.floor(minValue+(maxValue-minValue)*(vals.a/100))
  }

  getHigherValue = () => {
    const vals = this.getFills();
    let minValue = this.props.minValue || 0;
    let maxValue = this.props.maxValue || 100;
    return Math.ceil(minValue+(maxValue-minValue)*((100-vals.c)/100))
  }

  render () {
    return <div ref={this.wrapperRef} onMouseMove={this.handleSlide} className="custom-slider">
        <div className="text-label"><span>{this.props.name}</span></div>
      <button onMouseDown={this.handleSlideClick} name="left-slider" id={`${this.props.id}-left-slider`}
        className={this.state.clicks.left ? "left-slider clicked": "left-slider"}></button>
      <span className="left-slider-span">{this.getLowerValue()}</span>
      <button onMouseDown={this.handleSlideClick} name="right-slider" id={`${this.props.id}-right-slider`}
        className={this.state.clicks.right ? "right-slider clicked": "right-slider"}></button>
      <span className="right-slider-span">{this.getHigherValue() === this.props.maxValue ? `${this.props.maxValue}+` : this.getHigherValue()}</span>
      <div id={`${this.props.id}-slider-bar`} className="slider-container">
        <div onClick={(e) => this.handleBarClick(e, "a")} style={{width: `${this.getFills().a}%`}}  className="range"></div>
        <div onClick={(e) => this.handleBarClick(e, "b")} style={{width: `${this.getFills().b}%`}} className="range active"></div>
        <div onClick={(e) => this.handleBarClick(e, "c")} style={{width: `${this.getFills().c}%`}} className="range"></div>
      </div>
    </div>
  }
}
