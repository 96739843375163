// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.filter-grid .filters-section {
    display: flex;
    width: 100%;
}

.filter-grid  .filters-section .search-box {
    display: flex;
    flex-direction: column;
    min-width: 10%;
    max-width: 30%;
    padding: 1%;
}

.filter-grid  .filters-section .filter-item {
    display: flex;
    flex-direction: column;
    min-width: 10%;
    margin: 1%;
}

.filter-grid .tags {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
}

.filter-grid .tags .custom-label {
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/FilterGrid/FilterGrid.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,cAAc;IACd,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".filter-grid {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.filter-grid .filters-section {\n    display: flex;\n    width: 100%;\n}\n\n.filter-grid  .filters-section .search-box {\n    display: flex;\n    flex-direction: column;\n    min-width: 10%;\n    max-width: 30%;\n    padding: 1%;\n}\n\n.filter-grid  .filters-section .filter-item {\n    display: flex;\n    flex-direction: column;\n    min-width: 10%;\n    margin: 1%;\n}\n\n.filter-grid .tags {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow-x: auto;\n}\n\n.filter-grid .tags .custom-label {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
