import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";

export interface Filters {
    categories: string[],
    subcategories: string[]
}

export interface ProductsState {
    products: any[];
    pageNumber: number;
    limit: number;
    totalPages: number;
    totalResults: number;
    filters: Filters;
};

const initialState: ProductsState = {
    products: [],
    pageNumber: 1,
    limit: 12,
    totalPages: 1,
    totalResults: 0,
    filters: {
        categories: [],
        subcategories: []
    }
}

const productsSlice: Slice = createSlice({
    name: 'products',
    initialState: initialState,
    reducers: {
        setProducts: (state: ProductsState, action: PayloadAction<any[]>) => {
            state.products = action.payload;
        },
        setPagination: (state: ProductsState, action: PayloadAction<{ 
            pageNumber: number; limit: number
        }>) => {
            state.pageNumber = action.payload.pageNumber;
            state.limit = action.payload.limit
        },
        setTotalPages: (state: ProductsState, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        setTotalResults: (state: ProductsState, action: PayloadAction<number>) => {
            state.totalResults = action.payload;
        },
        setFilters: (state: ProductsState, action: PayloadAction<{name: string; filters: []}>) => {
            if (action.payload.name === "categories" || action.payload.name === "subcategories") {
                state.filters[action.payload.name] = action.payload.filters;
            }
        },
    },
})

export const productsActions = {
    setProducts: productsSlice.actions.setProducts,
    setPagination: productsSlice.actions.setPagination,
    setTotalPages: productsSlice.actions.setTotalPages,
    setTotalResults: productsSlice.actions.setTotalResults,
    setFilters: productsSlice.actions.setFilters,
}

export default productsSlice.reducer;