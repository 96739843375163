import { useEffect, useState } from "react"
import { Icon } from "semantic-ui-react";
import { DataGrid, DeleteIcon, EditIcon, Row, RowHeader } from "../../components";
import { HeaderItem, RowItem, RowsContainer } from "../../components/RowsContainer";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ComponentsState } from "../../store/reducers/componentsReducer";

export const PartItem = (props: {index: number}) => {
    const state = useAppSelector((state: any): ComponentsState => state.components);
    const { components } = actions;
    const dispatch = useAppDispatch();
    const initialObj = {...state.components[props.index]};
    const [partObj, setPartObj] = useState(initialObj);

    useEffect(() => {
        const initialObj = {...state.components[props.index]};
        setPartObj(initialObj);
    }, [props.index, state.components[props.index]])

    const handleSelectClick = (id: string) => {
       dispatch(components.setSelections(id));
    }

    const handleDelete = () => {
        dispatch(components.setDeleteForm(true));
        dispatch(components.setDeleteIndex(props.index));
    }

    const handleEdit = () => {
        dispatch(components.setEditForm(true));
        dispatch(components.setEditIndex(props.index));
    }

    const getCheckbox = () => {
        return <div className="checkbox" onClick={() => handleSelectClick(partObj._id)}>
        <Icon size="large" name={state.selections[partObj._id] ? "check square outline" : "square outline"}></Icon></div>
    }

    return <Row color={props.index%2 === 0 ? false : true} key={partObj.name}>
        <RowItem width={5}>{getCheckbox()}</RowItem>
        <RowItem width={15} value={partObj.name}></RowItem>
        <RowItem width={10} value={partObj.partNumber}></RowItem>
        <RowItem width={20} value={partObj.description}></RowItem>
        <RowItem width={20} value={`${partObj.category}, ${partObj.subcategory}\n${partObj.footPrint}`}></RowItem>
        <RowItem width={20} value={`${partObj.manufacturer}(${partObj.supplier})`}></RowItem>
        <RowItem width={10}>
            <DeleteIcon onClick={handleDelete}/>
            <EditIcon onClick={handleEdit}/>
        </RowItem>
    </Row>
}

export const PartHeader = () => {
    const state = useAppSelector((state: any): ComponentsState => state.components);
    const { components } = actions;
    const dispatch = useAppDispatch()
    const handleSelectAll = () => {
        if (state.selectAll) {
            dispatch(components.setUnselectAll({}))
        } else {
            dispatch(components.setSelectAll({}))
        }
    }

    return <RowHeader>
        <HeaderItem width={5}>
            <div className="checkbox" onClick={handleSelectAll}><Icon size="large"
            name={state.selectAll ? "checkmark box" : "square outline"}></Icon></div>
        </HeaderItem>
        <HeaderItem width={15} value="Name"></HeaderItem>
        <HeaderItem width={10} value="Part Number"></HeaderItem>
        <HeaderItem width={20} value="Description"></HeaderItem>
        <HeaderItem width={20} value="Type"></HeaderItem>
        <HeaderItem width={20} value="Manufacturer"></HeaderItem>
        <HeaderItem width={10} value="Actions"></HeaderItem>
    </RowHeader>
}
export const PartsDataGrid = () => {
    const state = useAppSelector((state: any): ComponentsState => state.components);
    const getData = () => {
        return <RowsContainer>
            {state.components.map((comp, ind) => {
                return <PartItem index={ind}></PartItem>
            })}
        </RowsContainer>
    }

    return <DataGrid>
        <PartHeader></PartHeader>
        {getData()}
    </DataGrid>
}