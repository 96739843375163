import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
    isAuthenticated: boolean;
    userType: string;
    userId: string;
};

const initialState: AuthState = {
    isAuthenticated: !(localStorage.getItem("accessToken") === null),
    userType: "guide",
    userId: localStorage.getItem("userId") || ""
}

const authSlice: Slice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        setAccessData(state: AuthState, action: PayloadAction<any>) {
            localStorage.setItem("accessToken", action.payload.accessToken);
            localStorage.setItem("userId", action.payload.userId);
            state.userId = action.payload.userId;
            state.isAuthenticated = true;
        },
        setAuthStatus(state: AuthState, action: PayloadAction<boolean>) {
            state.isAuthenticated = action.payload;
        }
    },
})

export const authActions = {
    setAccessData: authSlice.actions.setAccessData,
    setAuthStatus: authSlice.actions.setAuthStatus,
}

export default authSlice.reducer;