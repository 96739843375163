import { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react"
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { AssemblyFilter, ComponentFilter, FilterName, ProductionFilter, PurchasesFilter } from "../../utils/enums";
import { CustomDatePicker } from "../CustomDatePicker/CustomDatePicker";
import { DropdownField } from "../Dropdown/DropdownField";
import { FormField, InputTextField, TextAreaField } from "./Fields";
import "./Form.css";

const multiSelects: any = [
    ComponentFilter.MANUFACTURER, ComponentFilter.SUPPLIER, ProductionFilter.ASSEMBLY_NAME
]

const fieldTypes: any = {
    name: "text",
    partNumber: "text",
    description: "textArea",
    category: "dropdown",
    subcategory: "dropdown",
    manufacturer: "dropdown",
    supplier: "dropdown",
    footPrint: "dropdown",
    assemblyNumber: "text",
    assemblyType: "dropdown",
    assemblyName: "dropdown",
    type: "dropdown",
    purchaseDate: "date",
    purchasedBy: "text",
    paymentMode: "dropdown",
    deliveryStatus: "dropdown",
    deliveryDate: "date",
    note: "textArea"
}

const labels: any = {
    [ComponentFilter.SUBCATEGORY]: "Subcategory in Category",
    [PurchasesFilter.FROM_DATE]: "Purchased Date",
    [PurchasesFilter.TO_DATE]: "Delivery Date",
    name: "Name",
    partNumber: "Part Number",
    assemblyNumber: "Assembly Number",
    description: "Description",
    type: "Assembly Type",
    purchasedDate: "Purchased Date",
    purchasedBy: "Purchased By",
    paymentMode: "Payment Mode",
    note: "Details",
    grandTotalPrice: "Total Cost"
}

const fieldsMap: any = {
    category: ComponentFilter.CATEGORY,
    subcategory: ComponentFilter.SUBCATEGORY,
    manufacturer: ComponentFilter.MANUFACTURER,
    supplier: ComponentFilter.SUPPLIER,
    footPrint: ComponentFilter.FOOTPRINT,
    type: AssemblyFilter.ASSEMBLY_TYPE,
    purchaseDate: PurchasesFilter.FROM_DATE,
    paymentMode: PurchasesFilter.PAYMENT_MODE,
    deliveryStatus: PurchasesFilter.DELIVERY_STATUS,
    deliveryDate: PurchasesFilter.TO_DATE,
    assemblyName: ProductionFilter.ASSEMBLY_NAME,
    assemblyType: AssemblyFilter.ASSEMBLY_TYPE,
}

const filtersMap: any = {
   [ComponentFilter.CATEGORY]: "category",
   [ComponentFilter.SUBCATEGORY]: "subcategory",
   [ComponentFilter.MANUFACTURER]: "manufacturer",
   [ComponentFilter.SUPPLIER]: "supplier",
   [ComponentFilter.FOOTPRINT]: "footPrint",
   [AssemblyFilter.ASSEMBLY_TYPE]: "type",
   [PurchasesFilter.FROM_DATE]: "purchasedDate",
   [PurchasesFilter.PAYMENT_MODE]: "paymentMode",
   [PurchasesFilter.DELIVERY_STATUS]: "deliveryStatus",
   [PurchasesFilter.TO_DATE]: "deliveryDate",
   [ProductionFilter.ASSEMBLY_NAME]: "assemblyName",
   [AssemblyFilter.ASSEMBLY_TYPE]: "assemblyType",
}

const payloadsMap: any = {
    [ComponentFilter.SUBCATEGORY]: ["category"],
}

export const Form = (props: {
    id?: string;
    showLoader?: boolean;
    fields: string[];
    readonlyFields?: string[];
    formErrors: any;
    formObj: any;
    setFormObj: Function;
    setFormErrors: Function;
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    width?: string;
    height?: string;
}) => {
    const dispatch = useAppDispatch();
    const { common } = actions;
    const [ fieldCount, setFieldsCount] = useState(20);

    useEffect(() => {
        let n = props.fields.length;
        let r = Math.ceil(n/3)
        setFieldsCount(r);
    }, [props.fields.length])

    const getSelections = (name: string) => {       
        let value = props.formObj[name];
        if (Array.isArray(value) && value.length > 0) {
            return value.map(item => {
                return {text: item, value: item, key: item}
            })
        } else if (value && value.length > 0) {
            return [{text: value, value: value, key: value}]
        }
        return []
    }

    const handleSelection = (e: any, data: any) => {
        const { name, values, multiselect } = data;
        if (multiselect) {
            props.setFormObj({
                ...props.formObj,
                [filtersMap[name]]: values.map((item: any) => item.value)
            })    
        } else {
            props.setFormObj({
                ...props.formObj,
                [filtersMap[name]]: values[0] ? values[0].value : ""
            })    
        }
        if ((multiselect && values.length > 0) || (!multiselect && values[0])) {
            props.setFormErrors({...props.formErrors, [filtersMap[name]]: false})
        }
    }

    const handleDateChange = (data: any) => {
        const { name, value, multiselect } = data;
        props.setFormObj({
            ...props.formObj,
            [filtersMap[name]]: value[0]
        })    
    }

    const getClassName = (className: string, fieldName: string) => {
        if (props.formErrors[fieldName]) {
            return `${className} error`
        } else {
            return className;
        }
    }

    const handleInputChangeBasic = (e: any) => {
        const { name, value } = e.target;
        props.setFormObj({
            ...props.formObj,
            [name]: value
        });
        if (value) {
            props.setFormErrors({...props.formErrors, [name]: false})
        }
    }

    const getPayload = (name: FilterName) => {
        let payload: any = {};
        if (!payloadsMap[name]) {
            return payload;
        }
        for (let key of payloadsMap[name]) {
            payload[key] = props.formObj[key] || [];
        }
        
        return payload;
    }

    const getFields = () => {
        let names = props.fields;
        let rows = [];
        if (props.readonlyFields) {
            let names = props.readonlyFields;
            for (let i = 0; i < props.readonlyFields.length; i++) {
                rows.push(<FormField readonly={true}>
                    <InputTextField
                        formError=""
                        readonly={true}
                        name={names[i]}
                        label={labels[names[i]]}
                        value={props.formObj[names[i]]}
                    ></InputTextField>
                </FormField>)
            }
        }
        for (let i = 0; i < names.length; i++) {
            let field = null;
            if (fieldTypes[names[i]] === "dropdown") {
                let filterName: FilterName = fieldsMap[names[i]];
                field = <DropdownField
                    multiselect={multiSelects.includes(filterName)}
                    closeOnSelect={!multiSelects.includes(filterName)}
                    noCheckbox={!multiSelects.includes(filterName)}
                    payload={getPayload(filterName)}
                    name={filterName}
                    label={labels[filterName] ? labels[filterName] : filterName}
                    onSelection={handleSelection}
                    key={filterName}
                    selections={getSelections(names[i])}
                    addOption={true}
                    isError={props.formErrors[filtersMap[filterName]]}
                />
            } else if(fieldTypes[names[i]] === "date") {
                let filterName: FilterName = fieldsMap[names[i]];
                field = <CustomDatePicker
                    name={filterName}
                    label={labels[filterName] ? labels[filterName] : filterName}
                    key={filterName}
                    onChange={handleDateChange}
                    value={props.formObj[filtersMap[filterName]]}
                />
            } else {
                field = fieldTypes[names[i]] === "text" ? <InputTextField label={labels[names[i]]} formError={props.formErrors[names[i]]}
                        value={props.formObj[names[i]]} name={names[i]} onChange={handleInputChangeBasic}
                    /> : <TextAreaField label={labels[names[i]]} formError={props.formErrors[names[i]]}
                    value={props.formObj[names[i]]} name={names[i]} onChange={handleInputChangeBasic}/>
            }
            rows.push(<FormField>{field}</FormField>);
        }
        return rows;
    }

    return  <div className="custom-form" style={{width: props.width, height: props.height}}>
        {getFields()}
        <Dimmer active={props.showLoader}>
            <Loader inverted content='Loading' />
        </Dimmer>
        {props.children ? props.children : null}
    </div>
}