import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentFilter } from '../../utils/enums';
import { Pagination } from './commonReducer';

export interface ComponentsState {
    components: any[];
    pagination: Pagination;
    filterValues: {name: ComponentFilter, value: any}[],
    searchText: string;
    selections: any;
    selectAll: boolean;
    showForms: {add: boolean, delete: boolean, edit: boolean};
    editIndex: number;
    deleteIndex: number;
};

const initialState: ComponentsState = {
    components: [],
    pagination: {
        totalPages: 1,
        pageNumber: 1,
        limit: 10,
        totalResults: 0,
        currentResults: 0,
    },
    filterValues: [],
    searchText: "",
    selections: {},
    selectAll: false,
    showForms: {add: false, delete: false, edit: false},
    editIndex: 0,
    deleteIndex: 0,
}

const componentsSlice: Slice = createSlice({
    name: 'components',
    initialState: initialState,
    reducers: {
        setComponents: (state: ComponentsState, action: PayloadAction<any[]>) => {
            state.components = action.payload;
        },
        setPageNumber: (state: ComponentsState, action: PayloadAction<number>) => {
            state.pagination.pageNumber = action.payload
        },
        setPagination: (state: ComponentsState, action: PayloadAction<{ 
            pageNumber: number; limit: number, totalResults: number, totalPages: number, currentResults: number
        }>) => {
            state.pagination.pageNumber = action.payload.pageNumber;
            state.pagination.limit = action.payload.limit
            state.pagination.currentResults = action.payload.currentResults;
            state.pagination.totalPages = action.payload.totalPages;
            state.pagination.totalResults = action.payload.totalResults;
        },
        setFilters: (state: ComponentsState, action: PayloadAction<{name: ComponentFilter; value: any}>) => {
            let filterIndex = state.filterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.filterValues[filterIndex].value = action.payload.value;
            } else {
                state.filterValues.push({...action.payload})
            }
        },
        setSearchText: (state: ComponentsState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        setSelections: (state: ComponentsState, action: PayloadAction<string>) => {
            state.selections[action.payload] = state.selections[action.payload] ? false : true;
            if (!state.selections[action.payload]) {
                state.selectAll = false;
            }
        },
        setSelectAll: (state: ComponentsState) => {
            state.components.forEach(comp => {
                state.selections[comp._id] = true;
            })
            state.selectAll = true;
        },
        setUnselectAll: (state: ComponentsState) => {
            state.selections = {};
            state.selectAll = false;
        },
        setAddForm: (state: ComponentsState, action: PayloadAction<boolean>) => {
            state.showForms.add = action.payload;
        },
        setEditForm: (state: ComponentsState, action: PayloadAction<boolean>) => {
            state.showForms.edit = action.payload;
        },
        setDeleteForm: (state: ComponentsState, action: PayloadAction<boolean>) => {
            state.showForms.delete = action.payload;
        },
        setEditIndex: (state: ComponentsState, action: PayloadAction<number>) => {
            state.editIndex = action.payload;
        },
        setDeleteIndex: (state: ComponentsState, action: PayloadAction<number>) => {
            state.deleteIndex = action.payload;
        }
    },
})

export const componentsActions = {
    setComponents: componentsSlice.actions.setComponents,
    setPagination: componentsSlice.actions.setPagination,
    setPageNumber: componentsSlice.actions.setPageNumber,
    setFilters: componentsSlice.actions.setFilters,
    setSelections: componentsSlice.actions.setSelections,
    setSelectAll: componentsSlice.actions.setSelectAll,
    setUnselectAll: componentsSlice.actions.setUnselectAll,
    setSearchText: componentsSlice.actions.setSearchText,
    setAddForm: componentsSlice.actions.setAddForm,
    setDeleteForm: componentsSlice.actions.setDeleteForm,
    setEditForm: componentsSlice.actions.setEditForm,
    setEditIndex: componentsSlice.actions.setEditIndex,
    setDeleteIndex: componentsSlice.actions.setDeleteIndex,
}

export default componentsSlice.reducer;