import { useEffect, useState } from "react";
import { CollapseIcon, DataGrid, ExpandIcon, RowHeader } from "../../components";
import { HeaderItem, Row, RowItem, RowsContainer } from "../../components/RowsContainer";
import { useAppSelector } from "../../store/hooks";
import { PricesState } from "../../store/reducers/pricesReducer";

export const PriceRecord = (props: {index: number}) => {
    const [expand, setExpand] = useState(false);
    const state = useAppSelector((state: any): PricesState => state.prices);
    const initialObj = {...state.prices[props.index]};
    const [priceObj, setPriceObj] = useState(initialObj);

    useEffect(() => {
        const initialObj = {...state.prices[props.index]};
        setPriceObj(initialObj);
    }, [props.index, state.prices[props.index]])

    const getRatesHeader = () => {
        return <RowHeader className="expand-section">
            <HeaderItem width={33.33} value={"Price"}></HeaderItem>
            <HeaderItem width={33.33} value={"Quantity"}></HeaderItem>
            <HeaderItem width={33.33} value={"Total Cost"}></HeaderItem>
        </RowHeader>
    }

    const getRates = (rates: any[]) => {
        return <RowsContainer className="expand-section">
            {
                rates.map(item => {
                    return <Row>
                        <RowItem width={33.33} value={`${item.price} ${priceObj.currencySymbol}/${priceObj.quantityUnit}`}></RowItem>
                        <RowItem width={33.33} value={item.totalPurchase+" "+ priceObj.currencySymbol}></RowItem>
                        <RowItem width={33.33} value={item.totalQuantity+" "+ priceObj.quantityUnit}></RowItem>
                    </Row>
                })
            }
        </RowsContainer>
    }

    let avgPrice = parseFloat(`${priceObj.totalPurchase/priceObj.totalQuantity}`).toFixed(2)
    return <><Row color={props.index%2 === 0 ? false : true} key={priceObj.name}>
        <RowItem width={25} value={priceObj.partName}></RowItem>
        <RowItem width={25} value={priceObj.totalPurchase+" "+ priceObj.currencySymbol}></RowItem>
        <RowItem width={25} value={priceObj.totalQuantity+" "+ priceObj.quantityUnit}></RowItem>
        <RowItem width={20} value={`${avgPrice} ${priceObj.currencySymbol}/${priceObj.quantityUnit}`}></RowItem>
        <RowItem width={5}>
            {expand ? <CollapseIcon onClick={() => {setExpand(false)}}/>: <ExpandIcon onClick={() => {setExpand(true)}}/>}
        </RowItem>
    </Row>
    {expand ? <DataGrid>
        {getRatesHeader()}
        {getRates(priceObj.prices)}
    </DataGrid>:null}
    </>
}

export const PriceHeader = () => {
    return <RowHeader>
        <HeaderItem width={25} value="Part Name"></HeaderItem>
        <HeaderItem width={25} value="Total Expenditure"></HeaderItem>
        <HeaderItem width={25} value="Total Quantity Purchased"></HeaderItem>
        <HeaderItem width={20} value="Average Price"></HeaderItem>
        <HeaderItem width={5} value=""></HeaderItem>
    </RowHeader>
}

export const PriceDataGrid = () => {
    const state = useAppSelector((state: any): PricesState => state.prices);
    const getData = () => {
        return <RowsContainer>
            {state.prices.map((comp, ind) => {
                return <PriceRecord index={ind}></PriceRecord>
            })}
        </RowsContainer>
    }

    return <DataGrid>
        <PriceHeader></PriceHeader>
        {getData()}
    </DataGrid>
}