import React, { createRef } from "react";
import { Checkbox, Icon } from "semantic-ui-react"
import { FilterName } from "../../utils/enums";
import { addOption, getOptions } from "./utils";
import "./Dropdown.css";
import { v4 as uuidv4 } from 'uuid';
import { DropdownContainer, DropdownOption, DropdownFilterProps, InputBox, DropdownFieldProps } from "./DropdownParts";


export interface DropdownFieldState {
    isOpen: boolean;
    options: DropdownOption[];
    searchText: string;
    pos: any;
}

const initialState: DropdownFieldState = {
    isOpen: false,
    options: [],
    searchText: "",
    pos: { left: 0, right: 0, top: 0, bottom: 0},
}

export class DropdownField extends React.Component<DropdownFieldProps, DropdownFieldState>{
    constructor(props: DropdownFieldProps) {
        super(props);
        this.state = { ...initialState }
    }
    wrapperRef: any = createRef();
    inputRef: any = createRef();

    handleClickOutside = (e: any) => {
        if (
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(e.target)
        ) {
            if (this.state.isOpen) {
                this.setState({
                    isOpen: false,
                    searchText: ""
                })
            }
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        return <div ref={this.wrapperRef} className="custom-dropdown">
            {this.props.label ? <div className={this.props.isError ? "text-label error": "text-label"}><span>{this.props.label || this.props.name}</span>
                {this.props.isError ? <span>{this.props.errorMsg || "*"}</span>: null}
                </div> : null}
            {!this.props.label && this.props.isError ? <div className={"text-label error"}><span>{this.props.errorMsg || "*This field is required."}</span></div> : null}

            <InputBox
                {...this.props}
                isOpen={this.state.isOpen}
                setOpen={(isOpen:boolean)=>this.setState({isOpen})}
                setSearchText={(searchText: any) => {this.setState({searchText})}}
                isError={this.props.isError}
                setPos={(pos: any) => this.setState({pos: {...pos}})}
            ></InputBox>
            <DropdownContainer
                pos={this.state.pos}
                {...this.props}
                searchText={this.state.searchText}
                isOpen={this.state.isOpen}
                setOpen={(isOpen:boolean)=>this.setState({isOpen})}
            ></DropdownContainer>
        </div>
    }
}

