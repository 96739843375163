import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";

export interface Filters {
    categories: string[],
}

export interface SubcategoriesState {
    subcategories: any[];
    totalPages: number;
    totalResults: number;
    pageNumber: number;
    limit: number;
    filters: Filters;
};

const initialState: SubcategoriesState = {
    subcategories: [],
    pageNumber: 1,
    totalResults: 0,
    totalPages: 1,
    limit: 20,
    filters: {
        categories: []
    }
}

const subcategoriesSlice: Slice = createSlice({
    name: 'subcategories',
    initialState: initialState,
    reducers: {
        setSubcategories: (state: SubcategoriesState, action: PayloadAction<any[]>) => {
            state.subcategories = action.payload;
        },
        setPagination: (state: SubcategoriesState, action: PayloadAction<{ 
            pageNumber: number; limit: number
        }>) => {
            state.pageNumber = action.payload.pageNumber;
            state.limit = action.payload.limit
        },
        setTotalPages: (state: SubcategoriesState, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        setTotalResults: (state: SubcategoriesState, action: PayloadAction<number>) => {
            state.totalResults = action.payload;
        },
        setFilters: (state: SubcategoriesState, action: PayloadAction<{name: string; filters: []}>) => {
            if (action.payload.name === "categories") {
                state.filters[action.payload.name] = action.payload.filters;
            }
        },
    },
})

export const subcategoriesActions = {
    setSubcategories: subcategoriesSlice.actions.setSubcategories,
    setPagination: subcategoriesSlice.actions.setPagination,
    setTotalPages: subcategoriesSlice.actions.setTotalPages,
    setTotalResults: subcategoriesSlice.actions.setTotalResults,
    setFilters: subcategoriesSlice.actions.setFilters,
}

export default subcategoriesSlice.reducer;