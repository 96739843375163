// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.pagination .prev-icon, .next-icon, .current-page {
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1%;
    width: 8%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.11);
}


.pagination .prev-icon:hover, .next-icon:hover {
    cursor: pointer;
    background-color: var(--link-active);
}

.pagination .results-text, .pages-text {
    padding: 2%;
    font-size: medium;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,YAAY;IACZ,qCAAqC;AACzC;;;AAGA;IACI,eAAe;IACf,oCAAoC;AACxC;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".pagination {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n    padding: 2%;\n}\n\n.pagination .prev-icon, .next-icon, .current-page {\n    background: #FFFFFF;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 1%;\n    width: 8%;\n    height: 100%;\n    border: 1px solid rgba(0, 0, 0, 0.11);\n}\n\n\n.pagination .prev-icon:hover, .next-icon:hover {\n    cursor: pointer;\n    background-color: var(--link-active);\n}\n\n.pagination .results-text, .pages-text {\n    padding: 2%;\n    font-size: medium;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
