import { categoriesAPIs, subcategoriesAPIs, componentsAPIs, assemblyTypesAPIs } from "../../apis";
import { assemblyAPIs } from "../../apis/assembly";
import { footprintsAPIs } from "../../apis/footprints";
import { manufacturersAPIs } from "../../apis/manufacturers";
import { suppliersAPIs } from "../../apis/suppliers";
import { getDeliveryStatus, getPaymentModes, getPurchaseModes, getPurchaseUnits } from "../../utils/commonUtils";
import { ComponentFilter, AssemblyFilter, PurchasesFilter, FilterName, StockFilter, ProductionFilter } from "../../utils/enums";

export const getOptions = (name: FilterName) => {
    const maps: any = {
        [ComponentFilter.CATEGORY]: categoriesAPIs.getCategoriesData,
        [ComponentFilter.SUBCATEGORY]: subcategoriesAPIs.getSubcategoriesData,
        [ComponentFilter.MANUFACTURER]: manufacturersAPIs.getManufacturersData,
        [ComponentFilter.SUPPLIER]: suppliersAPIs.getSuppliersData,
        [ComponentFilter.FOOTPRINT]: footprintsAPIs.getFootprintsData,
        [AssemblyFilter.ASSEMBLY_TYPE]: assemblyTypesAPIs.getAssemblyTypesData,
        [PurchasesFilter.PAYMENT_MODE]: getPaymentModes,
        [PurchasesFilter.DELIVERY_STATUS]: getDeliveryStatus,
        [PurchasesFilter.PURCHASE_MODE]: getPurchaseModes,
        [PurchasesFilter.QUANTITY_UNIT]: getPurchaseUnits,
        [PurchasesFilter.PART_NAME]: componentsAPIs.getComponentsData,
        [StockFilter.ASSEMBLY_NAME]: assemblyAPIs.getAssemblysData,
        [ProductionFilter.ASSEMBLY_NAME]: assemblyAPIs.getAssemblysData,
        [PurchasesFilter.QUANTITY]: async () => {
            const options = [];
            for (let i = 0; i < 100; i++) {
                options.push({name: `${i+1}`})
            }
            return {results: options}
        },
        [PurchasesFilter.TAX_TYPE]: async () => {
            const options = ["CGST", "IGST", "SGST"];
            return {results: options.map(item => {return {name: item}})}
        },
        [PurchasesFilter.ADDITIONAL_CHARGES_TYPE]: async () => {
            const options = ["Shipping", "Handling", "Custom"];
            return {results: options.map(item => {return {name: item}})}
        },
    }
    return maps[name]
}

export const addOption = (name: FilterName) => {
    const maps: any = {
        [ComponentFilter.CATEGORY]: categoriesAPIs.addCategory,
        [ComponentFilter.SUBCATEGORY]: subcategoriesAPIs.addSubcategory,
        [ComponentFilter.MANUFACTURER]: manufacturersAPIs.addManufacturer,
        [ComponentFilter.SUPPLIER]: suppliersAPIs.addSupplier,
        [ComponentFilter.FOOTPRINT]: footprintsAPIs.addFootprint,
        [AssemblyFilter.ASSEMBLY_TYPE]: assemblyTypesAPIs.addAssemblyType,
    }
    return maps[name]
}