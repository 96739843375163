import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { InventoryFilter } from '../../utils/enums';
import { Pagination } from './commonReducer';

export interface InventoryState {
    inventory: any[];
    pagination: Pagination;
    filterValues: {name: InventoryFilter, value: any}[],
    searchText: string;
};

const initialState: InventoryState = {
    inventory: [],
    pagination: {
        totalPages: 1,
        pageNumber: 1,
        limit: 10,
        totalResults: 0,
        currentResults: 0,
    },
    filterValues: [],
    searchText: "",
}

const inventorySlice: Slice = createSlice({
    name: 'inventory',
    initialState: initialState,
    reducers: {
        setInventory: (state: InventoryState, action: PayloadAction<any[]>) => {
            state.inventory = action.payload;
        },
        setPageNumber: (state: InventoryState, action: PayloadAction<number>) => {
            state.pagination.pageNumber = action.payload
        },
        setPagination: (state: InventoryState, action: PayloadAction<{ 
            pageNumber: number; limit: number, totalResults: number, totalPages: number, currentResults: number
        }>) => {
            state.pagination.pageNumber = action.payload.pageNumber;
            state.pagination.limit = action.payload.limit
            state.pagination.currentResults = action.payload.currentResults;
            state.pagination.totalPages = action.payload.totalPages;
            state.pagination.totalResults = action.payload.totalResults;
        },
        setFilters: (state: InventoryState, action: PayloadAction<{name: InventoryFilter; value: any}>) => {
            let filterIndex = state.filterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.filterValues[filterIndex].value = action.payload.value;
            } else {
                state.filterValues.push({...action.payload})
            }
        },
        setSearchText: (state: InventoryState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
    },
})

export const inventoryActions = {
    setInventory: inventorySlice.actions.setInventory,
    setPagination: inventorySlice.actions.setPagination,
    setPageNumber: inventorySlice.actions.setPageNumber,
    setFilters: inventorySlice.actions.setFilters,
    setSearchText: inventorySlice.actions.setSearchText,
}

export default inventorySlice.reducer;