import { ChangeEventHandler } from "react";
import { FilterGridType, FilterName } from "../utils/enums";
import "./components.css";
import { FilterGrid } from "./FilterGrid/FilterGrid";
import { Searchbox } from "./Searchbox/Searchbox";
export const CommandBar = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    dropdowns?: FilterName[];
    dropdownValues?: any;
    setDropdownFn?: Function;
    gridType: FilterGridType;
    searchFn?: ChangeEventHandler<HTMLInputElement>;
    actions?: React.ReactElement<any, any>[]
    noTags?: boolean;
}) => {
    return <div id={`command-bar-${props.gridType}`} className={props.className ? `command-bar ${props.className}` : "command-bar"}>
        <div className="filter-grid-container">
            {props.dropdowns ? <FilterGrid
                filters={props.dropdowns || []}
                filterValues={props.dropdownValues}
                dispatchFnFilters={props.setDropdownFn || function(){}}
                grid={props.gridType}
                noTags={props.noTags}
            >
                {props.searchFn ? <Searchbox
                    displayLabel
                    key="searchbox"
                    onChange={props.searchFn}
                />: <></>}
            </FilterGrid>: Array.isArray(props.children) ? props.children[0] : props.children}
        </div>
        {props.actions ? <div className="actions-section">
            {props.actions}
        </div>: null}
    </div>
}