
import axios from 'axios';
import urls from './urls';
import * as qs from 'query-string';

export const manufacturersAPIs = {
    addManufacturer: async (payload: {
        name: string[]
    }) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "application/json"
            }
            const response = await axios.post(urls.manufacturers, { name: payload.name}, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    getManufacturersData: async (payload: {
        pageNumber: number;
        limit: number;
        searchText?: string;
    }) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            let params: any = {
                "pageNumber": payload.pageNumber,
                "limit": payload.limit
            }

            if (payload.searchText) {
                params.searchText = payload.searchText
            }

            let response = await axios.get(urls.manufacturers, {
                params,
                paramsSerializer: params => qs.stringify(params),
                headers });
            return response.data.data;
        } catch (error) {
            console.log(error);
            
            let err: any = error;
            return err.error;
        }
    },
    deleteManufacturer: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            }
            const response = await axios.delete(`${urls.manufacturers}/${payload.id}`, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
}