import { useEffect } from "react";
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { pricesAPIs } from "../../apis";
import { PricesState } from "../../store/reducers/pricesReducer";
import { NavBar } from "../../components/NavBar/NavBar";
import { FilterGridType, PricesFilter } from "../../utils/enums";
import { PriceDataGrid } from "./PricesData";
import { ChangeButton, CommandBar, Container, MainSection } from "../../components";
import { useHistory } from "react-router";


export const Prices = () => {
    const state = useAppSelector((state: any): PricesState => state.prices);
    const history = useHistory();
    const { prices, common } = actions;
    const dispatch = useAppDispatch();

    const pricesFilters = [
        PricesFilter.PART_NAME
    ]

    const getFilterValue = (name: PricesFilter): any[] => {
        const index = state.filterValues.findIndex((filter: any) => filter.name === name);
        if (index > -1) {
            return state.filterValues[index].value;
        } else {
            return [];
        }
    }

    useEffect(() => {
        pricesAPIs.getPricesData({
            pageNumber: state.pagination.pageNumber,
            limit: state.pagination.limit,
            searchText: state.searchText,
            assemblyName: getFilterValue(PricesFilter.ASSEMBLY_NAME).map(item => item.value),
            partName: getFilterValue(PricesFilter.PART_NAME).map(item => item.value),
        }).then((pricesData: any) => {
            dispatch(prices.setPrices(pricesData.results));
            dispatch(prices.setPagination({
                pageNumber: pricesData.pageNumber,
                limit: pricesData.limit,
                totalPages: pricesData.totalPages,
                totalResults: pricesData.totalResults,
                currentResults: pricesData.currentResults
            }))
        }).catch((error: any) => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in fetching components data"})
        })
    }, [state.searchText, state.pagination, JSON.stringify(state.filterValues)])

    const handleSearch = (e: any) => {
        dispatch(prices.setSearchText(e.target.value));
    }


    const handleNavigation = () => {
        history.push("/prices")
    }
    return <Container className="prices">
        <NavBar
            sectionName="Prices"
            name="prices"
        />
        <MainSection className="main-section">
            <CommandBar
                searchFn={handleSearch}
                dropdowns={pricesFilters}
                dropdownValues={state.filterValues}
                setDropdownFn={prices.setFilters}
                actions={[<ChangeButton onClick={() => handleNavigation()} label="Assembly Prices"></ChangeButton>]}
                gridType={FilterGridType.PRICES}
            ></CommandBar>
            <PriceDataGrid></PriceDataGrid>
        </MainSection>
    </Container>
}