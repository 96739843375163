import { configureStore } from '@reduxjs/toolkit';
import authReducer, { authActions } from './reducers/authReducer';
import commonReducer, { commonActions } from './reducers/commonReducer';
import productsReducer, { productsActions } from './reducers/productsReducer';
import categoriesReducer, { categoriesActions } from './reducers/categoriesReducer';
import subcategoriesReducer, { subcategoriesActions } from './reducers/subcategoriesReducer';
import purchasesReducer, { purchasesActions } from './reducers/purchasesReducer';
import addPurchasesReducer, { addPurchasesActions } from './reducers/addPurchasesReducer';
import componentsReducer, { componentsActions } from './reducers/componentsReducer';
import assemblyReducer, { assemblyActions } from './reducers/assemblyReducer';
import inventoryReducer, { inventoryActions } from './reducers/inventoryReducer';
import stockReducer, { stockActions } from './reducers/stockReducer';
import pricesReducer, { pricesActions } from './reducers/pricesReducer';
import assemblyPricesReducer, { assemblyPricesActions } from './reducers/assemblyPricesReducer';
import productionReducer, { productionActions } from './reducers/productionReducer';


const store = configureStore({
    reducer: {
        auth: authReducer,
        common: commonReducer,
        products: productsReducer,
        categories: categoriesReducer,
        subcategories: subcategoriesReducer,
        purchases: purchasesReducer,
        addPurchases: addPurchasesReducer,
        components: componentsReducer,
        assembly: assemblyReducer,
        inventory: inventoryReducer,
        stocks: stockReducer,
        prices: pricesReducer,
        assemblyPrices: assemblyPricesReducer,
        production: productionReducer,
    },
})

export const actions = {
    auth: authActions,
    common: commonActions,
    products: productsActions,
    categories: categoriesActions,
    subcategories: subcategoriesActions,
    purchases: purchasesActions,
    addPurchases: addPurchasesActions,
    components: componentsActions,
    assembly: assemblyActions,
    inventory: inventoryActions,
    stocks: stockActions,
    prices: pricesActions,
    production: productionActions,
    assemblyPrices: assemblyPricesActions
}

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch