import { MouseEventHandler } from "react"

export const Breadcrumb = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    width?: string;
    height?: string;
    active?: boolean;
    onClick?: MouseEventHandler
}) => {
    let className = props.className ? `breadcrumb ${props.className}` : `breadcrumb`;
    return <div onClick={props.onClick} className={props.active ? `${className} active` : className}>
        <div className={props.active ? 'content active' : 'content'}>{props.children}</div>
    </div>
}
