import { Pagination, PaginationProps } from "../../components/Pagination/Pagination";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getActions, getIcons, iconMaps } from "../../utils/commonUtils";
import { SidebarIcon } from "../Icons";
import "./NavBar.css";

export interface NavBarProps {
    sectionName: string,
    pagination?: boolean,
    name: string;
}

export const NavBar = (props: NavBarProps) => {
    const allStates = useAppSelector((state: any) => state);
    const dispatch = useAppDispatch();
    let action: any = getActions(props.name);
    const relavantState = allStates[props.name];
    
    return <div className="nav-bar">
        <div className="title">
            <div className="icon"><SidebarIcon name={props.name} active></SidebarIcon></div>
            <div className="text"><span>{props.sectionName}</span></div>
        </div>
        {props.pagination ? <div className="pagination-container">
            <Pagination
                totalPages={relavantState.pagination.totalPages}
                totalResults={relavantState.pagination.totalResults}
                currentPage={relavantState.pagination.pageNumber}
                limit={relavantState.pagination.limit}
                resultsInPage={relavantState.pagination.currentResults}
                setPageFn={(pageNumber: number) => dispatch(action.setPageNumber(pageNumber))}
                key="pagination"
            />
        </div>: <></>}

    </div>
}