import { useState } from "react"
import { Input, Label } from "semantic-ui-react"
import "./CustomDatePicker.css";
import SemanticDatepicker from 'react-semantic-ui-datepickers';

export interface CustomDatePickerState {
    showModal: boolean;
    dates: Date[]
}

export const CustomDatePicker = (props: {
    onChange: Function,
    value?: Date;
    values?: Date[];
    name: string;
    label?: string;
    range?: boolean;
}) => {

    const today = new Date();
    const initialState: CustomDatePickerState = {
        showModal: false,
        dates: props.value ? [new Date(props.value)] : (props.values ? props.values.map(val => new Date(val)) : [])
    }
    const [state, setState] = useState(initialState);

    const handleDateChange = (e: any, data: any) => {
        if (Array.isArray(data.value)) {
            const values: Date[] = data.value.map((value: string) => new Date(value));
            if (values.length === 2) {
                props.onChange({...data, value: values.map(value => value.toString()), name: props.name});
            }
            setState({
                showModal: values.length === 2 ? false : true,
                dates: values,
            })
        } else {
            const value: Date = new Date(data.value)
            props.onChange({...data, value: [value.toString()], name: props.name});
            setState({
                showModal: false,
                dates: [value],
            })
        }
    }

    const handleFocus = () => {
        setState({...state, showModal: true})
    }

    const handleClear = () => {
        setState({
            dates: [],
            showModal: false
        })
        props.onChange({value: [], name: props.name});
    }

    const handleClose = (e: any) => {
        if (e.target.className === "modal") {
            if (props.range && state.dates.length === 2) {
                setState({...state, showModal: false})
            }

            if (!props.range) {
                setState({...state, showModal: false})
            }
        }
        if (e.target.className === "close-button") {
            setState({...state, showModal: false})
        }
    }

    const getInputStr = () => {        
        if ((props.range && props.values && props.values.length > 0) || (!props.range && props.value)) {
            const options: any = { year: 'numeric', month: 'short', day: '2-digit' };
            if (state.dates.length === 2) {
                return `${state.dates[0].toLocaleDateString("en-IN", options)} - ${state.dates[1].toLocaleDateString("en-IN", options)}`;
            } else if (state.dates.length === 1) {
                return state.dates[0].toLocaleDateString("en-IN", options);
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const getFullString = (date: Date) => {
        if (!date) {
            return ""
        } else {
            var options: any = { year: 'numeric', month: 'long', day: '2-digit' };
            return date.toLocaleDateString("en-IN", options)    
        }
    }

    return <div onFocus={handleFocus} className="custom-date-picker">
        {props.label ? <div className="text-label"><span>{props.label}</span></div>: null}
        <div className="inputbox-container">
            <input value={getInputStr()} autoComplete="off" name={props.name} placeholder={`Select ${props.name}`}></input>
        </div>
        {state.showModal ? <div className="modal" onClick={handleClose}>
            <div className="modal-content">
                <div className="dates-text">
                    <div className="inputs">
                        <input readOnly value={getFullString(state.dates[0])}></input>
                        {state.dates.length === 2 ?<input value={getFullString(state.dates[1])}></input>: <></>}
                        <button onClick={handleClear}>Clear</button>
                    </div>                    
                    <button className="close-button" onClick={handleClose}>Close</button>
                </div>
                <SemanticDatepicker
                    datePickerOnly
                    type={props.range ? "range" : "basic"}
                    inline
                    name={props.name}
                    value={props.range ? state.dates : state.dates[0] ? state.dates[0] : null}
                    onChange={handleDateChange} autoFocus />
            </div>
        </div> : null}
    </div>
}