// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbox {
    width: 100%;
    position: static;
    max-width: 20vw;
}

.searchbox .text-label {
    border: none;
    padding: 2%;
}

.searchbox .inputbox-container {
    display: flex;
    align-items: center;
    border: 1.5px solid black;
    border-radius: 4px;
}

.searchbox .inputbox-container input{
    border: none;
    padding: 3%;
    width: 90%;
}

.searchbox .dropdown-container {
    border: 1px solid rgba(0, 0, 0, 0.11);
    padding: 5%;
}

.searchbox .dropdown-container .items-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.searchbox .dropdown-container .list-item {
    background: var(--link-active);
    width: 100%;
    display: flex;
    padding: 1%;
    align-items: center;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/Searchbox/Searchbox.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,UAAU;AACd;;AAEA;IACI,qCAAqC;IACrC,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".searchbox {\n    width: 100%;\n    position: static;\n    max-width: 20vw;\n}\n\n.searchbox .text-label {\n    border: none;\n    padding: 2%;\n}\n\n.searchbox .inputbox-container {\n    display: flex;\n    align-items: center;\n    border: 1.5px solid black;\n    border-radius: 4px;\n}\n\n.searchbox .inputbox-container input{\n    border: none;\n    padding: 3%;\n    width: 90%;\n}\n\n.searchbox .dropdown-container {\n    border: 1px solid rgba(0, 0, 0, 0.11);\n    padding: 5%;\n}\n\n.searchbox .dropdown-container .items-list{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.searchbox .dropdown-container .list-item {\n    background: var(--link-active);\n    width: 100%;\n    display: flex;\n    padding: 1%;\n    align-items: center;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
