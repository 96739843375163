import { useEffect } from "react";
import { useHistory } from "react-router";
import { Menu, Image } from "semantic-ui-react"
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { CommonState } from "../../store/reducers/commonReducer";
import "./SideBar.css";
import logo from "../../assets/logos/OptimumPLogo2.png"
import { SidebarIcon } from "../Icons";

export const SideBar = () => {
    const state = useAppSelector((state: any): CommonState => state.common);
    const { common } = actions;
    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        const path = window.location.href.split("/")[3];
        dispatch(common.setSelectedTab(path));
    })

    const handleClick = (e: any, data: any) => {
        dispatch(common.setSelectedTab(data.name));
        history.push(`/${data.name}`);
    }

    const getMenuItems = () => {
        const items = ["Dashboard", "Inventory", "Stocks", "Assembly", "Production", "Purchases", "Components", "Prices",
            "Accounts", "Reports", "Settings"]
        const menuItems = items.map((item) => {
            return <Menu.Item
                    key={item}
                    name={item.toLocaleLowerCase()}
                    onClick={handleClick}
                    active={state.selectedTab === item.toLocaleLowerCase()}
                    className="sidebar-menu-item-container"
                >
                    <div className={state.selectedTab === item.toLocaleLowerCase() ? "sidebar-menu-item active": "sidebar-menu-item"}>
                        <div className="menu-item-icon">
                           <SidebarIcon name={item.toLowerCase()} active={state.selectedTab === item.toLocaleLowerCase()} />
                        </div>
                        <div className="menu-item-text"><span>{item}</span></div>
                    </div>
                </Menu.Item>
        })
        return menuItems;
    }

    return (
        <>
            <div className="logo">
                <Image src={logo} centered />
            </div>
            <Menu vertical className="menu-container">
                {getMenuItems()}
            </Menu>
        </>
    )
}