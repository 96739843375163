import { useEffect } from "react";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";


export const Home = (props: any) => {
    const dispatch = useAppDispatch();
    const { common } = actions;
    useEffect(() => {
        dispatch(common.setSelectedTab("home"));
    })
    return <div className="home">
            Coming Soon
    </div>
}