import { useEffect, useState } from "react";
import { DataGrid, Row } from "../../components";
import { HeaderItem, RowHeader, RowItem, RowsContainer } from "../../components/RowsContainer";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AssemblyPricesState } from "../../store/reducers/assemblyPricesReducer";

export const AssemblyPricesItem = (props: {index: number}) => {
    const state = useAppSelector((state: any): AssemblyPricesState => state.assemblyPrices);
    const { assemblyPrices } = actions;
    const dispatch = useAppDispatch();
    const initialObj = {...state.assemblyPrices[props.index]};
    const [pricesObj, setPartObj] = useState(initialObj);

    useEffect(() => {
        const initialObj = {...state.assemblyPrices[props.index]};
        setPartObj(initialObj);
    }, [props.index, state.assemblyPrices[props.index]])

    const handleSelectClick = () => {
       dispatch(assemblyPrices.setSelectedIndex(props.index));
    }

    return <Row key={pricesObj.name} onClick={() => {handleSelectClick()}}
            isSelected={state.selectedIndex === props.index}
            color={props.index%2 === 0 ? false : true}>
        <RowItem width={33.33} value={pricesObj.name}></RowItem>
        <RowItem width={33.33} value={pricesObj.assemblyNumber}></RowItem>
        <RowItem width={33.33} value={`${pricesObj.totalCost} ${pricesObj.currencySymbol}`}></RowItem>
    </Row>
}

export const AssemblyPricesHeader = () => {
    return <RowHeader>
        <HeaderItem key="heading-name" width={33.33} value="Name"></HeaderItem>
        <HeaderItem key="heading-type" width={33.33} value="Assembly Number"></HeaderItem>
        <HeaderItem key="heading-desc" width={33.33} value="Price"></HeaderItem>
    </RowHeader>
}

export const AssemblyPricesDataGrid = () => {
    const state = useAppSelector((state: any): AssemblyPricesState => state.assemblyPrices);
    const getData = () => {
        return <RowsContainer>
            {state.assemblyPrices.map((comp, ind) => {
                return <AssemblyPricesItem key={"assembly-item"+ind} index={ind}></AssemblyPricesItem>
            })}
        </RowsContainer>
    }

    return <DataGrid>
        <AssemblyPricesHeader></AssemblyPricesHeader>
        {getData()}
    </DataGrid>
}

export const AssemblyPartsPrice = (props: {
    index: number,
    colorInd: number,
}) => {
    const state = useAppSelector((state: any): AssemblyPricesState => state.assemblyPrices);
    let initialPartObj = state.assemblyPrices[state.selectedIndex].parts ?
        JSON.parse(JSON.stringify(state.assemblyPrices[state.selectedIndex].parts[props.index])) : {};
    const [partObj, setPartObj] = useState(initialPartObj);

    useEffect(() => {
        let initialPartObj = state.assemblyPrices[state.selectedIndex].parts ?
        JSON.parse(JSON.stringify(state.assemblyPrices[state.selectedIndex].parts[props.index])) : {};
        setPartObj(initialPartObj);
    }, [props.index])
    return <Row key={partObj.name} color={props.index%2 === 0 ? false : true}>
        <RowItem width={10} value={`${props.colorInd+1}`}></RowItem>
        <RowItem width={20} value={partObj.partName}></RowItem>
        <RowItem width={20} value={partObj.partNumber}></RowItem>
        <RowItem width={15} value={partObj.quantity + " "+ partObj.quantityUnit}></RowItem>
        <RowItem width={15} value={`${partObj.cost} ${partObj.currencySymbol}/${partObj.quantityUnit}`}></RowItem>
        <RowItem width={20} value={`${partObj.cost*partObj.quantity} ${partObj.currencySymbol}`}></RowItem>
    </Row>
}

export const AssemblyPartsPricesHeader = () => {
    return <RowHeader>
        <HeaderItem key="heading-s-no" width={10} value="S.No"></HeaderItem>
        <HeaderItem key="heading-name" width={20} value="Part Name"></HeaderItem>
        <HeaderItem key="heading-number" width={20} value="Part Number"></HeaderItem>
        <HeaderItem key="heading-quantity" width={15} value="Quantity"></HeaderItem>
        <HeaderItem key="heading-action" width={15} value="Rate"></HeaderItem>
        <HeaderItem key="heading-action" width={20} value="Part Cost"></HeaderItem>
    </RowHeader>
}

export const AssemblyPartsPricesDataGrid = () => {
    const state = useAppSelector((state: any): AssemblyPricesState => state.assemblyPrices);

    const searchPassPart = (part: any) => {
        let text = state.componentsSearchText.toLowerCase();
        let include = part.partName.toLowerCase().includes(text) || part.partNumber.toLowerCase().includes(text)
            || part.quantity.toString().toLowerCase().includes(text)
        return include;
    }

    const getData = () => {
        if (state.assemblyPrices.length === 0) {
            return <RowsContainer></RowsContainer>
        }
        let parts = [];
        let ind  = 0;
        for (let i = 0; i < state.assemblyPrices[state.selectedIndex].parts.length; i++) {
            let part = state.assemblyPrices[state.selectedIndex].parts[i];
            if (searchPassPart(part)) {
                parts.push(<AssemblyPartsPrice colorInd={ind} index={i} key={i+part.partName}></AssemblyPartsPrice>)
                ind++;
            }
        }        
        return <RowsContainer>{parts}</RowsContainer>;
    }

    return <DataGrid>
        <AssemblyPartsPricesHeader></AssemblyPartsPricesHeader>
        {getData()}
    </DataGrid>
}

export const SubAssemblyPrice = (props: {
    index: number,
    colorInd: number,
}) => {
    const state = useAppSelector((state: any): AssemblyPricesState => state.assemblyPrices);
    let initialSubAsmObj = state.assemblyPrices[state.selectedIndex].subAssemblies ?
        JSON.parse(JSON.stringify(state.assemblyPrices[state.selectedIndex].subAssemblies[props.index])) : {};
    const [subAsmObj, setPartObj] = useState(initialSubAsmObj);

    useEffect(() => {
        let initialSubAsmObj = state.assemblyPrices[state.selectedIndex].subAssemblies ?
        JSON.parse(JSON.stringify(state.assemblyPrices[state.selectedIndex].subAssemblies[props.index])) : {};
        setPartObj(initialSubAsmObj);
    }, [props.index])

    return <Row key={subAsmObj.name} color={props.index%2 === 0 ? false : true}>
        <RowItem width={10} value={`${props.colorInd+1}`}></RowItem>
        <RowItem width={20} value={subAsmObj.assemblyName}></RowItem>
        <RowItem width={20} value={subAsmObj.assemblyNumber}></RowItem>
        <RowItem width={15} value={subAsmObj.quantity + " "+ subAsmObj.quantityUnit}></RowItem>
        <RowItem width={15} value={`${subAsmObj.cost} ${subAsmObj.currencySymbol}/${subAsmObj.quantityUnit}`}></RowItem>
        <RowItem width={20} value={`${subAsmObj.cost*subAsmObj.quantity} ${subAsmObj.currencySymbol}`}></RowItem>
    </Row>
}

export const SubAssemblyPricesHeader = () => {
    return <RowHeader>
        <HeaderItem key="heading-s-no" width={10} value="S.No"></HeaderItem>
        <HeaderItem key="heading-s-no" width={10} value="S.No"></HeaderItem>
        <HeaderItem key="heading-name" width={20} value="Asmb. Name"></HeaderItem>
        <HeaderItem key="heading-number" width={20} value="Asmb. Number"></HeaderItem>
        <HeaderItem key="heading-quantity" width={15} value="Quantity"></HeaderItem>
        <HeaderItem key="heading-action" width={15} value="Rate"></HeaderItem>
        <HeaderItem key="heading-action" width={20} value="Asmb. Cost"></HeaderItem>
    </RowHeader>
}

export const SubAssemblyPricesDataGrid = () => {
    const state = useAppSelector((state: any): AssemblyPricesState => state.assemblyPrices);

    const searchPassSubAsm = (asm: any) => {
        let text = state.subAsmSearchText.toLowerCase();
        let include = asm.assemblyName.toLowerCase().includes(text) || asm.assemblyNumber.toLowerCase().includes(text)
            || asm.quantity.toString().toLowerCase().includes(text)
        return include;
    }

    const getData = () => {
        if (state.assemblyPrices.length === 0) {
            return <RowsContainer></RowsContainer>
        }
        let parts = [];
        let ind  = 0;
        for (let i = 0; i < state.assemblyPrices[state.selectedIndex].subAssemblies.length; i++) {
            let part = state.assemblyPrices[state.selectedIndex].subAssemblies[i];
            if (searchPassSubAsm(part)) {
                parts.push(<SubAssemblyPrice colorInd={ind} index={i} key={i+part.partName}></SubAssemblyPrice>)
                ind++;
            }
        }
        return <RowsContainer>{parts}</RowsContainer>;
    }

    return <DataGrid>
        <SubAssemblyPricesHeader></SubAssemblyPricesHeader>
        {getData()}
    </DataGrid>
}
