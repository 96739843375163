import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";

export interface CategoriesState {
    categories: any[];
    totalPages: number;
    pageNumger: number;
    limit: number;
};

const initialState: CategoriesState = {
    categories: [],
    totalPages: 1,
    pageNumger: 1,
    limit: 20,
}

const categoriesSlice: Slice = createSlice({
    name: 'categories',
    initialState: initialState,
    reducers: {
        setCategories: (state: CategoriesState, action: PayloadAction<any[]>) => {
            state.categories = action.payload;
        },
        setPageNumber: (state: CategoriesState, action: PayloadAction<number>) => {
            state.pageNumger = action.payload;
        },
        setTotalPages: (state: CategoriesState, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
    },
})

export const categoriesActions = {
    setCategories: categoriesSlice.actions.setCategories,
    setPageNumber: categoriesSlice.actions.setPageNumber,
    setTotalPages: categoriesSlice.actions.setTotalPages
}

export default categoriesSlice.reducer;