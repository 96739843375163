import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { componentsAPIs } from "../../apis";
import { purchasesAPIs } from "../../apis/purchases";
import { AddtoIcon, Button, CommandBar, DataGrid, DeleteIcon, Form, MenuIcon, Modal, Row,
    RowHeader, RowsContainer, SaveIcon, Section, SplitSection, VerticalSection } from "../../components";
import { DropdownField } from "../../components/Dropdown/DropdownField";
import { HeaderItem, RowItem } from "../../components/RowsContainer";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AddPurchasesState } from "../../store/reducers/addPurchasesReducer";
import { PurchasesState } from "../../store/reducers/purchasesReducer";
import { getDeliveryStatus, getQuantity } from "../../utils/commonUtils"
import { ComponentFilter, FilterGridType, PurchasesFilter } from "../../utils/enums";
import { NewPurchaseItem } from "./NewPurchaseItem";

export interface AddPurchasesProps {
    name: string;
    closeFn: Function;
    icon: string;
}

export const AddPurchases = (props: AddPurchasesProps) => {
    const state = useAppSelector((state):AddPurchasesState => state.addPurchases);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { common, addPurchases } = actions;
    const [showOptions, setShowOptions] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [purchaseDetails, setPurchaseDetails] = useState({
        purchasedBy: "",
        paymentMode: "Credit Card",
        paymentDetails: "",
        grandTotalPrice: 0,
        purchasedDate: new Date(),
        note: "",
        deliveryStatus: "Order Placed",
        deliveryDate: undefined
    })
    const [searchText, setSearchText] = useState("");
    const getFilterValue = (name: string): any[] => {
        const index = state.optionFilterValues.findIndex(filter => filter.name === name);
        if (index > -1) {
            return state.optionFilterValues[index].value;
        } else {
            return [];
        }
    }

    useEffect(() => {
        componentsAPIs.getComponentsData({limit: 1000, pageNumber: 1, searchText: searchText,
            category: getFilterValue(ComponentFilter.CATEGORY).map(item => item.value),
            subcategory: getFilterValue(ComponentFilter.SUBCATEGORY).map(item => item.value),
            footPrint: getFilterValue(ComponentFilter.FOOTPRINT).map(item => item.value),
            manufacturer: getFilterValue(ComponentFilter.MANUFACTURER).map(item => item.value),
            supplier: getFilterValue(ComponentFilter.SUPPLIER).map(item => item.value)
        }).then((data) => {
            dispatch(addPurchases.setOptions(data.results.map((option: any) => {
                return {...option, quantityUnit: "pcs", quantity: 1}
            })));
        }).catch(error => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in getting options"});            
        })
    }, [searchText, state.optionFilterValues])


    const validate = (payload: any) => {
        let fields = ["purchasedBy", "paymentMode", "grandTotalPrice", "purchasedDate"];
        let fieldsMap = ["Purchased By", "Payment Mode", "Grand Total Price", "Purchased Date"]
        let errorFields = [];
        for (let i = 0; i < fields.length; i++) {
            if ([null, "", undefined].indexOf(payload[fields[i]]) > -1) {
                errorFields.push(fieldsMap[i])
            }
        }        

        if (!payload.purchaseItems || (payload.purchaseItems && payload.purchaseItems.length === 0)) {
            errorFields.push("Purchase Items");
        }
        for (let i = 0; i < payload.purchaseItems.length; i++) {
            if ([null, "", undefined].indexOf(payload.purchaseItems[i].totalPrice) > -1) {
                errorFields.push("Total Price");
                break;
            }
        }
        if (errorFields.length > 0) {
            return {
                isValid: false,
                errorFields: errorFields
            }
        } else {
            return {
                isValid: true
            }
        }
    }
        
    const handleSubmit = async () => {
        let payload = {
            ...purchaseDetails, purchaseItems: state.purchaseItems
        }
        let validation = validate(payload);
        
        if (!validation.isValid) {
            let msg = `${validation.errorFields} values cannot be empty`;
            dispatch(common.setFlyMsg({msg: msg, isError: true}))
            return;
        }

        setShowLoader(true);
        const defaultDeliveryStatus = (await getDeliveryStatus()).results[0].name
        let resp = await purchasesAPIs.addPurchases({
            ...purchaseDetails, purchaseItems: state.purchaseItems.map(item => {
                return {
                    ...item,
                    deliveryStatus: purchaseDetails.deliveryStatus || defaultDeliveryStatus,
                    deliveryDate: purchaseDetails.deliveryDate || null,
                }
            })
        });

        let errorMsg = null;
        if (resp.error) {
            errorMsg = resp.error;
            dispatch(common.setFlyMsg({msg: errorMsg, isError: true}))
        } else {
            dispatch(addPurchases.setPurchaseItems([]))
        }
        setShowLoader(false);
        let msg = `${state.purchaseItems.length} Purchases added successfully.`;
        dispatch(common.setFlyMsg({msg: msg}))
        dispatch(addPurchases.setPurchaseItems([]))
        window.location.reload();
    }

    const handleClose = () => {
        history.push("/purchases")
    }

    const handleAdd = (ind: number) => {
        dispatch(addPurchases.setItemAtIndex({item: {
            partName: state.options[ind].name,
            partId: state.options[ind]._id,
            unitType: "pcs",
            noOfUnits: 1,
            totalQuantity: 1,
            quantityUnit: "pcs",
            netPrice: 0,
            totalPrice: 0,
            pricePerQuantity: 0,
            partNumber: state.options[ind].partNumber,
            totalTaxValue: 0,
            totalAdditionalCharge: 0,
            taxes: [],
            additionalCharges: [],            
        }, index: state.purchaseItems.length}))
    }

    const getOptions = () => {
        return state.options.map((option: any, ind) => {
            let isAdded = false;
            let quantityValue = 1;
            state.purchaseItems.forEach((element: any) => {
                if (element.partName === option.name) {
                    isAdded = true;
                    quantityValue = element.quantity;
                }
            });
            return <Row key={option.name}>
                <RowItem width={5}>
                        {isAdded ? <SaveIcon /> : <AddtoIcon onClick={() => !isAdded ? handleAdd(ind) : {}}/>}</RowItem>
                <RowItem width={20} value={option.name+`(${option.partNumber})`}></RowItem>
                <RowItem width={20} value={option.category+`(${option.subcategory})`}></RowItem>
                <RowItem width={55} value={`${option.manufacturer}(${option.supplier})\n${option.description}`}></RowItem>
            </Row>
        })
    }

    const getPurchaseItems = () => {        
        let purchaseItemsList = state.purchaseItems.map((comp: any, ind: number) => {
            return <NewPurchaseItem index={ind} isActive={false} setData={() => {}}/>
        })
        if (state.purchaseItems.length === 0) {
            dispatch(addPurchases.setNewItemAtIndex(0))
        }
        return purchaseItemsList;
    }

    const getGrandTotalDetails = () => {
        return <Row color>
            <RowItem width={3} value={`${state.purchaseItems.length} Items`}></RowItem>
            <RowItem width={15} value={""}></RowItem>
            <RowItem width={10} value={state.purchaseItems.reduce((a, b) => {return a+b.noOfUnits}, 0) + ""}></RowItem>
            <RowItem width={5} value={state.purchaseItems.reduce((a, b) => {return a+b.totalQuantity}, 0) + ""}></RowItem>
            <RowItem width={10} value={state.purchaseItems.reduce((a, b) => {return a+b.netPrice}, 0) + " ₹"}></RowItem>
            <RowItem width={15}
                value={state.purchaseItems.reduce((a, b) => {return a+b.totalTaxValue}, 0) + " ₹"}>
            </RowItem>
            <RowItem width={15}
                value={state.purchaseItems.reduce((a, b) => {return a+b.totalAdditionalCharge}, 0) + " ₹"}>
            </RowItem>
            <RowItem width={10} value={""}></RowItem>
            <RowItem width={10}
                value={state.purchaseItems.reduce((a, b) => {return a+b.totalPrice}, 0) + " ₹"}> 
            </RowItem>
            <RowItem width={3} className="action">
            </RowItem>
        </Row>
    }

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
    }

    const componentFilters = [
        ComponentFilter.CATEGORY,
        ComponentFilter.SUBCATEGORY,
        ComponentFilter.MANUFACTURER,
        ComponentFilter.SUPPLIER
    ]
    return <Modal size="full"
        iconName="PurchasesActive"
        title="Add Purchases"
        actions={[
            <Button onClick={handleClose} label="Close"/>,
            <Button onClick={handleSubmit} label="Save"/>
        ]}
    >
        <div style={{position: "absolute", top: "8vh", right: "0.5vw"}}>
            <MenuIcon onClick={() => {setShowOptions(!showOptions)}}></MenuIcon></div>
        <VerticalSection height="18%">
            <Form
                fields={[
                    "purchaseDate", "purchasedBy", "paymentMode", 
                    "deliveryStatus", "deliveryDate", "note"
                ]}
                formErrors={{}}
                formObj={{...purchaseDetails}}
                setFormErrors={() => {}}
                setFormObj={setPurchaseDetails}
                showLoader={false}
            />
        </VerticalSection>
        <Section height="82%" width="98%">
            <DataGrid>
                <RowHeader>
                    <HeaderItem width={3} value={"S.No"}></HeaderItem>
                    <HeaderItem width={8} value={"Part Name"}></HeaderItem>
                    <HeaderItem width={8} value={"Part Number"}></HeaderItem>
                    <HeaderItem width={7} value={"Units"}></HeaderItem>
                    <HeaderItem width={5} value={"Total Quantity"}></HeaderItem>
                    <HeaderItem width={10} value={"Net Price"}></HeaderItem>
                    <HeaderItem width={15} value={"Taxes"}></HeaderItem>
                    <HeaderItem width={15} value={"Charges"}></HeaderItem>
                    <HeaderItem width={10} value={"Unit Price"}></HeaderItem>
                    <HeaderItem width={10} value={"Total Price"}></HeaderItem>
                    <HeaderItem width={3} value={"Action"}></HeaderItem>
                </RowHeader>
                <RowsContainer height="100%">
                    {getPurchaseItems().concat(getGrandTotalDetails())}
                </RowsContainer>
                { purchaseDetails.grandTotalPrice ? <div className="grand-total">
                    <span>{"Grand Total: "+purchaseDetails.grandTotalPrice+"₹"}</span>
                    </div> : <></>}
            </DataGrid>
        </Section>
        {showOptions ? <div style={{position: "absolute", right: "1vw", top: "11vh", width: "50%", height:"80.8%", zIndex: 10}}>
            <Section height="100%" width="100%">
                <CommandBar
                    searchFn={handleSearch}
                    gridType={FilterGridType.COMPONENTS}
                    dropdowns={componentFilters}
                    dropdownValues={state.optionFilterValues}
                    setDropdownFn={addPurchases.setOptionFilters}
                    noTags={true}
                />
                <DataGrid>
                    <RowHeader>
                        <HeaderItem width={5} value=""></HeaderItem>
                        <HeaderItem width={20} value="Part Name"></HeaderItem>
                        <HeaderItem width={20} value="Category"></HeaderItem>
                        <HeaderItem width={55} value="Details"></HeaderItem>
                    </RowHeader>
                    <RowsContainer>
                        {getOptions()}
                    </RowsContainer>
                </DataGrid>
            </Section>
            {showLoader ? <Loader></Loader>: null}
        </div>: <></>}
    </Modal>
}