import { useState } from "react";
import { actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CommonState } from "../../store/reducers/commonReducer";
import { Modal } from "../Modal/Modal";

export interface DeleteFormProps {
    deleteFn: Function;
    payload: any;
    multidelete?: boolean;
    name: string;
    value?: string;
    showModal: boolean;
    closeFn: Function;
    icon: string;
    refreshTrigger?: Function;
}

export interface DeleteFormState {
    showLoader: boolean;
    isDeleted: boolean;
}

export const DeleteForm = (props: DeleteFormProps) => {
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const [showLoader, setShowLoader] = useState(false);
    const dispatch = useAppDispatch();
    const { common } = actions;
    const initialState: DeleteFormState = {
        showLoader: false,
        isDeleted: false,
    }
    const [state, setState] = useState(initialState);

    const handleSubmit = async () => {
        setShowLoader(true);
        let promises = [];
        if (props.multidelete) {
            for (let payload of props.payload) {
                promises.push(props.deleteFn(payload));
            }
        } else {
            promises.push(props.deleteFn(props.payload));
        }
        await Promise.all(promises);
        setShowLoader(false);
        setState({...state, isDeleted: true});
        props.closeFn(false);
        dispatch(common.setFlyMsg({msg: `${props.multidelete ? props.payload.length : 1} ${props.name}(s) deleted successfully.`}))
        dispatch(common.setRefresh({name: props.name, value: !commonState.refresh.value}))
        setShowLoader(false);
    }

    const handleClose = () => {
        setState({
            ...initialState
        });
        props.closeFn(false);
    }

    const msg = props.multidelete ? `Are you sure you want to delete ${props.payload.length} ${props.name}`
        :`Are you sure you want to delete ${props.name} "${props.value}"`;

    return <Modal
        size="small"
        iconName={props.icon}
        title={`Delete ${props.name}`}
        key={"delete-"+props.name}
        showLoader={showLoader}
        actions={[
            <button onClick={handleClose}>Cancel</button>,
            <button onClick={handleSubmit}>Delete</button>
        ]}
    >
        <div className="delete-msg"><span>{msg}</span></div>
    </Modal>
}