
import axios from 'axios';
import urls from './urls';
import * as qs from 'query-string';

export const plansAPIs = {
    addPlan: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "application/json"
            }
            const response = await axios.post(urls.plans, {...payload}, {headers});
            return response.data;
        } catch (error) {
            return {
                code: 500,
                error: "Error in adding new component."
            }
        }
    },
    updatePlan: async (payload: any, id?: string) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "application/json"
            }
            const response = await axios.put(`${urls.assembly}/${id ? id : payload._id}`, {...payload}, {headers});
            return response.data;
        } catch (error) {
            return {
                code: 500,
                error: "Error in adding new component."
            }
        }
    },
    getAPlan: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }

            let params: any = {
                "pageNumber": payload.pageNumber,
                "limit": payload.limit,
                "assemblyName": payload.assemblyName
            }
            
            let response = await axios.get(urls.plans, {
                params, headers, paramsSerializer: params => qs.stringify(params)});
            return response.data.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    getPlans: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            let params: any = {
                "pageNumber": payload.pageNumber,
                "limit": payload.limit
            }

            for (let param in payload) {
                if (["pageNumber", "limit"].indexOf(param) === -1 && payload[param].length > 0) {
                    params[param] = payload[param]
                }
            }

            let response = await axios.get(urls.assembly, {
                params, headers, paramsSerializer: params => qs.stringify(params)});
            return response.data.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    deletePlan: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            }
            const response = await axios.delete(`${urls.assembly}/${payload}`, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
}