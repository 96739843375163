
import axios from 'axios';
import urls from './urls';
import * as qs from 'query-string';

export const componentsAPIs = {
    addComponent: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "multipart/form-data"
            }
            const formData = new FormData();
            formData.append('name', payload.name)
            formData.append('category', payload.category)
            formData.append('subcategory', payload.subcategory)
            formData.append('manufacturer', payload.manufacturer)
            formData.append('supplier', payload.supplier)
            formData.append('footPrint', payload.footPrint)
            formData.append('partNumber', payload.partNumber)
            formData.append('description', payload.description)

            const response = await axios.post(urls.components, formData, {headers});
            return response.data;
        } catch (error) {
            return {
                code: 500,
                error: "Error in adding new component."
            }
        }
    },
    updateComponent: async (payload: any, id: string) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "multipart/form-data"
            }
            const formData = new FormData();
            formData.append('name', payload.name)
            formData.append('category', payload.category)
            formData.append('subcategory', payload.subcategory)
            formData.append('manufacturer', payload.manufacturer)
            formData.append('supplier', payload.supplier)
            formData.append('footPrint', payload.footPrint)
            formData.append('partNumber', payload.partNumber)
            formData.append('description', payload.description)

            const response = await axios.put(`${urls.components}/${id}`, formData, {headers});
            return response.data;
        } catch (error) {
            return {
                code: 500,
                error: "Error in adding new component."
            }
        }
    },
    getAComponent: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            let response = await axios.get(`${urls.components}/${payload.id}`, { headers });
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    getComponentsData: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            let params: any = {
                "pageNumber": payload.pageNumber,
                "limit": payload.limit
            }

            for (let param in payload) {
                if (["pageNumber", "limit"].indexOf(param) === -1 && payload[param].length > 0) {
                    params[param] = payload[param]
                }
            }

            let response = await axios.get(urls.components, {
                params, headers, paramsSerializer: params => qs.stringify(params)});
                console.log(response.data.data,'-----data');
                
            return response.data.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    deleteComponent: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            }
            const response = await axios.delete(`${urls.components}/${payload}`, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
}