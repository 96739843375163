import { ChangeEventHandler } from "react";

export const InputTextField = (props: {
    formError?: string;
    label?: string;
    value?: string | number;
    name: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    readonly?: boolean;
    subscript?: string;
    type?: string;
}) => {
    const getClassName = (className: string) => {
        if (props.formError) {
            return `${className} error`
        } else {
            return className;
        }
    }
    return <div className="text-field">
        {props.label ? <div className={props.formError ? "text-label error": "text-label"}><span>{props.label || props.name}</span>
        {props.formError ? <span>{props.formError.length > 0 ? props.formError : "*"}</span>: null}
        </div> : null}
        {!props.label && props.formError ? <div className={getClassName("text-label")}><span>{props.formError}</span></div> : null}
        {props.readonly ? <div className={"value"}><span>{props.value}</span></div> :
        <div className={getClassName("inputbox-container")}>
            <input type={props.type} value={props.value?.toString()} name={props.name} onChange={props.onChange}></input>
            {props.subscript ? <span className="subscript">{props.subscript}</span> : <></>} 
        </div>}
    </div>
}

export const TextAreaField = (props: {
    formError?: string;
    label?: string;
    value?: string;
    name: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
}) => {
    const getClassName = (className: string) => {
        if (props.formError) {
            return `${className} error`
        } else {
            return className;
        }
    }

    return <div className="text-field">
        {props.label ? <div className={props.formError ? "text-label error": "text-label"}><span>{props.label || props.name}</span>
            {props.formError ? <span>{props.formError.length > 0 ? props.formError : "*"}</span>: null}
        </div> : null}
        {!props.label && props.formError ? <div className={getClassName("text-label")}><span>{props.formError}</span></div> : null}
        <div className={getClassName("inputbox-container")}>
            <textarea value={props.value} name={props.name} onChange={props.onChange}></textarea> 
        </div>
    </div>
}

export const FormField = (props: {
    readonly?: boolean;
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
}) => {
    return <div className={props.readonly ? "readonly-field" : "field"}>
        {props.children}
    </div>
}