import { useEffect } from "react";
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { NavBar } from "../../components/NavBar/NavBar";
import { FilterGridType } from "../../utils/enums";
import { AssemblyPricesState } from "../../store/reducers/assemblyPricesReducer";
import { pricesAPIs } from "../../apis";
import { EditAssemblyPrices } from "./EditAssemblyPrices";
import { Container, SplitSection, Section, CommandBar, AddNewButton, VerticalSection, ChangeButton } from "../../components";
import { AssemblyPartsPricesDataGrid, AssemblyPricesDataGrid, SubAssemblyPricesDataGrid } from "./AssemblyPricesData";
import { useHistory } from "react-router";

export const AssemblyPrices = () => {
    const state = useAppSelector((state: any): AssemblyPricesState => state.assemblyPrices);
    const { assemblyPrices, common } = actions;
    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        pricesAPIs.getAssemblyPricesData({
            pageNumber: state.pageNumber,
            limit: state.limit,
            searchText: state.searchText
        }).then((assemblyData: any) => {
            dispatch(assemblyPrices.setAssemblyPrices(assemblyData.results));
            if (assemblyData.results.length > 0) {
                let focusIndex = state.selectedIndex;
                if (state.selectedIndex > (assemblyData.results.length - 1)) {
                    focusIndex = assemblyData.results.length - 1;
                }
                dispatch(assemblyPrices.setSelectedIndex(focusIndex));
            }

            dispatch(assemblyPrices.setPagination({
                pageNumber: assemblyData.pageNumber,
                limit: assemblyData.limit,
                totalPages: assemblyData.totalPages,
                totalResults: assemblyData.totalResults,
                currentResults: assemblyData.currentResults
            }))
        }).catch((error: any) => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in fetching components data"})
        })
    }, [state.limit, state.pageNumber, state.searchText])

    const handleAssemblySearch = (e: any) => {
        dispatch(assemblyPrices.setSearchText(e.target.value));
    }

    const handlePartsSearch = (e: any) => {
        dispatch(assemblyPrices.setComponentsSearchText(e.target.value));
    }

    const handleSubAsmSearch = (e: any) => {
        dispatch(assemblyPrices.setSubAsmSearchText(e.target.value));
    }

    return <Container>
        <NavBar
            sectionName="Assembly Prices"
            name="assemblyPrices"
        />
        <SplitSection>
            <Section>
                <CommandBar
                    key="assembly"
                    dropdowns={[]}
                    dropdownValues={state.filterValues}
                    setDropdownFn={() => {}}
                    gridType={FilterGridType.ASSEMBLY_PRICES}
                    searchFn={handleAssemblySearch}
                    actions={[]}
                >
                </CommandBar>
                <AssemblyPricesDataGrid></AssemblyPricesDataGrid>
            </Section>
            <Section>
                <VerticalSection>
                    <CommandBar
                        key="assembly-parts"
                        dropdowns={[]}
                        dropdownValues={[]}
                        setDropdownFn={() => { }}
                        gridType={FilterGridType.ASSEMBLY_PRICES}
                        searchFn={handlePartsSearch}
                        actions={[<ChangeButton label={"Change Prices"}
                            onClick={() => { dispatch(assemblyPrices.setUpdateForm(true)) }}
                        />]}
                    >
                    </CommandBar>
                    <AssemblyPartsPricesDataGrid />
                </VerticalSection>
                <VerticalSection>
                    <CommandBar
                        key="sub-assembly"
                        dropdowns={[]}
                        dropdownValues={[]}
                        setDropdownFn={() => { }}
                        gridType={FilterGridType.ASSEMBLY_PRICES}
                        searchFn={handleSubAsmSearch}
                        actions={[
                            <ChangeButton label={"View Parts Prices"}
                                onClick={() => { history.push("/prices/parts") }}
                            />
                        ]}
                    >
                    </CommandBar>
                    <SubAssemblyPricesDataGrid />
                </VerticalSection>
            </Section>
        </SplitSection>
        {state.showForms.update ? <EditAssemblyPrices
            showModal={state.showForms.update}
            closeFn={(show: boolean) => { dispatch(assemblyPrices.setUpdateForm(show)) }}
        ></EditAssemblyPrices> : <></>}
    </Container>
}