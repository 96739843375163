import { useEffect, useState } from "react";
import { actions } from "../../store";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { componentsAPIs } from "../../apis";
import { ComponentsState } from "../../store/reducers/componentsReducer";
import { getIcons } from "../../utils/commonUtils";
import { DeleteForm } from "../../components/DeleteForm/DeleteForm";
import { ComponentFilter, FilterGridType } from "../../utils/enums";
import { PartsDataGrid } from "./ComponentsData";
import { CommonState } from "../../store/reducers/commonReducer";
import { MainSection, Container, CommandBar, NavBar, MultiDeleteIcon, AddNewButton
} from "../../components";
import { Icon } from "semantic-ui-react";
import { AddComponent, EditComponent } from "./ComponentForms";

export const Components = () => {
    const state = useAppSelector((state: any): ComponentsState => state.components);
    const commonState = useAppSelector((state: any): CommonState => state.common);
    const { components, common } = actions;
    const dispatch = useAppDispatch();
    const [multiDelete, setMultiDelete] = useState(false);
    const componentFilters = [
        ComponentFilter.CATEGORY,
        ComponentFilter.SUBCATEGORY,
        ComponentFilter.FOOTPRINT,
        ComponentFilter.MANUFACTURER,
        ComponentFilter.SUPPLIER
    ]

    useEffect(() => {
        componentsAPIs.getComponentsData({
            pageNumber: state.pagination.pageNumber,
            limit: state.pagination.limit,
            searchText: state.searchText,
            category: getFilterValue(ComponentFilter.CATEGORY).map(item => item.value),
            subcategory: getFilterValue(ComponentFilter.SUBCATEGORY).map(item => item.value),
            footPrint: getFilterValue(ComponentFilter.FOOTPRINT).map(item => item.value),
            manufacturer: getFilterValue(ComponentFilter.MANUFACTURER).map(item => item.value),
            supplier: getFilterValue(ComponentFilter.SUPPLIER).map(item => item.value)
        }).then((compsData: any) => {
            dispatch(components.setComponents(compsData.results));
            dispatch(components.setPagination({
                pageNumber: compsData.pageNumber,
                limit: compsData.limit,
                totalPages: compsData.totalPages,
                totalResults: compsData.totalResults,
                currentResults: compsData.currentResults
            }))
            dispatch(components.setUnselectAll({}))
        }).catch((error: any) => {
            console.log(error);
            common.setFlyMsg({isError: true, msg:"Error in fetching components data"})
        })
    }, [state.filterValues, state.searchText, state.pagination, commonState.refresh.value])

    const getFilterValue = (name: ComponentFilter): any[] => {
        const index = state.filterValues.findIndex(filter => filter.name === name);
        if (index > -1) {
            return state.filterValues[index].value;
        } else {
            return [];
        }
    }

    const setPagination = (pageNumber: number) => {
        dispatch(components.setPageNumber(pageNumber));
    }

    const handleMultiDelete = (e: any) => {
        if (Object.values(state.selections).includes(true)) {
            setMultiDelete(true);
        }
    }

    const handleDeleteClose = (e: any) => {
        if (state.showForms.delete) {
            dispatch(components.setDeleteForm(false));
        } else {
            setMultiDelete(false);
        }
    }

    const getDeleteIds = () => {
        return Object.keys(state.selections).filter(item => {
            return state.selections[item]
        })
    }

    const handleSearch = (e: any) => {
        dispatch(components.setSearchText(e.target.value));
    }

    return <Container>
        <NavBar
            sectionName="Components"
            name="components"
            pagination={true}
        />
        <MainSection>
            <CommandBar
                dropdowns={componentFilters}
                dropdownValues={state.filterValues}
                setDropdownFn={components.setFilters}
                gridType={FilterGridType.COMPONENTS}
                searchFn={handleSearch}
                actions={[ <MultiDeleteIcon onClick={handleMultiDelete}/>,
                        <AddNewButton label="Add Component"
                            onClick={() => { dispatch(components.setAddForm(true)) }}/>
                    ]}
                >
            </CommandBar>
            <PartsDataGrid></PartsDataGrid>
        </MainSection>
        {state.showForms.add ? <AddComponent></AddComponent> : <></>}
        {state.showForms.edit ? <EditComponent></EditComponent>: <></>}
        {state.showForms.delete || multiDelete ? <DeleteForm
            icon="ComponentsActive"
            showModal={state.showForms.delete || multiDelete}
            closeFn={handleDeleteClose}
            name="Component"
            multidelete={multiDelete}
            value={state.showForms.delete ? state.components[state.deleteIndex].name : ""}
            payload={state.showForms.delete ? state.components[state.deleteIndex]._id : getDeleteIds()}
            deleteFn={componentsAPIs.deleteComponent}
        ></DeleteForm> : <></>}
    </Container>
}