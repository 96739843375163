
import axios from 'axios';
import urls from './urls';
import * as qs from 'query-string';
import { getDateString } from '../utils/dateUtils';

export const purchasesAPIs = {
    addPurchases: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "application/json"
            }
            payload.purchasedDate = getDateString(payload.purchasedDate);
            payload.deliveryDate = payload.deliveryDate ? getDateString(payload.deliveryDate) : null;
            payload.purchaseItems = payload.purchaseItems.map((item: any) => {
                return {
                    ...item,
                    deliveryDate: item.deliveryDate ? getDateString(item.deliveryDate) : null
                }
            })
            const response = await axios.post(urls.purchases, payload, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    updatePurchase: async (payload: any, id: string) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "application/json"
            }
            payload.deliveryDate = payload.deliveryDate ? getDateString(payload.deliveryDate) : null;
            const response = await axios.put(`${urls.purchases}/${id}`, {
                deliveryDate: getDateString(payload.deliveryDate),
                deliveryStatus: payload.deliveryStatus,
                purchaseItems: payload.purchaseItems.map((item: any) => {
                    return {
                        deliveryStatus: item.deliveryStatus,
                        partId: item.partId,
                        deliveryDate:  item.deliveryDate ? getDateString(item.deliveryDate) : null
                    }
                })
            }, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    getAProduct: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            let response = await axios.get(`${urls.purchases}/${payload.id}`, { headers });
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    getPurchasesData: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            let params: any = {
                "pageNumber": payload.pageNumber,
                "limit": payload.limit
            }
            
            for (let param in payload) {
                if (["pageNumber", "limit"].indexOf(param) === -1 && payload[param]) {
                    params[param] = payload[param]
                }
            }            

            if (payload.fromDate) {
                params.fromDate = getDateString(payload.fromDate);
            }

            if (payload.toDate) {
                params.toDate = getDateString(payload.toDate);
            }

            let response = await axios.get(urls.purchases, {
                params, headers, paramsSerializer: params => qs.stringify(params)});
            return response.data.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    deletePurchase: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            }
            const response = await axios.delete(`${urls.purchases}/${payload}`, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
}