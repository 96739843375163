
import axios from 'axios';
import urls from './urls';

export const categoriesAPIs = {
    addCategory: async (payload: {
        name: string,
        image?: any,
    }) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                'Content-type': "multipart/form-data"
            }
            const formData = new FormData();
            if (payload.image) {
                formData.append('imgLarge', payload.image)
            }
            formData.append('name', payload.name)
            const response = await axios.post(urls.categories, formData, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    getCategoriesData: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
            let params: any = {
                "pageNumber": payload.pageNumber,
                "limit": payload.limit
            }
            if (payload.searchText) {
                params.searchText = payload.searchText
            }
            let response = await axios.get(urls.categories, {
                params, headers});
            return response.data.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
    deleteCategory: async (payload: any) => {
        try {
            const headers = {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            }
            const response = await axios.delete(`${urls.categories}/${payload.id}`, {headers});
            return response.data;
        } catch (error) {
            let err: any = error;
            return err.error;
        }
    },
}