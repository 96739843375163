import { useEffect, useState } from "react"
import { Dimmer, Loader } from "semantic-ui-react"
import { Container } from ".."
import { getIcons } from "../../utils/commonUtils"
import "./Modal.css"

export const Modal = (props: {
    actions?: React.ReactElement<any, any> | React.ReactElement<any, any>[];
    showLoader?: boolean;
    errorMsg?: string;
    iconName: string;
    title: string;
    dimensions?: { width: number, height: number };
    size?: string;
    children?: React.ReactElement<any, any> | React.ReactElement<any, any>[];
}) => {


    const [height, setHeight] = useState(200);

    const getBoxHeight = () => {
        const title = document.getElementById("modal-title" + props.size);
        const modal = document.getElementById("custom-modal" + props.size);
        console.log(modal, title);

        let height = 100;
        if (modal && title) {
            height = modal.getBoundingClientRect().height - title.getBoundingClientRect().height;

        }
        if (props.actions) {
            const actions = document.getElementById("modal-actions" + props.size);
            if (actions) {
                height = height - actions.getBoundingClientRect().height;
            }
        }
        setHeight(height);
        return height;
    }

    useEffect(() => {
        let h = getBoxHeight();
        setHeight(h);
    }, [height])

    const getContainerStyle = () => {
        switch (props.size) {
            case "full":
                return { width: "100%", height: "100%" }
            case "large":
                return { width: "80%", height: "80%" }
            case "medium":
                return { width: "70%", height: "60%" }
            case "small":
                return { width: "70%", height: "40%" }
        }
    }

    return <div className="custom-modal">
        <div id={"custom-modal" + props.size} style={getContainerStyle()} className="container">
            <div id={"modal-title" + props.size} className="title">
                <div className="icon">{getIcons(props.iconName)}</div><span className="text">{props.title}</span>
                {props.errorMsg ? <div className="error"><span>{props.errorMsg}</span></div> : <></>}
            </div>
            <div style={{ height: height }} className="content-container">
                {props.children}
            </div>
            {props.actions ? <div id={"modal-actions"+props.size} className="actions-bar">
                <div className="buttons-div">
                    {props.actions}
                </div>
            </div>: <></>}
        </div>
    </div>
}