import { ChangeEventHandler, KeyboardEventHandler } from "react";
import "./components.css";

export const MainSection = (props: {
    children?: React.ReactElement<any, any>[];
    className?: string;
}) => {
    return <div className={props.className ? `main-section ${props.className}` : "main-section"}>
        {props.children}
    </div>
}

export const SplitSection = (props: {
    children: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    width?: string;
    height?: string;
}) => {
    return <div style={{width: props.width, height: props.height}} className={props.className ? `split-section ${props.className}` : "split-section"}>
        {props.children}
    </div>
}

export const Section = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    width?: string;
    height?: string;
}) => {
    return <div style={{width: props.width, height: props.height}} className={props.className ? `section ${props.className}` : "section"}>
        {props.children}
    </div>
}

export const VerticalSection = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    width?: string;
    height?: string;
}) => {
    return <div style={{width: props.width, height: props.height}} className={props.className ? `vertical-section ${props.className}` : "vertical-section"}>
        {props.children}
    </div>
}

export const TextArea = (props: {
    className?: string;
    width?: string;
    height?: string;
    label?: string;
    name?: string;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>
}) => {
    return <div style={{width: props.width, height: props.height}} className={props.className ? `text-area ${props.className}` : "text-area"}>
        {props.label ? <span>{props.label}</span>: null}
        <textarea name={props.name} onChange={props.onChange}></textarea>
    </div>
}