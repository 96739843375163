export enum TabName {
    HOME = "home",
    PURCHASES = "purchases",
    ORDERS = "orders",
    BILLS = "bills",
    PRICES = "prices",
    INVENTORY = "inventory",
    ACCOUNTS = "accounts",
}

export enum SubtabName {
    PRODUCTS = "products",
    SUBCATEGORIES = "subcategories",
    CATEGORIES = "categories",
    STOCKS = "stocks",
    HISTORY="history",
    HISTORICAL="historical",
    DAILY="daily",
    TODAY="today",
    ADD="add",
    DASHBOARD="dashboard",
}

export enum ComponentFilter {
    CATEGORY="Category",
    SUBCATEGORY="Subcategory",
    MANUFACTURER="Manufacturer",
    SUPPLIER="Supplier",
    FOOTPRINT="Footprint",
}

export enum AssemblyFilter {
    ASSEMBLY_TYPE="Assembly Type"
}

export enum ProductionFilter {
    ASSEMBLY_NAME="Assembly Name"
}

export enum PurchasesFilter {
    PART_NAME="Part Name",
    PAYMENT_MODE="Payment Mode",
    PURCHASE_MODE="Purchase Mode",
    FROM_DATE="From Date",
    TO_DATE="To Date",
    TOTAL_COST="Total Cost",
    QUANTITY="Quantity",
    QUANTITY_UNIT="Quantity Unit",
    DELIVERY_STATUS="Delivery Status",
    TAX_TYPE="Tax Type",
    ADDITIONAL_CHARGES_TYPE="Additional Charge",
}

export enum InventoryFilter {
    PART_NAME="Part Name",
    USED="Quantity Used",
    PURCHASED="Quantity Purchased",
    WASTED="Quantity Wasted",
    LEFT="Quantity Left",
}

export enum StockFilter {
    ASSEMBLY_NAME="Assembly Name",
    USED="Quantity Used",
    PURCHASED="Quantity Purchased",
    WASTED="Quantity Wasted",
    LEFT="Quantity Left",
}

export enum PricesFilter {
    ASSEMBLY_NAME="Assembly Name",
    PART_NAME="Part Name"
}

export type FilterName = ComponentFilter | AssemblyFilter | PurchasesFilter 
    | InventoryFilter | StockFilter | PricesFilter | ProductionFilter

export enum FilterGridType {
    COMPONENTS="Components",
    ASSEMBLY="Assembly",
    PURCHASES="Purchases",
    ASSEMBLY_PARTS="Assembly Parts",
    INVENTORY="Inventory",
    PRICES="Prices",
    ASSEMBLY_PRICES="Assembly Prices",
    PRODUCTION="Production"
}
