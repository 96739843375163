import { MouseEventHandler, useEffect, useState } from "react";
import "./components.css";

export const DataTile = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    width?: string;
    height?: string;
    heading?: string;
    values?: string[];
}) => {
    if (props.heading && props.values) {
        return <div style={{width: props.width, height: props.height}} className="data-tile">
            <DataTileHeader value={props.heading} />
            <DataTilesContainer values={props.values}></DataTilesContainer>
        </div>
    }
    return <div style={{width: props.width, height: props.height}}
    className={props.className ? `data-tiles ${props.className}` : "data-tiles"}>
        {props.children}
    </div>
}


export const DataTilesContainer = (props: {
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any>;
    className?: string;
    width?: string;
    height?: string;
    values?: string[];
    vertical?: boolean;
}) => {
    let initialClass = props.vertical ? "data-tiles-container vertical": "data-tiles-container";
    if (props.values) {
        return <div style={{width: props.width, height: props.height}}
        className={props.className ? `${initialClass} ${props.className}` : `${initialClass}`}>
            {props.values.map(value => {
                return <DataTileItem value={value}></DataTileItem>
            })}
        </div>
    }
    return <div style={{width: props.width, height: props.height}}
    className={props.className ? `${initialClass} ${props.className}` : `${initialClass}`}>
        {props.children}
    </div>
}

export const DataTileHeader = (props: {
    className?: string;
    value?: string;
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any> ;
    onClick?: MouseEventHandler<HTMLDivElement>
    width?: string;
    height?: string;
}) => {
    return <div style={{width: props.width, height: props.height}}
        onClick={props.onClick} className={props.className ? `data-tile-header ${props.className}` : "data-tile-header"}>
        {props.value ? <span>{props.value}</span> : props.children}
    </div>
}

export const DataTileItem = (props: {
    className?: string;
    value?: string;
    width?: number;
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any> ;
    onClick?: MouseEventHandler<HTMLDivElement>
    vertical?: boolean;
}) => {
    let className = props.vertical ? "data-tile-item vertical" : "data-tile-item";
    return <div style={{width: `${props.width}%`}} onClick={props.onClick} className={props.className ? 
    `${className} ${props.className}` : className}>
        {props.value ? <span>{props.value}</span>: props.children}
    </div>
}

export const DataTileInput = (props: {
    className?: string;
    value?: string;
    width?: number;
    children?: React.ReactElement<any, any>[] | React.ReactElement<any, any> ;
    onClick?: MouseEventHandler<HTMLDivElement>
    vertical?: boolean;
}) => {
    let className = props.vertical ? "data-tile-input vertical" : "data-tile-input";
    return <div style={{width: `${props.width}%`}} onClick={props.onClick} className={props.className ? 
    `${className} ${props.className}` : className}>
        {props.value ? <span>{props.value}</span>: props.children}
    </div>
}