// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-form {
    padding: 1vh 1vw;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.custom-form .field {
    margin: 0.5vh 0.5vw;
}

.custom-form .readonly-field {
    margin: 0.5vh 0.5vw;
    min-width: 5vw;
}

.text-label {
    border: none;
    padding: 2%;
}

.text-label.error {
    color: red;
}

.text-field {
    max-width: 25vw;
}

.text-field .subscript {
    padding: 0.5vh 0.5vw;
}

.inputbox-container {
    display: flex;
    align-items: center;
    border: 1.5px solid black;
    border-radius: 4px;
}

.inputbox-container.error {
    border-color: red;
}

.inputbox-container input{
    border: none;
    padding: 3%;
    width: 100%;
    text-align: center;
}

.inputbox-container textarea{
    border: none;
    padding: 3%;
    min-width: 20vw;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/Form.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;AACnB","sourcesContent":[".custom-form {\n    padding: 1vh 1vw;\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n}\n\n.custom-form .field {\n    margin: 0.5vh 0.5vw;\n}\n\n.custom-form .readonly-field {\n    margin: 0.5vh 0.5vw;\n    min-width: 5vw;\n}\n\n.text-label {\n    border: none;\n    padding: 2%;\n}\n\n.text-label.error {\n    color: red;\n}\n\n.text-field {\n    max-width: 25vw;\n}\n\n.text-field .subscript {\n    padding: 0.5vh 0.5vw;\n}\n\n.inputbox-container {\n    display: flex;\n    align-items: center;\n    border: 1.5px solid black;\n    border-radius: 4px;\n}\n\n.inputbox-container.error {\n    border-color: red;\n}\n\n.inputbox-container input{\n    border: none;\n    padding: 3%;\n    width: 100%;\n    text-align: center;\n}\n\n.inputbox-container textarea{\n    border: none;\n    padding: 3%;\n    min-width: 20vw;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
