import { Route, BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
import { MainContainer } from './containers/MainContainer/MainContainer';
import { Login } from './components/Login/Login';
import { Alert } from './components/Alert/Alert';
import 'semantic-ui-css/semantic.min.css'

const App = () => {
  // const state = useAppSelector((state: any): AuthState => state.auth);
  // const { auth } = actions;

  // const checkAuthAndRedirect = () => {
  //   if (!state.isAuthenticated) {
  //     return window.location.pathname === "/" ? <Redirect to="/"/> : <Redirect to="/login"/>
  //   } else {
  //     return window.location.pathname === "/" ? <Redirect to="/dashboard"/> : <Redirect to={window.location.pathname}/>
  //   }
  // }

  return <BrowserRouter>
    {/* {checkAuthAndRedirect()} */}
    <div className="App">
        <Alert></Alert>
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route path="/*" component={MainContainer}/>
        </Switch>
    </div>
  </BrowserRouter>
}

export default App;