import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { PurchasesFilter } from '../../utils/enums';
import { Pagination } from './commonReducer';

export interface PurchasesState {
    purchases: any[];
    pagination: Pagination;
    filterValues: {name: PurchasesFilter, value: any}[],
    optionFilterValues: {name: PurchasesFilter, value: any}[],
    searchText: string;
    showForms: {add: boolean, edit: boolean};
    editIndex: number;
};

const initialState: PurchasesState = {
    purchases: [],
    pagination: {
        totalPages: 1,
        pageNumber: 1,
        limit: 20,
        totalResults: 0,
        currentResults: 0,
    
    },
    filterValues: [],
    optionFilterValues: [],
    searchText: "",
    showForms: {add: false, edit: false},
    editIndex: 0
}

const purchasesSlice: Slice = createSlice({
    name: 'purchases',
    initialState: initialState,
    reducers: {
        setPurchases: (state: PurchasesState, action: PayloadAction<any[]>) => {
            state.purchases = action.payload;
        },
        setPageNumber: (state: PurchasesState, action: PayloadAction<number>) => {
            state.pagination.pageNumber = action.payload
        },
        setPagination: (state: PurchasesState, action: PayloadAction<{ 
            pageNumber: number; limit: number, totalResults: number, totalPages: number, currentResults: number
        }>) => {
            state.pagination.pageNumber = action.payload.pageNumber;
            state.pagination.limit = action.payload.limit
            state.pagination.currentResults = action.payload.currentResults;
            state.pagination.totalPages = action.payload.totalPages;
            state.pagination.totalResults = action.payload.totalResults;
        },
        setFilters: (state: PurchasesState, action: PayloadAction<{name: PurchasesFilter; value: any}>) => {
            let filterIndex = state.filterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.filterValues[filterIndex].value = action.payload.value;
            } else {
                state.filterValues.push({...action.payload})
            }
        },
        setOptionFilters: (state: PurchasesState, action: PayloadAction<{name: PurchasesFilter; value: any}>) => {
            let filterIndex = state.optionFilterValues.findIndex(filter => {
                return filter.name === action.payload.name
            })
            if (filterIndex > -1) {
                state.optionFilterValues[filterIndex].value = action.payload.value;
            } else {
                state.optionFilterValues.push({...action.payload})
            }
        },
        setSearchText: (state: PurchasesState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        setAddForm: (state: PurchasesState, action: PayloadAction<boolean>) => {
            state.showForms.add = action.payload;
        },
        setEditForm: (state: PurchasesState, action: PayloadAction<boolean>) => {
            state.showForms.edit = action.payload;
        },
        setEditIndex: (state: PurchasesState, action: PayloadAction<number>) => {
            state.editIndex = action.payload;
        },
    },
})

export const purchasesActions = {
    setPurchases: purchasesSlice.actions.setPurchases,
    setPagination: purchasesSlice.actions.setPagination,
    setPageNumber: purchasesSlice.actions.setPageNumber,
    setFilters: purchasesSlice.actions.setFilters,
    setOptionFilters: purchasesSlice.actions.setOptionFilters,
    setSearchText: purchasesSlice.actions.setSearchText,
    setAddForm: purchasesSlice.actions.setAddForm,
    setEditForm: purchasesSlice.actions.setEditForm,
    setEditIndex: purchasesSlice.actions.setEditIndex,
}

export default purchasesSlice.reducer;