import { Widgets } from "@mui/icons-material";
import "./components.css";

export const Arrow = (props: {
    direction: string
}) => {
    let style = {}
    if (props.direction === "down") {
        style={
            transform: `rotateZ(-90deg)`,
            width: '10vh'
        }
    }
    return <div style={style} className={props.direction==="right" ? "arrow-container": "arrow-container left"}>
        <div className="arrow-length"></div>
        <div className={props.direction==="right" ? "arrow-tails": "arrow-tails left"}></div>
    </div>
}